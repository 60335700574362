import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import React from 'react';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import HouseholdTable from '../../components/tables/HouseholdTable';
import DashboardHeader from '../../components/typography/DashboardHeader';

const HouseholdList: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Typography color="inherit" variant="overline">
            Data
          </Typography>
          <Typography color="textPrimary" variant="overline">
            Households
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          All Households
        </Typography>
      </DashboardHeader>
      <Paper variant="outlined">
        <HouseholdTable />
      </Paper>
    </DashboardLayout>
  );
};

export default HouseholdList;
