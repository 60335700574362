import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  cell: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export interface ImageLandingProps {
  image: string;
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
}

const ImageLanding: React.FC<ImageLandingProps> = ({ title, subtitle, image, children }) => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box py={4} px={4}>
      <Container fixed>
        <Grid container alignItems="center" style={{ height: '100vh' }}>
          <Grid
            item
            container
            md={6}
            xs={12}
            className={classes.cell}
            alignItems={matches ? 'center' : 'flex-start'}>
            {typeof title === 'string' ? (
              <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
                <Typography color="textPrimary" component="h1" variant="h5">
                  {title}
                </Typography>
              </Box>
            ) : (
              title
            )}
            {typeof subtitle === 'string' ? (
              <Typography color="textSecondary" variant="body1" align={matches ? 'center' : 'left'}>
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
            {children}
          </Grid>
          <Grid item container xs={12} md={6} className={classes.cell} alignItems="center">
            <img alt="background" src={image} style={{ width: '100%', maxWidth: '400px' }} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ImageLanding;
