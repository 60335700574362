import { makeStyles } from '@material-ui/core';
import React, { ForwardRefRenderFunction, PropsWithChildren, forwardRef } from 'react';

const useStyles = makeStyles({
  subtitle: {
    margin: 0,
    lineHeight: 1.5,
    fontSize: '1.15rem',
    color: 'var(--ion-color-medium)',
  },
});

type ComponentType = ForwardRefRenderFunction<HTMLHeadingElement, PropsWithChildren<{}>>;

const Subtitle: ComponentType = ({ children }, ref) => {
  const classes = useStyles();

  return (
    <h2 ref={ref} className={classes.subtitle}>
      {children}
    </h2>
  );
};

export default forwardRef(Subtitle);
