import { Box, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';

export interface LoadingOrFailProps {
  isLoading?: boolean;
  isError?: boolean;
}

const LoadingOrFail: React.FC<LoadingOrFailProps> = (props) => {
  const { isLoading, isError, children } = props;

  return (
    <>
      {isLoading || isError ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={4}>
          {isLoading && !isError ? <CircularProgress /> : null}
          {isError ? (
            <Typography color="textPrimary" variant="body2">
              Failed to load data.
            </Typography>
          ) : null}
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default LoadingOrFail;
