import { FormControl, InputLabel, Select } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { FilterInputValueProps } from '@material-ui/data-grid';
import React from 'react';

import { LookupValue } from '../../api/common';

export interface SelectFilterInputProps extends FilterInputValueProps {
  options?: LookupValue<any, string>[];
}

const SelectFilterInput: React.FC<SelectFilterInputProps> = (props) => {
  const { item, options, applyValue } = props;

  const onSelectionChange: SelectInputProps['onChange'] = (event) => {
    applyValue({ ...item, value: String(event.target.value ?? '') });
  };

  return (
    <FormControl>
      <InputLabel shrink>Value</InputLabel>
      <Select native value={item.value ?? ''} onChange={onSelectionChange}>
        <option value="" />
        {options?.map(({ id, title }) => (
          <option key={id} value={String(id)}>
            {title}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectFilterInput;
