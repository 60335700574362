import { InputChangeEventDetail } from '@ionic/core';
import { IonAlert, IonButton, IonList, IonPage } from '@ionic/react';
import { isPhoneNumber } from 'class-validator';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { useSelfUser, useSelfUserUpdate } from '../api/user';
import ButtonSheet from '../components/buttons/ButtonSheet';
import AddonButton from '../components/forms/AddonButton';
import HelperText from '../components/forms/HelperText';
import ItemInput from '../components/forms/ItemInput';
import FormLayout from '../components/layouts/FormLayout';
import QueryResultToast from '../components/notification/QueryResultToast';
import { useEditState } from '../utils/state';

const Profile: React.FC = () => {
  const history = useHistory();

  const [update, resUpdate] = useSelfUserUpdate({ onSuccess: () => history.goBack() });
  const { data: user, ...resQuery } = useSelfUser({
    onSuccess: (user_) => {
      setFirstName(user_?.profile.firstName, true);
      setLastName(user_?.profile.lastName, true);
      setPhoneNumber(user_?.profile.phoneNumber, true);
    },
  });

  const [firstName, setFirstName] = useEditState(user?.profile.firstName);
  const [lastName, setLastName] = useEditState(user?.profile.lastName);
  const [phoneNumber, setPhoneNumber] = useEditState(user?.profile.phoneNumber);

  const [isEmailTipVisible, setIsEmailTipVisible] = useState(false);

  const onFirstNameChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setFirstName(e.detail.value === '' ? null : e.detail.value);
  };

  const onLastNameChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setLastName(e.detail.value === '' ? null : e.detail.value);
  };

  const onPhoneNumberChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setPhoneNumber(e.detail.value === '' ? null : e.detail.value);
  };

  const onSubmit = () => update({ firstName, lastName, phoneNumber });

  const isPhoneNumberValid = !phoneNumber || isPhoneNumber(phoneNumber!, 'US');
  const isLoading = (resQuery.isInitialData && resQuery.isFetching) || resUpdate.isLoading;
  const canSubmit = !isLoading && isPhoneNumberValid;

  return (
    <IonPage>
      <FormLayout title="Profile" subtitle="Tell us about you.">
        <IonList className="input-group">
          <ItemInput
            label="Email"
            required
            readonly
            value={user?.email ?? ''}
            addons={
              <AddonButton icon={helpCircleOutline} onClick={() => setIsEmailTipVisible(true)} />
            }
          />
          <ItemInput label="First Name" value={firstName} onChange={onFirstNameChange} />
          <ItemInput label="Last Name" value={lastName} onChange={onLastNameChange} />
          <ItemInput label="Phone Number" value={phoneNumber} onChange={onPhoneNumberChange} />
          {!isPhoneNumberValid ? (
            <HelperText color="error">Please enter a valid US phone number</HelperText>
          ) : null}
        </IonList>
      </FormLayout>
      <ButtonSheet>
        <IonButton shape="round" expand="block" onClick={onSubmit} disabled={!canSubmit}>
          {isLoading ? 'Loading...' : 'Save'}
        </IonButton>
      </ButtonSheet>
      <IonAlert
        isOpen={isEmailTipVisible}
        onDidDismiss={() => setIsEmailTipVisible(false)}
        message={'Email address is read-only at this time.'}
        buttons={['OK']}
      />
      <QueryResultToast result={resUpdate}>User profile updated.</QueryResultToast>
    </IonPage>
  );
};

export default Profile;
