import { FormHelperText, Typography, TypographyTypeMap } from '@material-ui/core';
import React from 'react';

export interface HelperTextProps {
  align?: TypographyTypeMap['props']['align'];
  color?: TypographyTypeMap['props']['color'];
}

const HelperText: React.FC<HelperTextProps> = ({ align, color, children }) => {
  return (
    <FormHelperText component="div">
      <Typography variant="body2" align={align} color={color}>
        {children}
      </Typography>
    </FormHelperText>
  );
};

export default HelperText;
