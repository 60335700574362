import { makeStyles, withStyles } from '@material-ui/core';
import { DataGridProps, DataGrid as MuiDataGrid } from '@material-ui/data-grid';
import React from 'react';

import ColumnMenu from './ColumnMenu';
import LoadingOverlay from './LoadingOverlay';
import NoRowsOverlay from './NoRowsOverlay';

const GlobalCss = withStyles((theme) => ({
  '@global': {
    '.MuiDataGridPanelContent-root': {
      overflow: 'hidden',
      '& .MuiInputBase-input, & .MuiFormControl-root & .MuiFormLabel-root': {
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },
}))(() => null);

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 400,
    border: 'none',
  },
}));

const DataGrid: React.FC<DataGridProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <GlobalCss />
      <MuiDataGrid
        autoHeight
        disableSelectionOnClick
        disableMultipleSelection
        className={classes.container}
        components={{ NoRowsOverlay, LoadingOverlay, ColumnMenu }}
        {...props}
      />
    </>
  );
};

export default DataGrid;
