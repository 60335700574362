import { InputChangeEventDetail, SelectChangeEventDetail } from '@ionic/core';
import { IonAlert, IonButton, IonList, IonPage } from '@ionic/react';
import { isPostalCode } from 'class-validator';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { AddressBase } from '../../api/address';
import {
  isHouseholdReadOnly,
  useHousehold,
  useHouseholdCreate,
  useHouseholdUpdate,
  useHousingTypes,
  useIncomeLevels,
  useOwnershipTypes,
} from '../../api/household';
import { useSelfUser } from '../../api/user';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import AddonButton from '../../components/forms/AddonButton';
import AddressInput from '../../components/forms/AddressInput';
import ItemInput from '../../components/forms/ItemInput';
import LoadableKvSelect from '../../components/forms/LoadableKvSelect';
import FormLayout from '../../components/layouts/FormLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import { useEditState } from '../../utils/state';
import AddressSelect from '../common/AddressSelect';

const Household: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isCreatingHousehold = !Boolean(id);

  const { data: housingTypes, status: housingTypeStatus } = useHousingTypes();
  const { data: ownershipTypes, status: ownershipTypeStatus } = useOwnershipTypes();
  const { data: incomeLevels, status: incomeLevelStatus } = useIncomeLevels();

  const [create, resCreate] = useHouseholdCreate({ onSuccess: () => history.goBack() });
  const [update, resUpdate] = useHouseholdUpdate({ onSuccess: () => history.goBack() });

  const { data: user } = useSelfUser();
  const { data: household, ...resQuery } = useHousehold(id, {
    onSuccess: (household_) => {
      setAddress(household_?.address, true);
      setCity(household_?.city, true);
      setState(household_?.state, true);
      setZipCode(household_?.zipCode, true);
      setHousingType(household_?.housingType?.id, true);
      setHousingOwnershipType(household_?.housingOwnershipType?.id, true);
      setLivingTime(household_?.livingTime, true);
      setIncomeLevel(household_?.incomeLevel?.id, true);
    },
  });

  const [isAddressSelectVisible, setIsAddressSelectVisible] = useState(false);
  const [isIncomeTipVisible, setIsIncomeTipVisible] = useState(false);
  const [isLivingTimeTipVisible, setIsLivingTimeTipVisible] = useState(false);

  const [address, setAddress] = useEditState(household?.address);
  const [city, setCity] = useEditState(household?.city);
  const [state, setState] = useEditState(household?.state);
  const [zipCode, setZipCode] = useEditState(household?.zipCode);
  const [livingTime, setLivingTime] = useEditState(household?.livingTime);
  const [housingType, setHousingType] = useEditState(household?.housingType?.id);
  const [housingOwnershipType, setHousingOwnershipType] = useEditState(
    household?.housingOwnershipType?.id,
  );
  const [incomeLevel, setIncomeLevel] = useEditState(household?.incomeLevel?.id);

  const onAddressChange = (address?: AddressBase) => {
    setAddress(address?.address);
    setCity(address?.city);
    setState(address?.state);
    setZipCode(address?.zipCode);
  };

  const onHousingTypeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setHousingType(e.detail.value!);
  };

  const onHousingOwnershipTypeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setHousingOwnershipType(e.detail.value!);
  };

  const onLivingTimeChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const time = parseInt(e.detail.value!);
    if (!isNaN(time)) {
      setLivingTime(time);
    } else {
      setLivingTime(null);
    }
  };

  const onIncomeLevelChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setIncomeLevel(e.detail.value!);
  };

  const onSubmit = () => {
    const updates = {
      address: address!,
      city: city!,
      state: state!,
      zipCode: zipCode!,
      housingType,
      housingOwnershipType,
      livingTime,
      incomeLevel,
    };
    if (isCreatingHousehold) {
      create(updates);
    } else if (household) {
      update({ id: household.id, ...updates });
    }
  };

  const isLoading =
    (resQuery.isInitialData && resQuery.isFetching) || resCreate.isLoading || resUpdate.isLoading;
  const isZipCodeValid = isPostalCode(zipCode!, 'US');
  const isFormValid = Boolean(address && city && state && zipCode && isZipCodeValid);
  const isHouseholdOwner = user?.householdOwnedId && user?.householdOwnedId === household?.id;
  const isReadOnly = !isCreatingHousehold && (!isHouseholdOwner || isHouseholdReadOnly(household));
  const canSubmit = !isLoading && !isReadOnly && isFormValid;

  const title = 'Household';
  const subtitle = isReadOnly
    ? 'View your household information.'
    : 'Tell us about your household.';

  return (
    <IonPage>
      <FormLayout title={title} subtitle={subtitle}>
        <IonList className="input-group">
          <AddressInput
            required
            label="Address"
            readonly={isReadOnly}
            address={{ address, city, state, zipCode }}
            onClick={isReadOnly ? undefined : () => setIsAddressSelectVisible(true)}
          />
          <LoadableKvSelect
            label="Housing Type"
            value={housingType}
            entries={housingTypes}
            readonly={isReadOnly}
            status={housingTypeStatus}
            onChange={onHousingTypeChange}
          />
          <LoadableKvSelect
            label="Ownership Status"
            value={housingOwnershipType}
            entries={ownershipTypes}
            readonly={isReadOnly}
            status={ownershipTypeStatus}
            onChange={onHousingOwnershipTypeChange}
          />
          <ItemInput
            type="number"
            label="Number of Years Lived"
            value={livingTime}
            readonly={isReadOnly}
            onChange={onLivingTimeChange}
            addons={
              <AddonButton
                icon={helpCircleOutline}
                onClick={() => setIsLivingTimeTipVisible(true)}
              />
            }
          />
          <LoadableKvSelect
            label="Household Income"
            value={incomeLevel}
            entries={incomeLevels}
            readonly={isReadOnly}
            status={incomeLevelStatus}
            onChange={onIncomeLevelChange}
            addons={
              <AddonButton icon={helpCircleOutline} onClick={() => setIsIncomeTipVisible(true)} />
            }
          />
        </IonList>
      </FormLayout>
      {!isReadOnly ? (
        <ButtonSheet>
          <IonButton shape="round" expand="block" onClick={onSubmit} disabled={!canSubmit}>
            {isLoading ? 'Loading...' : 'Save'}
          </IonButton>
        </ButtonSheet>
      ) : null}
      <AddressSelect
        value={{ address, city, state, zipCode }}
        onSelect={onAddressChange}
        isOpen={isAddressSelectVisible}
        onDismiss={() => setIsAddressSelectVisible(false)}
      />
      <IonAlert
        isOpen={isLivingTimeTipVisible}
        onDidDismiss={() => setIsLivingTimeTipVisible(false)}
        message={`Enter number of years you have lived in your current home.
          Please round up to the next full year 
          (e.g. less than 1, enter 1; between 1 and 2, enter 2; etc.).`}
        buttons={['OK']}
      />
      <IonAlert
        isOpen={isIncomeTipVisible}
        onDidDismiss={() => setIsIncomeTipVisible(false)}
        message={`Enter your total household income, before taxes, for last year.
          Include income from sources such as wages and salaries, 
          income from a business or a farm, 
          Social Security, pensions, dividends, interest, rent 
          and any other income received for all household members.`}
        buttons={['OK']}
      />
      <QueryResultToast result={isCreatingHousehold ? resCreate : resUpdate}>
        Household information saved.
      </QueryResultToast>
    </IonPage>
  );
};

export default Household;
