import { ColDef, RowParams, RowsProp } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from 'react-router';

import { FilterOptions, usePagedList } from '../../api/common';
import { Household } from '../../api/household';
import DataGrid from '../../components/datagrid/DataGrid';
import {
  ContainsOperator,
  DateTimeRangeOperators,
  createSelectOperator,
} from '../../components/datagrid/Operators';
import useFilter from '../../components/datagrid/useFilter';
import usePagination from '../../components/datagrid/usePagination';
import useSorting from '../../components/datagrid/useSorting';
import { API_HOUSEHOLDS, DASHBOARD_HOUSEHOLD } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const columns: ColDef[] = [
  { field: 'address', headerName: 'Address', width: 300, filterOperators: ContainsOperator },
  { field: 'city', headerName: 'City', width: 180, filterOperators: ContainsOperator },
  { field: 'state', headerName: 'State', width: 120, filterOperators: ContainsOperator },
  {
    field: 'zipCode',
    headerName: 'Postal Code',
    width: 160,
    filterOperators: ContainsOperator,
  },
  {
    field: 'enrollmentStatus',
    headerName: 'Enrollment Status',
    width: 200,
    filterOperators: createSelectOperator([
      { id: 'none', title: 'NONE' },
      { id: 'submitted', title: 'SUBMITTED' },
      { id: 'reviewing', title: 'REVIEWING' },
      { id: 'rejected', title: 'REJECTED' },
      { id: 'accepted', title: 'ACCEPTED' },
    ]),
    valueGetter: (params) => params.row.enrollmentStatus?.title.toUpperCase() ?? '',
  },
  {
    field: 'enrolledAt',
    headerName: 'Enrolled At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('enrolledAt') as string),
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('createdAt') as string),
  },
];

export interface HouseholdTableProps {
  filter?: FilterOptions;
}

const HouseholdTable: React.FC<HouseholdTableProps> = ({ filter: extraFilter }) => {
  const history = useHistory();

  const [filter, filterProps] = useFilter();
  const [sorting, sortingPorps] = useSorting();
  const [pagination, paginationProps] = usePagination();

  const { data, totalItems, isLoading } = usePagedList<Household>(
    API_HOUSEHOLDS,
    { ...filter, ...extraFilter },
    sorting,
    pagination,
  );

  const onRowClick = (params: RowParams) => {
    history.push(DASHBOARD_HOUSEHOLD.replace(':id', params.getValue('id') as string));
  };

  const rows: RowsProp = data?.items ?? [];

  return (
    <DataGrid
      {...paginationProps}
      {...filterProps}
      {...sortingPorps}
      rows={rows}
      columns={columns}
      rowCount={totalItems}
      loading={isLoading}
      onRowClick={onRowClick}
    />
  );
};

export default HouseholdTable;
