import { ColDef, RowParams, RowsProp } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from 'react-router';

import { Address } from '../../api/address';
import { FilterOptions, usePagedList } from '../../api/common';
import DataGrid from '../../components/datagrid/DataGrid';
import { ContainsOperator, DateTimeRangeOperators } from '../../components/datagrid/Operators';
import useFilter from '../../components/datagrid/useFilter';
import usePagination from '../../components/datagrid/usePagination';
import useSorting from '../../components/datagrid/useSorting';
import { API_ADDRESSES, DASHBOARD_ADDRESS } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const columns: ColDef[] = [
  { field: 'name', headerName: 'Name', width: 300, filterOperators: ContainsOperator },
  { field: 'address', headerName: 'Address', width: 300, filterOperators: ContainsOperator },
  { field: 'city', headerName: 'City', width: 180, filterOperators: ContainsOperator },
  { field: 'state', headerName: 'State', width: 120, filterOperators: ContainsOperator },
  {
    field: 'zipCode',
    headerName: 'Postal Code',
    width: 160,
    filterOperators: ContainsOperator,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('createdAt') as string),
  },
];

export interface UserTableProps {
  filter?: FilterOptions;
}

const AddressTable: React.FC<UserTableProps> = ({ filter: extraFilter }) => {
  const history = useHistory();

  const [filter, filterProps] = useFilter();
  const [sorting, sortingPorps] = useSorting();
  const [pagination, paginationProps] = usePagination();

  const { data, totalItems, isLoading } = usePagedList<Address>(
    API_ADDRESSES,
    { ...filter, ...extraFilter },
    sorting,
    pagination,
  );

  const onRowClick = (params: RowParams) => {
    history.push(DASHBOARD_ADDRESS.replace(':id', params.getValue('id') as string));
  };

  const rows: RowsProp = data?.items ?? [];

  return (
    <DataGrid
      {...paginationProps}
      {...filterProps}
      {...sortingPorps}
      rows={rows}
      columns={columns}
      loading={isLoading}
      rowCount={totalItems}
      onRowClick={onRowClick}
    />
  );
};

export default AddressTable;
