// Copied from: https://github.com/tannerlinsley/react-query/blob/v2.26.2/src/core/utils.ts
function hasObjectPrototype(o: any): boolean {
  return Object.prototype.toString.call(o) === '[object Object]';
}

// Copied from: https://github.com/jonschlinkert/is-plain-object
export function isPlainObject(o: any): o is Object {
  if (!hasObjectPrototype(o)) {
    return false;
  }

  // If has modified constructor
  const ctor = o.constructor;
  if (typeof ctor === 'undefined') {
    return true;
  }

  // If has modified prototype
  const prot = ctor.prototype;
  if (!hasObjectPrototype(prot)) {
    return false;
  }

  // If constructor does not have an Object-specific method
  if (!prot.hasOwnProperty('isPrototypeOf')) {
    return false;
  }

  // Most likely a plain Object
  return true;
}

// Copied from: https://github.com/tannerlinsley/react-query/blob/v2.26.2/src/core/utils.ts
function stableStringifyReplacer(_key: string, value: any): unknown {
  if (typeof value === 'function') {
    throw new Error();
  }

  if (isPlainObject(value)) {
    return Object.keys(value)
      .sort()
      .reduce((result, key) => {
        result[key] = value[key];
        return result;
      }, {} as any);
  }

  return value;
}

// Copied from: https://github.com/tannerlinsley/react-query/blob/v2.26.2/src/core/utils.ts
export function stableStringify(value: any): string {
  return JSON.stringify(value, stableStringifyReplacer);
}

// Copied from: https://github.com/tannerlinsley/react-query/blob/v2.26.2/src/core/utils.ts
export function deepIncludes(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (typeof a !== typeof b) {
    return false;
  }

  if (typeof a === 'object') {
    return !Object.keys(b).some((key) => !deepIncludes(a[key], b[key]));
  }

  return false;
}
