import { ColDef, RowParams, RowsProp } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from 'react-router';

import { FilterOptions, usePagedList } from '../../api/common';
import { Member, UNNAMED_MEMBER, useGenders, useRaces, useRelationships } from '../../api/member';
import DataGrid from '../../components/datagrid/DataGrid';
import {
  BooleanOperator,
  ContainsOperator,
  DateTimeRangeOperators,
  NumberRangeOperators,
  setColumnLookupValueFilter,
} from '../../components/datagrid/Operators';
import useFilter from '../../components/datagrid/useFilter';
import usePagination from '../../components/datagrid/usePagination';
import useSorting from '../../components/datagrid/useSorting';
import { API_MEMBERS, DASHBOARD_MEMBER } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    filterOperators: ContainsOperator,
    valueGetter: (params) => params.row.name ?? UNNAMED_MEMBER,
  },
  {
    field: 'relationship',
    headerName: 'Relationship',
    width: 250,
    valueGetter: (params) => params.row.relationship?.title ?? '',
  },
  { field: 'age', headerName: 'Age', width: 120, filterOperators: NumberRangeOperators },
  {
    field: 'gender',
    headerName: 'Gender',
    width: 120,
    valueGetter: (params) => params.row.gender?.title ?? '',
  },
  {
    field: 'race',
    headerName: 'Race',
    width: 250,
    valueGetter: (params) => params.row.race?.title ?? '',
  },
  {
    field: 'isHispanic',
    headerName: 'Hispanic',
    width: 150,
    filterOperators: BooleanOperator,
    valueGetter: (params) =>
      params.row.isHispanic === true ? 'Yes' : params.row.isHispanic === false ? 'No' : '',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('createdAt') as string),
  },
];

export interface MemberTableProps {
  filter?: FilterOptions;
}

const MemberTable: React.FC<MemberTableProps> = ({ filter: extraFilter }) => {
  const history = useHistory();

  const [filter, filterProps] = useFilter();
  const [sorting, sortingPorps] = useSorting();
  const [pagination, paginationProps] = usePagination();

  const { data: races } = useRaces();
  const { data: genders } = useGenders();
  const { data: relationships } = useRelationships();
  const { data, totalItems, isLoading } = usePagedList<Member>(
    API_MEMBERS,
    { ...filter, ...extraFilter },
    sorting,
    pagination,
  );

  const onRowClick = (params: RowParams) => {
    history.push(DASHBOARD_MEMBER.replace(':id', params.getValue('id') as string));
  };

  const rows: RowsProp = data?.items ?? [];
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'relationship'),
    relationships,
  );
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'gender'),
    genders,
  );
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'race'),
    races,
  );

  return (
    <DataGrid
      {...paginationProps}
      {...filterProps}
      {...sortingPorps}
      rows={rows}
      columns={columns}
      rowCount={totalItems}
      loading={isLoading}
      onRowClick={onRowClick}
    />
  );
};

export default MemberTable;
