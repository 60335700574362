import { IonButton, IonPage } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';

import ButtonSheet from '../../components/buttons/ButtonSheet';
import FormLayout from '../../components/layouts/FormLayout';

const Complete: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <FormLayout
        title="Thank you!"
        subtitle="We will review your recruitment survey response and keep you updated."
      />
      <ButtonSheet>
        <IonButton expand="block" shape="round" onClick={() => history.goBack()}>
          OK
        </IonButton>
      </ButtonSheet>
    </IonPage>
  );
};

export default Complete;
