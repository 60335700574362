import { Box, ListItemText, MenuItem, MenuItemProps } from '@material-ui/core';
import React, { ForwardRefRenderFunction, PropsWithChildren, forwardRef } from 'react';

export interface IconMenuItemProps extends MenuItemProps {
  icon: React.ReactNode;
  text: string;
}

type ComponentType = ForwardRefRenderFunction<HTMLLIElement, PropsWithChildren<IconMenuItemProps>>;

const IconMenuItem: ComponentType = (props, ref) => {
  const { icon, text, ...menuProps } = props;
  return (
    <MenuItem {...menuProps} ref={ref}>
      <Box mr={2} display="flex" alignItems="center">
        {icon}
      </Box>
      <ListItemText primary={text} primaryTypographyProps={{ variant: 'body2' }} />
    </MenuItem>
  );
};

export default forwardRef(IconMenuItem);
