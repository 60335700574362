import { TextareaChangeEventDetail } from '@ionic/core';
import { IonTextarea } from '@ionic/react';
import React from 'react';

type IonTextareaProps = Parameters<typeof IonTextarea>[0];

const IonTextarea_: React.FC<IonTextareaProps> = ({ value, onIonChange, ...props }) => {
  const onIonChange_ = (e: CustomEvent<TextareaChangeEventDetail>) => {
    if (value !== e.detail.value) {
      onIonChange?.(e);
    }
  };

  return <IonTextarea value={value} onIonChange={onIonChange_} {...props} />;
};

export default IonTextarea_;
