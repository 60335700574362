import {
  ColDef,
  FilterItem,
  FilterOperator,
  getNumericColumnOperators,
  getStringOperators,
} from '@material-ui/data-grid';
import { compareAsc, parseISO } from 'date-fns';

import { LookupValue } from '../../api/common';
import DateTimeFilterInput from './DateTimeFilterInput';
import RatingFilterInput from './RatingFilterInput';
import SelectFilterInput from './SelectFilterInput';

export function createSelectOperator(options: LookupValue<any, string>[]): FilterOperator[] {
  return [
    {
      label: 'equals',
      value: 'select',
      getApplyFilterFn(filterItem: FilterItem, column: ColDef) {
        if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
          return null;
        }
        return (params): boolean => {
          const rowValue = column.valueGetter ? column.valueGetter(params) : params.value;
          return filterItem.value === String(rowValue ?? '');
        };
      },
      InputComponent: SelectFilterInput,
      InputComponentProps: { options },
    },
  ];
}

export function setColumnLookupValueFilter(column?: ColDef, options?: LookupValue[]) {
  if (column) {
    if (options?.length) {
      column.filterOperators = createSelectOperator(options);
    } else {
      column.filterable = false;
    }
  }
}

export const BooleanOperator = createSelectOperator([
  { id: true, title: 'Yes' },
  { id: false, title: 'No' },
]);

const numberOperatorMapping: { [key: string]: string } = {
  '=': 'equals',
  '>=': 'numberGeq',
  '<': 'numberLt',
};

export const NumberRangeOperators = getNumericColumnOperators()
  .filter((operator) => Object.keys(numberOperatorMapping).includes(operator.value))
  .map((operator) => ({
    ...operator,
    value: numberOperatorMapping[operator.value],
  }));

export const RatingOperators = NumberRangeOperators.map((operator) => ({
  ...operator,
  InputComponent: RatingFilterInput,
}));

export const ContainsOperator = getStringOperators().filter(
  (operator) => operator.value === 'contains',
);

export const EqualsOperator = getStringOperators().filter(
  (operator) => operator.value === 'equals',
);

export const DateTimeRangeOperators: FilterOperator[] = [
  {
    label: 'before (<)',
    value: 'datetimeBefore',
    getApplyFilterFn(filterItem: FilterItem, column: ColDef) {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return (params): boolean => {
        const rowValue = column.valueGetter ? column.valueGetter(params) : params.value;
        return compareAsc(parseISO(rowValue as string), parseISO(filterItem.value as string)) < 0;
      };
    },
    InputComponent: DateTimeFilterInput,
  },
  {
    label: 'after (>=)',
    value: 'datetimeAfter',
    getApplyFilterFn(filterItem: FilterItem, column: ColDef) {
      if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
        return null;
      }
      return (params): boolean => {
        const rowValue = column.valueGetter ? column.valueGetter(params) : params.value;
        return compareAsc(parseISO(rowValue as string), parseISO(filterItem.value as string)) >= 0;
      };
    },
    InputComponent: DateTimeFilterInput,
  },
];
