import { Capacitor } from '@capacitor/core';
import { QueryObserverConfig, useQuery } from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import { useApi, useLocal } from './base';

export interface Pagination<T> {
  currentPage: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
  items: T[];
}

export interface FilterOptions {
  [key: string]: any;
}

export interface SortingOptions {
  column?: string;
  order?: 'asc' | 'desc';
}

export interface PaginationOptions {
  page: number;
  limit: number;
}

export interface LookupValue<K = number, V = string> {
  id: K;
  title: V;
}

export interface Timestamp {
  createdAt: string;
  updatedAt: string;
}

export function useLookupValues<K = number, V = string>(key: string | string[], url: string) {
  const api = useApi();
  const store = useLocalCache();

  return useQuery<LookupValue<K, V>[]>(
    key,
    async () => {
      const { data } = await api.get<LookupValue<K, V>[]>(url);
      return Array.isArray(data) ? data : ([] as LookupValue<K, V>[]);
    },
    {
      initialData: () => store.get(key),
      onSuccess: (data) => store.set(key, data),
      staleTime: 5 * 60 * 1000,
    },
  );
}

export function useDocument(name: string) {
  const local = useLocal();

  return useQuery(
    [name],
    async () => {
      const { data } = await local.get<string>(`/assets/docs/${name}.md`);
      return data;
    },
    { staleTime: Infinity },
  );
}

export function usePagedList<T>(
  url: string,
  filters?: FilterOptions,
  sorting?: SortingOptions,
  pagination?: PaginationOptions,
  config?: QueryObserverConfig<Pagination<T>>,
) {
  const api = useApi();
  const queryFn = async (
    key: string,
    filters: FilterOptions,
    sorting: SortingOptions,
    pagination: PaginationOptions,
  ) => {
    const params: { [key: string]: any } = { ...filters, ...pagination };
    if (sorting?.column) {
      params['orderBy'] = [sorting.order === 'desc' ? '-' : '', sorting.column].join('');
    }
    const { data } = await api.get<Pagination<T>>(url, { params });
    return data;
  };

  const query = useQuery([url, filters, sorting, pagination], queryFn, { retry: false, ...config });
  return { ...query, totalItems: query.data?.totalItems ?? 0 };
}

export function getGoogleMapsApiKey() {
  let key = '';
  switch (Capacitor.platform) {
    case 'web':
      key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_WEB;
      break;
    case 'android':
      key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_ANDROID;
      break;
    case 'ios':
      key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_IOS;
      break;
  }
  if (!key) {
    throw new Error(`Google Maps API is undefined for platform "${Capacitor.platform}".`);
  }
  return key;
}
