import {
  Box,
  Breadcrumbs,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
  debounce,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { parseISO } from 'date-fns';
import React, { useCallback, useState } from 'react';

import {
  ConfigurableUrls,
  ContactInfo,
  SETTING_CONFIGURABLE_URLS,
  SETTING_CONTACT_INFO,
  SETTING_REGISTRATION_ENABLED,
  SETTING_SITE_CLOSED_DATE,
  SETTING_SURVEY_EXPIRATION_IN_DAYS,
  useSettings,
  useSettingsUpdate,
} from '../../api/setting';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import DashboardHeader from '../../components/typography/DashboardHeader';

const CONFIGURABLE_URLS = [
  {
    key: 'irb_consent_form_url',
    title: 'IRB Consent Form URL',
    description: 'URL to the Institutional Review Board (IRB) consent form',
  },
  {
    key: 'google_play_url',
    title: 'Google Play',
    description: 'URL to the Google Play app details page',
  },
  {
    key: 'apple_app_store_url',
    title: 'Apple App Store',
    description: 'URL to the Apple App Store app details page',
  },
  {
    key: 'twitter_url',
    title: 'Twitter Profile URL',
    description: 'URL to the Twitter profile page',
  },
  {
    key: 'facebook_url',
    title: 'Facebook Page URL',
    description: 'URL to the Facebook page',
  },
  {
    key: 'instagram_url',
    title: 'Instagram Page URL',
    description: 'URL to the Instagram page',
  },
  {
    key: 'youtube_playlist_url',
    title: 'YouTube Playlist URL',
    description: 'URL to the YouTube playlist of intro videos',
  },
  {
    key: 'android_tutorial_recruitment_survey',
    title: 'Recruitment Survey Tutorial (Android)',
    description: 'URL to the recruitment survey tutorial video for Android',
  },
  {
    key: 'android_tutorial_trip_tracking',
    title: 'Trip Tracking Tutorial (Android)',
    description: 'URL to the trip tracking tutorial video for Android',
  },
  {
    key: 'android_tutorial_settings',
    title: 'App Settings Tutorial (Android)',
    description: 'URL to the app settings tutorial video for Android',
  },
  {
    key: 'ios_tutorial_recruitment_survey',
    title: 'Recruitment Survey Tutorial (iOS)',
    description: 'URL to the recruitment survey tutorial video for iOS',
  },
  {
    key: 'ios_tutorial_trip_tracking',
    title: 'Trip Tracking Tutorial (iOS)',
    description: 'URL to the trip tracking tutorial video for iOS',
  },
  {
    key: 'ios_tutorial_settings',
    title: 'App Settings Tutorial (iOS)',
    description: 'URL to the app settings tutorial video for iOS',
  },
];

const getSiteClosedDateFromSettings = (settings: Record<string, any>) => {
  try {
    if (settings?.[SETTING_SITE_CLOSED_DATE]) {
      return parseISO(settings?.[SETTING_SITE_CLOSED_DATE]);
    }
  } catch (e) {}
  return null;
};

const Settings: React.FC = () => {
  const { data: settings } = useSettings({
    onSuccess(settings) {
      setIsRegistrationEnabled(settings?.[SETTING_REGISTRATION_ENABLED] ?? true);
      setSurveyExpiry(settings?.[SETTING_SURVEY_EXPIRATION_IN_DAYS] ?? null);
      setSiteClosedDate(getSiteClosedDateFromSettings(settings));
      setContactInfo(settings?.[SETTING_CONTACT_INFO] ?? null);
      setConfigurableUrls(settings?.[SETTING_CONFIGURABLE_URLS] ?? null);
    },
  });
  const [update, resUpdate] = useSettingsUpdate();

  const [isRegistrationEnabled, setIsRegistrationEnabled] = useState<boolean>(
    settings?.[SETTING_REGISTRATION_ENABLED] ?? true,
  );
  const [surveyExpiry, setSurveyExpiry] = useState<number | null>(
    settings?.[SETTING_SURVEY_EXPIRATION_IN_DAYS] ?? null,
  );
  const [siteClosedDate, setSiteClosedDate] = useState<Date | null>(
    settings ? getSiteClosedDateFromSettings(settings) : null,
  );
  const [contactInfo, setContactInfo] = useState<ContactInfo | null>(
    settings?.[SETTING_CONTACT_INFO] ?? null,
  );
  const [configurableUrls, setConfigurableUrls] = useState<ConfigurableUrls | null>(
    settings?.[SETTING_CONFIGURABLE_URLS] ?? null,
  );

  const [isSiteClosedDateDialogOpen, setIsSiteClosedDateDialogOpen] = useState(false);
  const [isInvalidSiteClosedDate, setIsInvalidSiteClosedDate] = useState(false);

  const updateRegistrationEnabled = useCallback(
    debounce((checked: boolean) => update({ [SETTING_REGISTRATION_ENABLED]: checked }), 500),
    [update],
  );

  const onRegistrationEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRegistrationEnabled(event.target.checked);
    updateRegistrationEnabled(event.target.checked);
  };

  const updateSurveyExpiry = useCallback(
    debounce((value: number | null) => update({ [SETTING_SURVEY_EXPIRATION_IN_DAYS]: value }), 500),
    [update],
  );

  const onSurveyExpiryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: number | null = null;
    try {
      value = parseInt(event.target.value);
      value = isNaN(value) ? null : value;
    } catch (e) {}
    setSurveyExpiry(value);
    updateSurveyExpiry(value);
  };

  const updateSiteClosedDate = useCallback(
    debounce((date: Date | null) => update({ [SETTING_SITE_CLOSED_DATE]: date }), 500),
    [update],
  );

  const onSiteClosedDateChange = (date: Date | null) => {
    if (!isSiteClosedDateDialogOpen) {
      if (date === null || !isNaN(date.getTime())) {
        setSiteClosedDate(date);
        setIsInvalidSiteClosedDate(false);
        updateSiteClosedDate(date);
      } else {
        setIsInvalidSiteClosedDate(true);
      }
    }
  };

  const onSiteClosedDateAccept = (date: Date | null) => {
    setSiteClosedDate(date);
    setIsInvalidSiteClosedDate(false);
    update({ [SETTING_SITE_CLOSED_DATE]: date });
  };

  const updateContactInfo = useCallback(
    debounce((info: ContactInfo) => update({ [SETTING_CONTACT_INFO]: info }), 500),
    [update],
  );

  const onContactInfoChange = (key: string, value: string) => {
    const info = { ...contactInfo, [key]: value === '' ? null : value } as ContactInfo;
    setContactInfo(info);
    updateContactInfo(info);
  };

  const updateConfigurableUrls = useCallback(
    debounce((urls: ConfigurableUrls) => update({ [SETTING_CONFIGURABLE_URLS]: urls }), 500),
    [update],
  );

  const onConfigurableUrlsChange = (key: string, value: string) => {
    const urls = { ...configurableUrls, [key]: value === '' ? null : value } as ConfigurableUrls;
    setConfigurableUrls(urls);
    updateConfigurableUrls(urls);
  };

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Typography color="textPrimary" variant="overline">
            Settings
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          Settings
        </Typography>
      </DashboardHeader>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <Paper variant="outlined">
              <Box px={2} py={1}>
                <Typography color="textSecondary" variant="overline">
                  Site settings
                </Typography>
              </Box>
              <Box px={2} py={1}>
                <Box display="flex" flexDirection="row" pb={2}>
                  <Box flexGrow={1}>
                    <Typography>Public registration</Typography>
                    <Typography color="textSecondary" variant="caption">
                      Public registration is currently{' '}
                      {isRegistrationEnabled ? 'enabled' : 'disabled'}
                    </Typography>
                  </Box>
                  <Switch checked={isRegistrationEnabled} onChange={onRegistrationEnabledChange} />
                </Box>
                <Box display="flex" flexDirection="column" pb={2}>
                  <Box mb={1}>
                    <Typography>Site closed date</Typography>
                    <Typography color="textSecondary" variant="caption">
                      Enter a date after which this app is closed to non-admin users
                    </Typography>
                  </Box>
                  <DateTimePicker<Date>
                    clearable
                    value={siteClosedDate}
                    onChange={onSiteClosedDateChange}
                    onAccept={onSiteClosedDateAccept}
                    onOpen={() => setIsSiteClosedDateDialogOpen(true)}
                    onClose={() => setIsSiteClosedDateDialogOpen(false)}
                    inputFormat="yyyy/MM/dd HH:mm:ss"
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        variant="standard"
                        helperText={null}
                        placeholder="yyyy/MM/dd HH:mm:ss"
                        error={isInvalidSiteClosedDate}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box mb={2}>
            <Paper variant="outlined">
              <Box px={2} py={1}>
                <Typography color="textSecondary" variant="overline">
                  Survey settings
                </Typography>
              </Box>
              <Box px={2} py={1}>
                <Box display="flex" flexDirection="column" pb={2}>
                  <Box mb={1}>
                    <Typography>Survey expiry</Typography>
                    <Typography color="textSecondary" variant="caption">
                      Number of days from the user's recruitment survey is accepted to the day a
                      user's survey is ended. Leave empty to disable survey expiry.
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    type="number"
                    placeholder="Enter number of days"
                    value={surveyExpiry ?? ''}
                    onChange={onSurveyExpiryChange}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box>
            <Paper variant="outlined">
              <Box px={2} py={1}>
                <Typography color="textSecondary" variant="overline">
                  Contact information
                </Typography>
              </Box>
              <Box px={2} py={1}>
                <Box display="flex" flexDirection="column" pb={2}>
                  <Box mb={1}>
                    <Typography>Name</Typography>
                    <Typography color="textSecondary" variant="caption">
                      Name of the contact
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    placeholder="Enter contact name"
                    value={contactInfo?.name ?? ''}
                    onChange={(e) => onContactInfoChange('name', e.target.value)}
                  />
                </Box>
                <Box display="flex" flexDirection="column" pb={2}>
                  <Box mb={1}>
                    <Typography>Email</Typography>
                    <Typography color="textSecondary" variant="caption">
                      Email address to contact
                    </Typography>
                  </Box>
                  <TextField
                    fullWidth
                    type="email"
                    placeholder="Enter contact email"
                    value={contactInfo?.email ?? ''}
                    onChange={(e) => onContactInfoChange('email', e.target.value)}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Paper variant="outlined">
              <Box px={2} py={1}>
                <Typography color="textSecondary" variant="overline">
                  URL settings
                </Typography>
              </Box>
              <Box px={2} py={1}>
                {CONFIGURABLE_URLS.map(({ key, title, description }) => (
                  <Box key={key} display="flex" flexDirection="column" pb={2}>
                    <Box mb={1}>
                      <Typography>{title}</Typography>
                      <Typography color="textSecondary" variant="caption">
                        {description}
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      placeholder="Enter URL"
                      value={configurableUrls?.[key] ?? ''}
                      onChange={(e) => onConfigurableUrlsChange(key, e.target.value)}
                    />
                  </Box>
                ))}
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <QueryResultToast result={resUpdate}>Settings have been updated.</QueryResultToast>
    </DashboardLayout>
  );
};

export default Settings;
