import { SelectChangeEventDetail } from '@ionic/core';
import { IonSelect } from '@ionic/react';
import React from 'react';

type IonSelectProps = Parameters<typeof IonSelect>[0];

const IonSelect_: React.FC<IonSelectProps> = ({ value, onIonChange, ...props }) => {
  const onIonChange_ = (e: CustomEvent<SelectChangeEventDetail>) => {
    if (value !== e.detail.value) {
      onIonChange?.(e);
    }
  };

  return <IonSelect value={value} onIonChange={onIonChange_} {...props} />;
};

export default IonSelect_;
