import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import React from 'react';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import TripTable from '../../components/tables/TripTable';
import DashboardHeader from '../../components/typography/DashboardHeader';

const TripList: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Typography color="inherit" variant="overline">
            Data
          </Typography>
          <Typography color="textPrimary" variant="overline">
            Trips
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          All Trips
        </Typography>
      </DashboardHeader>
      <Paper variant="outlined">
        <TripTable />
      </Paper>
    </DashboardLayout>
  );
};

export default TripList;
