import {
  IonButton,
  IonCardContent,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonToast,
} from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import { Coords } from 'google-map-react';
import { arrowBackOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { useTrip } from '../../api/trip';
import { ThemeContext } from '../../components/ThemeProvider';
import Map from '../../components/map/Map';
import MapCard from '../../components/map/MapCard';
import MapControls from '../../components/map/MapControls';
import MapMarker from '../../components/map/MapMarker';
import useDrawTrack from '../../components/map/useDrawTrack';

const useStyles = makeStyles({
  controls: {
    padding: 0,
    '& ion-button': {
      margin: 0,
    },
  },
});

const TripMap: React.FC = () => {
  const classes = useStyles();
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [mapApi, setMapApi] = useState<{ map: any; maps: any }>();
  const [center, setCenter] = useState<Coords>();

  const [isDrawFailVisible, setIsDrawFailVisible] = useState(false);

  const { data: trip } = useTrip(id, {
    onError() {
      setIsDrawFailVisible(true);
    },
  });

  const coordinates = trip?.coordinates;
  const { resize } = useDrawTrack(mapApi, coordinates);

  let start: Coords | null = null;
  let end: Coords | null = null;
  if (coordinates && coordinates.length) {
    start = { lat: coordinates[0].lat, lng: coordinates[0].lng };
    if (coordinates.length > 1) {
      end = {
        lat: coordinates[coordinates.length - 1].lat,
        lng: coordinates[coordinates.length - 1].lng,
      };
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen className="fullscreen">
        <IonFab vertical="top" horizontal="start" slot="fixed" className="flat">
          <IonFabButton
            color={theme.isDarkMode ? 'light' : 'medium'}
            onClick={() => history.goBack()}>
            <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonFab>
        <Map onGoogleApiLoaded={setMapApi}>
          {center ? <MapMarker type="current" {...center} /> : null}
          {start ? <MapMarker type="start" {...start} /> : null}
          {end ? <MapMarker type="end" {...end} /> : null}
        </Map>
        <MapCard bottom right>
          <MapControls mapApi={mapApi} onCenterLocated={setCenter} />
        </MapCard>
        <MapCard bottom left>
          <IonCardContent className={classes.controls}>
            <IonButton fill="clear" size="small" onClick={() => resize()}>
              Reset View
            </IonButton>
          </IonCardContent>
        </MapCard>
      </IonContent>
      <IonToast
        duration={2000}
        color="danger"
        message="Failed to load trip track"
        isOpen={isDrawFailVisible}
        onDidDismiss={() => setIsDrawFailVisible(false)}
      />
    </IonPage>
  );
};

export default TripMap;
