import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';
import { AxiosError } from 'axios';
import { isEmail } from 'class-validator';
import React, { useState } from 'react';
import { QueryStatus } from 'react-query';
import { useLocation } from 'react-router';

import { useSignIn, useSignOut, useToken } from '../../api/auth';
import { useAcceptMemberInvite } from '../../api/member';
import { useSelfUser } from '../../api/user';
import ImageLanding from '../../components/layouts/ImageLanding';
import { getErrorMessage } from '../../utils/error';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    maxWidth: '360px',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const SignInForm: React.FC = () => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signIn, { status, error }] = useSignIn();

  const onEmailChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = () => signIn({ email, password });

  const isEmailValid = isEmail(email);
  const isLoading = status === QueryStatus.Loading;
  const canSubmit = !isLoading && !!email && isEmailValid && !!password;
  const responseStatus = (error as AxiosError)?.response?.status;

  let helperText = '';
  if (status === QueryStatus.Error) {
    helperText = 'Failed to sign in. ';
    switch (responseStatus) {
      case 401:
        helperText += 'Please check if email and password are correct.';
        break;
      case 403:
        helperText += 'Survey has ended.';
        break;
      default:
        if (error) {
          helperText += getErrorMessage(error);
        }
        break;
    }
  }

  return (
    <Box className={classes.form} my={1} display="flex" flexDirection="column">
      <TextField
        required
        fullWidth
        type="email"
        label="Email"
        variant="standard"
        onChange={onEmailChange}
      />
      <TextField
        required
        fullWidth
        type="password"
        label="Password"
        variant="standard"
        onChange={onPasswordChange}
      />
      {status === QueryStatus.Error ? <FormHelperText error>{helperText}</FormHelperText> : null}
      <Box my={2}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          disabled={!canSubmit}
          onClick={onSubmit}>
          {status === QueryStatus.Loading ? 'Loading...' : 'Sign In'}
        </Button>
      </Box>
      <FormHelperText>Do not have an account? Sign up now in the HTS@TN app.</FormHelperText>
    </Box>
  );
};

const MemberInvite: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const member = params.get('member');
  const token = params.get('token');

  const isValidLink = Boolean(member) && Boolean(token);

  const { data: userToken } = useToken();
  const { data: user } = useSelfUser({ enabled: isValidLink && Boolean(userToken), retry: 1 });

  const [signOut] = useSignOut();
  const [accept, { status, error, reset }] = useAcceptMemberInvite();

  const onSubmit = () => {
    accept({ token: token!, member: member! });
  };

  const onSwitchUser = () => {
    signOut();
    reset();
  };

  let icon = null;
  let subtitle = '';
  if (!isValidLink) {
    icon = <ErrorOutline color="error" />;
    subtitle = 'Invalid member invitation link.';
  } else if (!user) {
    subtitle = 'Sign in to join the HTS@TN household.';
  } else {
    switch (status) {
      case QueryStatus.Success:
        icon = <CheckCircleOutline color="primary" />;
        subtitle = 'Congratulations! You have joined the household.';
        break;
      case QueryStatus.Loading:
        icon = <CircularProgress color="inherit" size={20} />;
        subtitle = 'Accepting the invitation and joining the household...';
        break;
      case QueryStatus.Idle:
        subtitle = 'Click the button below to accept the invitation.';
        break;
      case QueryStatus.Error:
        icon = <ErrorOutline color="error" />;
        subtitle = 'Failed to accept the invitation. ';
        switch ((error as AxiosError).response?.status) {
          case 400:
            subtitle +=
              ((error as AxiosError).response?.data?.message ?? 'Invalid invitation link') + '.';
            break;
          case 403:
            subtitle += 'Survey has ended.';
            break;
          default:
            subtitle += getErrorMessage(error);
            break;
        }
        break;
    }
  }

  return (
    <ImageLanding
      title={
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
          {icon ? (
            <Box mr={1} component="span" display="flex" flexDirection="row" alignItems="center">
              {icon}
            </Box>
          ) : null}
          <Typography color="textPrimary" component="h1" variant="h5">
            You Are Invited!
          </Typography>
        </Box>
      }
      subtitle={subtitle}
      image="/assets/images/landing/clip-family.svg">
      {isValidLink ? (
        !user ? (
          <SignInForm />
        ) : status !== QueryStatus.Success ? (
          <>
            <Box className={classes.form} mt={3} mb={2} display="flex" flexDirection="column">
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={status === QueryStatus.Loading}>
                {status === QueryStatus.Loading ? 'Loading...' : 'Accept Invitation'}
              </Button>
            </Box>
            <FormHelperText>
              Not using {user.email}?{' '}
              <Link style={{ cursor: 'pointer' }} onClick={onSwitchUser}>
                Sign out
              </Link>
              .
            </FormHelperText>
          </>
        ) : null
      ) : null}
    </ImageLanding>
  );
};

export default MemberInvite;
