import { useEffect, useState } from 'react';

interface LatLng {
  lat: number;
  lng: number;
}

export default function useDrawTrack(mapApi?: any, coordinates?: LatLng[]) {
  const [resetCounter, setResetCounter] = useState(0);
  const [updateCounter, setUpdateCounter] = useState(0);

  useEffect(() => {
    if (mapApi && coordinates?.length) {
      const points = coordinates.map(
        (coordinate) => new mapApi.maps.LatLng(coordinate.lat, coordinate.lng),
      );

      const route = new mapApi.maps.Polyline({
        path: points,
        strokeColor: '#3880ff',
        strokeOpacity: 0.8,
        strokeWeight: 4,
      });

      route.setMap(mapApi.map);

      return () => route.setMap(null);
    }
  }, [mapApi, coordinates, updateCounter]);

  useEffect(() => {
    if (mapApi && coordinates?.length) {
      const bounds = new mapApi.maps.LatLngBounds();
      for (const coordinate of coordinates) {
        bounds.extend(new mapApi.maps.LatLng(coordinate.lat, coordinate.lng));
      }
      mapApi.map.fitBounds(bounds);
    }
  }, [mapApi, coordinates, resetCounter]);

  const redraw = () => {
    setUpdateCounter((state) => state + 1);
  };

  const resize = () => {
    setResetCounter((state) => state + 1);
  };

  return { redraw, resize };
}
