import { IonLabel, IonList, IonListHeader, IonPage } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import { DateRange, MobileDateRangePicker } from '@material-ui/pickers';
import { addDays, format, formatISO, parseISO } from 'date-fns';
import { filterOutline } from 'ionicons/icons';
import React, { useState } from 'react';

import { Trip, useAllTrips } from '../../api/trip';
import { isValidUser, useSelfUser } from '../../api/user';
import TripCard from '../../components/cards/TripCard';
import InfiniteScroll from '../../components/containers/InfiniteScroll';
import FormLayout from '../../components/layouts/FormLayout';
import { APP_TRIP } from '../../urls';

const useStyles = makeStyles({
  container: {
    '&::part(background)': {
      background:
        'url(/assets/images/app/fogg-logged-out.svg) center calc(100% + 25px) / 400px no-repeat',
    },
  },
});

function removeTime(date: Date) {
  return parseISO(formatISO(date, { representation: 'date' }));
}

const TripList: React.FC = () => {
  const classes = useStyles();

  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);

  const { data: user } = useSelfUser();
  const filters = {
    user: user?.id,
    startAfter: dateRange[0] ? formatISO(removeTime(dateRange[0])) : undefined,
    endBefore: dateRange[1] ? formatISO(removeTime(addDays(dateRange[1], 1))) : undefined,
  };
  const { data, isFetching, isInitialData, fetchMore, canFetchMore } = useAllTrips(filters, {
    enabled: isValidUser(user),
  });

  const onDateRangeChange = (date: DateRange<Date> | null) => {
    setDateRange(date ? date : [null, null]);
  };

  const bucket = new Set();
  const trips = new Map<string, Trip[]>();

  (Array.isArray(data) ? data : []).forEach((trip) => {
    if (bucket.has(trip.id)) {
      return;
    }
    bucket.add(trip.id);
    const month = format(new Date(trip.startAt), 'MMMM u');
    if (!trips.has(month)) {
      trips.set(month, []);
    }
    trips.get(month)?.push(trip);
  });

  const buttons = [
    {
      slot: 'end',
      color: 'light',
      icon: filterOutline,
      onClick: () => setIsDatePickerVisible(true),
    },
  ];

  const subtitle =
    isInitialData && isFetching
      ? 'Loading your trips...'
      : trips.size
      ? 'See all the trips you took.'
      : 'No trip found.';

  return (
    <IonPage>
      <FormLayout buttons={buttons} className={classes.container} title="Trips" subtitle={subtitle}>
        <IonList style={{ margin: '1 calc(0px - var(--ion-margin) / 2)' }}>
          {Array.from(trips.entries()).map(([month, tripList]) => (
            <React.Fragment key={month}>
              <IonListHeader>
                <IonLabel>{month}</IonLabel>
              </IonListHeader>
              {tripList.map((trip) => (
                <TripCard
                  key={trip.id}
                  date={parseISO(trip.endAt)}
                  link={APP_TRIP.replace(':id?', trip.id)}
                  address={trip.endAddress}
                  draft={trip.id.startsWith('draft:')}
                  purpose={trip.purpose?.title ?? 'Unknown activity'}
                />
              ))}
            </React.Fragment>
          ))}
        </IonList>
        <InfiniteScroll
          disabled={!canFetchMore}
          onIonInfinite={async (e: any) => {
            await fetchMore();
            e.target.complete();
          }}
        />
        <MobileDateRangePicker<Date>
          clearable
          value={dateRange}
          open={isDatePickerVisible}
          onChange={() => {}}
          renderInput={() => <></>}
          onOpen={() => setIsDatePickerVisible(true)}
          onClose={() => setIsDatePickerVisible(false)}
          onAccept={onDateRangeChange as any}
        />
      </FormLayout>
    </IonPage>
  );
};

export default TripList;
