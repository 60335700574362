import {
  MutationConfig,
  QueryObserverConfig,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import {
  API_USER,
  API_USER_DISABLE,
  API_USER_ENABLE,
  API_USER_PASSWORD,
  API_USER_PROFILE,
} from '../urls';
import { useApi } from './base';
import { Timestamp } from './common';

export interface UserProfile {
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
}

export interface UserRole {
  id: string;
  title: string;
}

export interface UserProfileUpdate extends Partial<UserProfile> {}

export interface UserPasswordUpdate {
  oldPassword: string;
  newPassword: string;
}

export interface User extends Timestamp {
  id: string;
  role: UserRole | null;
  email: string;
  profile: UserProfile;
  householdOwnedId: string | null;
  memberLinkedId: string | null;
  verifiedAt: string;
  disabledAt: string;
  referrer: string | null;
}

export function isUserDisabled(user?: User) {
  return user && Boolean(user.disabledAt);
}

export function isUserVerified(user?: User) {
  return user && Boolean(user.verifiedAt);
}

export function isValidUser(user?: User) {
  return isUserVerified(user) && !isUserDisabled(user);
}

export function useUser(id: string, config?: QueryObserverConfig<User>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['user', id],
    async (key: string, id: string) => {
      const { data } = await api.get<User>(API_USER(id));
      return data;
    },
    {
      initialData: initialData ?? (() => store.get(['user', id])),
      onSuccess(data) {
        store.set(['user', id], data);
        if (data && data.id !== id) {
          cache.setQueryData(['user', data.id], data);
          store.set(['user', data.id], data);
        }
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useSelfUser(config?: QueryObserverConfig<User>) {
  return useUser('self', config);
}

export function useSelfUserUpdate(config?: MutationConfig<User, unknown, UserProfileUpdate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (profile: UserProfileUpdate) => {
      const user = await cache.fetchQuery(['user', 'self'], async (key: string, id: string) => {
        const { data } = await api.get<User>(API_USER(id));
        return data;
      });
      const { data } = await api.put<User>(API_USER_PROFILE(user.id), profile);
      return data;
    },
    {
      onSuccess: (user, profile) => {
        cache.setQueryData(['user', 'self'], user);
        store.set(['user', 'self'], user);
        onSuccess?.(user, profile);
      },
      ...config_,
    },
  );
}

export function useUserPasswordUpdate(config?: MutationConfig<void, unknown, UserPasswordUpdate>) {
  const api = useApi();
  const cache = useQueryCache();

  return useMutation(async (password: UserPasswordUpdate) => {
    const user = await cache.fetchQuery(['user', 'self'], async (key: string, id: string) => {
      const { data } = await api.get<User>(API_USER(id));
      return data;
    });
    await api.put<void>(API_USER_PASSWORD(user.id), password);
  }, config);
}

export function useUserEnable(config?: MutationConfig<User, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      const { data } = await api.post<User>(API_USER_ENABLE(id));
      return data;
    },
    {
      onSuccess: (data, id) => {
        store.set(['user', id], data);
        if (data) {
          cache.setQueryData(['user', data.id], data);
          store.set(['user', data.id], data);
        }
        onSuccess?.(data, id);
      },
      ...config_,
    },
  );
}

export function useUserDisable(config?: MutationConfig<User, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      const { data } = await api.post<User>(API_USER_DISABLE(id));
      return data;
    },
    {
      onSuccess: (data, id) => {
        store.set(['user', id], data);
        if (data) {
          cache.setQueryData(['user', data.id], data);
          store.set(['user', data.id], data);
        }
        onSuccess?.(data, id);
      },
      ...config_,
    },
  );
}

export function useUserDelete(config?: MutationConfig<void, unknown, void>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async () => {
      const user = await cache.fetchQuery(['user', 'self'], async (key: string, id: string) => {
        const { data } = await api.get<User>(API_USER(id));
        return data;
      });
      await api.delete<void>(API_USER(user.id));
    },
    {
      onSuccess: () => {
        cache.invalidateQueries(['user', 'self']);
        store.remove(['user', 'self']);
        onSuccess?.();
      },
      ...config_,
    },
  );
}
