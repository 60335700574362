import { Box, Container, makeStyles } from '@material-ui/core';
import React from 'react';

import DashboardNavbar from '../navigation/DashboardNavbar';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.background.default,
    '& *': {
      userSelect: 'text',
    },
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

const DashboardLayout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} display="flex" flexDirection="column">
      <DashboardNavbar />
      <Container className={classes.content}>{children}</Container>
    </Box>
  );
};

export default DashboardLayout;
