import { InputChangeEventDetail } from '@ionic/core';
import {
  IonAlert,
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
} from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { useSelfUser, useUserDelete, useUserPasswordUpdate } from '../api/user';
import ButtonSheet from '../components/buttons/ButtonSheet';
import HelperText from '../components/forms/HelperText';
import ItemInput from '../components/forms/ItemInput';
import ItemText from '../components/forms/ItemText';
import FormLayout from '../components/layouts/FormLayout';
import QueryResultToast from '../components/notification/QueryResultToast';
import isPassword from '../utils/password';

const Security: React.FC = () => {
  const history = useHistory();

  const [update, resPasswordUpdate] = useUserPasswordUpdate({ onSuccess: () => history.goBack() });
  const [deleteUser, resUserDelete] = useUserDelete({ onSuccess: () => history.goBack() });
  const { data: user, ...resQuery } = useSelfUser();

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const onOldPasswordChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setOldPassword(typeof e.detail.value !== 'string' ? '' : e.detail.value);
  };

  const onNewPasswordChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setNewPassword(typeof e.detail.value !== 'string' ? '' : e.detail.value);
  };

  const onConfirmPasswordChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setConfirmPassword(typeof e.detail.value !== 'string' ? '' : e.detail.value);
  };

  const onSubmit = () => update({ oldPassword, newPassword });

  const onUserDeleteClick = () => deleteUser();

  const isOldPasswordValid = Boolean(oldPassword);
  const isNewPasswordValid = isPassword(newPassword);
  const isNewPasswordMatch = newPassword === confirmPassword;
  const isLoading = (resQuery.isInitialData && resQuery.isFetching) || resPasswordUpdate.isLoading;
  const canSubmit = !isLoading && isOldPasswordValid && isNewPasswordValid && isNewPasswordMatch;

  return (
    <IonPage>
      <FormLayout title="Security" subtitle="Protect your account.">
        <IonList className="input-group">
          <IonListHeader>
            <IonLabel>Password</IonLabel>
          </IonListHeader>
          <ItemInput
            label="Current Password"
            type="password"
            value={oldPassword}
            onChange={onOldPasswordChange}
          />
          <ItemInput
            label="New Password"
            type="password"
            value={newPassword}
            onChange={onNewPasswordChange}
          />
          {newPassword && !isNewPasswordValid ? (
            <HelperText color="error">
              At least 10 characters, 1 uppercase letter, 1 number, 1 symbol
            </HelperText>
          ) : null}
          <ItemInput
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
          />
          {confirmPassword && !isNewPasswordMatch ? (
            <HelperText color="error">Password does not match the new password</HelperText>
          ) : null}
          <IonListHeader>
            <IonLabel>Account</IonLabel>
          </IonListHeader>
          <ItemText
            title="Delete Account"
            color="danger"
            onClick={() => setIsDeleteDialogVisible(true)}>
            <IonIcon icon={trashOutline} color="danger" slot="start" />
          </ItemText>
        </IonList>
      </FormLayout>
      <ButtonSheet>
        <IonButton shape="round" expand="block" onClick={onSubmit} disabled={!canSubmit}>
          {isLoading ? 'Loading...' : 'Save'}
        </IonButton>
      </ButtonSheet>
      <IonAlert
        isOpen={isDeleteDialogVisible}
        onDidDismiss={() => setIsDeleteDialogVisible(false)}
        header="Delete user account?"
        message={`Do you want to delete your user account?
          Your recruitment survey response will also be deleted.
          This action is irreversible!`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => setIsDeleteDialogVisible(false),
          },
          {
            text: 'Delete',
            role: 'destructive',
            cssClass: 'danger',
            handler: onUserDeleteClick,
          },
        ]}
      />
      <QueryResultToast result={resUserDelete}>User account has been removed.</QueryResultToast>
      <QueryResultToast result={resPasswordUpdate}>User profile has been updated.</QueryResultToast>
    </IonPage>
  );
};

export default Security;
