import { makeStyles } from '@material-ui/core';
import { FilterInputValueProps } from '@material-ui/data-grid';
import { Rating, RatingProps } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 48,
    paddingLeft: 20,
  },
});

const RatingFilterInput: React.FC<FilterInputValueProps> = (props) => {
  const classes = useStyles();
  const { item, applyValue } = props;

  const onRatingChange: RatingProps['onChange'] = (event, value) => {
    applyValue({ ...item, value: value ? String(value) : undefined });
  };

  return (
    <div className={classes.root}>
      <Rating name="rating" precision={1} value={Number(item.value)} onChange={onRatingChange} />
    </div>
  );
};

export default RatingFilterInput;
