import { InputChangeEventDetail, TextFieldTypes } from '@ionic/core';
import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';

import IonInput from './patch/IonInput';

export interface ItemInputProps {
  required?: boolean;
  readonly?: boolean;
  type?: TextFieldTypes;
  label?: string;
  addons?: React.ReactNode;
  value?: string | number | null;
  onChange?: (event: CustomEvent<InputChangeEventDetail>) => void;
  onFocus?: (event: CustomEvent<FocusEvent>) => void;
  onInputClick?: (event: React.MouseEvent<HTMLIonInputElement>) => void;
}

const ItemInput: React.FC<ItemInputProps> = (props) => {
  const { required, readonly, type, label, value, addons, onChange, onFocus, onInputClick } = props;

  return (
    <IonItem className={readonly ? 'readonly' : ''}>
      <IonLabel position="floating">
        {label}
        {required ? <em className="required">*</em> : null}
      </IonLabel>
      <IonInput
        type={type}
        value={value}
        readonly={readonly}
        onIonChange={onChange}
        onIonFocus={onFocus}
        onClick={onInputClick}
      />
      {addons}
    </IonItem>
  );
};

export default ItemInput;
