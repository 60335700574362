import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonText,
} from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { alertCircleOutline } from 'ionicons/icons';
import React from 'react';

export interface CardProps {
  date: Date;
  link: string;
  address: string;
  purpose: string;
  draft?: boolean;
  className?: string;
}

const useStyles = makeStyles({
  root: {
    backgroundColor: 'rgba(var(--ion-background-color-rgb), 0.618)',
  },
  metadata: {
    fontSize: '.85rem',
    justifyContent: 'space-between',
    '&, & ion-text': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    '& ion-icon': {
      fontSize: '1.2em',
      marginRight: 'calc(var(--ion-margin) / 4)',
    },
  },
});

const TripCard: React.FC<CardProps> = (props) => {
  const { className, date, address, purpose, draft, link } = props;
  const classes = useStyles();

  return (
    <IonCard button routerLink={link} className={[classes.root, className].join(' ')}>
      <IonCardHeader>
        <IonCardSubtitle>{address}</IonCardSubtitle>
        <IonCardTitle>{purpose}</IonCardTitle>
        <IonCardSubtitle className={classes.metadata}>
          <IonText color="primary">{format(date, 'MMM d, hh:mm a')}</IonText>
          {draft ? (
            <IonText color="medium">
              <IonIcon icon={alertCircleOutline} />
              <IonText>Not saved</IonText>
            </IonText>
          ) : null}
        </IonCardSubtitle>
      </IonCardHeader>
    </IonCard>
  );
};

export default TripCard;
