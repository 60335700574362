import { Capacitor, Plugins } from '@capacitor/core';
import { MutationConfig, useMutation, useQuery, useQueryCache } from 'react-query';

import '../capacitor/tracking';
import {
  API_PASSWORD_RESET,
  API_RESEND_EMAIL,
  API_SIGNIN,
  API_SIGNUP,
  API_VERIFY_EMAIL,
} from '../urls';
import { useApi } from './base';

const { Storage, Tracking } = Plugins;

export interface AuthRequest {
  email: string;
  password: string;
}

export interface AuthResponse {
  token: string;
}

export interface SignInRequest extends AuthRequest {}

export interface SignUpRequest extends AuthRequest {
  referrer?: string | null;
}

export interface ResetPasswordTokenRequest {
  email: string;
}

export interface ResetPasswordRequest extends AuthRequest {
  token: string;
}

export function useToken() {
  const cache = useQueryCache();

  return useQuery(
    'token',
    async () => {
      const token = cache.getQueryData<string>('token');
      if (token) {
        return token;
      }
      const { value } = await Storage.get({ key: 'token' });
      if (value) {
        cache.setQueryData('token', value, { staleTime: Infinity });
        return value;
      }
    },
    { staleTime: Infinity },
  );
}

export function useSignIn(config?: MutationConfig<string, unknown, SignInRequest>) {
  const api = useApi();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (credentials: SignInRequest) => {
      const { data } = await api.post<AuthResponse>(API_SIGNIN, credentials);
      return data.token;
    },
    {
      onSuccess: async (token, variables) => {
        await Storage.set({ key: 'token', value: token });
        await cache.invalidateQueries('token');
        onSuccess?.(token, variables);
      },
      ...config_,
    },
  );
}

export function useSignOut(config?: MutationConfig<void>) {
  const cache = useQueryCache();

  return useMutation(async () => {
    if (Capacitor.isNative) {
      await Tracking.stopTracking();
    }
    await Storage.remove({ key: 'token' });
    localStorage.clear();
    cache.setQueryData('token', undefined);
    cache.clear();
  }, config);
}

export function useSignUp(config?: MutationConfig<void, unknown, SignUpRequest>) {
  const api = useApi();

  return useMutation(async (credentials: SignUpRequest) => {
    await api.post(API_SIGNUP, credentials);
  }, config);
}

export function useResendEmail(config?: MutationConfig<void, unknown, void>) {
  const api = useApi();

  return useMutation(async () => {
    await api.get(API_RESEND_EMAIL);
  }, config);
}

export function useVerifyEmail(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();

  return useMutation(async (token: string) => {
    await api.get(API_VERIFY_EMAIL, { params: { token } });
  }, config);
}

export function useRequestPasswordReset(
  config?: MutationConfig<void, unknown, ResetPasswordTokenRequest>,
) {
  const api = useApi();

  return useMutation(async (request: ResetPasswordTokenRequest) => {
    await api.post(API_PASSWORD_RESET, request);
  }, config);
}

export function useConfirmPasswordReset(
  config?: MutationConfig<void, unknown, ResetPasswordRequest>,
) {
  const api = useApi();

  return useMutation(async (credentials: ResetPasswordRequest) => {
    await api.put(API_PASSWORD_RESET, credentials);
  }, config);
}
