import { SelectChangeEventDetail, SelectInterface } from '@ionic/core';
import { IonItem, IonLabel } from '@ionic/react';
import React, { useEffect, useRef } from 'react';

import IonSelect from './patch/IonSelect';

export interface ItemSelectProps<T> {
  className?: string;
  required?: boolean;
  readonly?: boolean;
  interface?: SelectInterface;
  interfaceOptions?: any;
  label?: string;
  selectedText?: string | null;
  addons?: React.ReactNode;
  value?: T | null;
  onChange?: (event: CustomEvent<SelectChangeEventDetail>) => void;
  children?: React.ReactNode;
}

function ItemSelect<T>({ children, ...props }: ItemSelectProps<T>) {
  let { interfaceOptions } = props;
  const {
    className,
    required,
    readonly,
    label,
    selectedText,
    value,
    addons,
    onChange,
    interface: iface = 'action-sheet',
  } = props;
  const itemRef = useRef<HTMLIonItemElement>(null);
  const classes = [readonly ? 'readonly' : '', className ?? ''].join(' ').trim();

  if (iface === 'action-sheet') {
    interfaceOptions = Object.assign({}, interfaceOptions, { header: label });
  }

  useEffect(() => {
    if (itemRef.current) {
      if (!selectedText && (typeof value === 'undefined' || value === null)) {
        itemRef.current.classList.remove('item-has-value');
      } else {
        itemRef.current.classList.add('item-has-value');
      }
    }
  }, [value, selectedText, itemRef]);

  return (
    <IonItem ref={itemRef} className={classes}>
      <IonLabel position="floating">
        {label}
        {required ? <em className="required">*</em> : null}
      </IonLabel>
      <IonSelect
        value={value}
        disabled={readonly}
        onIonChange={onChange}
        selectedText={selectedText}
        interface={iface}
        interfaceOptions={interfaceOptions}>
        {children}
      </IonSelect>
      {addons}
    </IonItem>
  );
}

export default ItemSelect;
