import {
  MutationConfig,
  QueryObserverConfig,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import { API_FEEDBACK, API_FEEDBACK_LIST } from '../urls';
import { useApi } from './base';
import { Timestamp } from './common';

export interface FeedbackBase {
  overallRating: number;
  functionalityRating: number;
  usabilityRating: number;
  stabilityRating: number;
  lookAndFeelRating: number;
  userComment: string | null;
}

export interface Feedback extends FeedbackBase, Timestamp {
  id: string;
  userId: string;
}

export interface FeedbackCreate extends FeedbackBase {}

export interface FeedbackUpdate extends Partial<FeedbackCreate> {
  id: string;
}

export function useFeedback(id: string, config?: QueryObserverConfig<Feedback>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['feedback', id],
    async (key: string, id: string) => {
      const { data } = await api.get<Feedback>(API_FEEDBACK(id));
      return data;
    },
    {
      enabled: enabled ?? Boolean(id),
      initialData: initialData ?? (() => store.get(['feedback', id])),
      onSuccess: (data) => {
        store.set(['feedback', id], data);
        if (data && data.id !== id) {
          cache.setQueryData(['feedback', data.id], data);
          store.set(['feedback', data.id], data);
        }
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useSelfFeedback(config?: QueryObserverConfig<Feedback>) {
  return useFeedback('self', config);
}

export function useFeedbackCreate(config?: MutationConfig<Feedback, unknown, FeedbackCreate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (feedback: FeedbackCreate) => {
      const { data } = await api.post<Feedback>(API_FEEDBACK_LIST, feedback);
      return data;
    },
    {
      onSuccess: async (feedback, variables) => {
        cache.setQueryData(['feedback', feedback.id], feedback);
        store.set(['feedback', feedback.id], feedback);
        const selfFeedback = cache.getQueryData<Feedback>(['feedback', 'self']);
        if (!selfFeedback?.id) {
          cache.invalidateQueries(['feedback', 'self']);
          store.remove(['feedback', 'self']);
        }
        onSuccess?.(feedback, variables);
      },
      ...config_,
    },
  );
}

export function useFeedbackUpdate(config?: MutationConfig<Feedback, unknown, FeedbackUpdate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (feedback: FeedbackUpdate) => {
      const { data } = await api.put<Feedback>(API_FEEDBACK(feedback.id), feedback);
      return data;
    },
    {
      onSuccess: (feedback, variables) => {
        cache.setQueryData(['feedback', feedback.id], feedback);
        store.set(['feedback', feedback.id], feedback);
        const selfFeedback = cache.getQueryData<Feedback>(['feedback', 'self']);
        if (feedback?.id === selfFeedback?.id) {
          cache.setQueryData(['feedback', 'self'], feedback);
          store.set(['feedback', 'self'], feedback);
        }
        onSuccess?.(feedback, variables);
      },
      ...config_,
    },
  );
}

export function useFeedbackDelete(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      await api.delete(API_FEEDBACK(id));
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['feedback', id]);
        store.remove(['feedback', id]);
        const selfFeedback = cache.getQueryData<Feedback>(['feedback', 'self']);
        if (id === selfFeedback?.id) {
          cache.invalidateQueries(['feedback', 'self']);
          store.remove(['feedback', 'self']);
        }
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}
