import { IonSelectOption } from '@ionic/react';
import React from 'react';

import ItemSelect, { ItemSelectProps } from './ItemSelect';

export interface KvSelectProps<T extends string | number, V> extends ItemSelectProps<T> {
  entries?: [T, V][];
}

function KvSelect<T extends string | number, V>({ entries, ...props }: KvSelectProps<T, V>) {
  return (
    <ItemSelect {...props}>
      {(entries ?? []).map(([key, value]) => (
        <IonSelectOption key={key} value={key}>
          {value}
        </IonSelectOption>
      ))}
    </ItemSelect>
  );
}

export default KvSelect;
