import { SetStateAction, useCallback, useState } from 'react';

type SetEditStateAction<S> = (state: SetStateAction<S>, skipIfEdited?: boolean) => void;

function useEditState<S>(
  initialState: S | (() => S),
): [S, SetEditStateAction<S>, { edited: boolean; clearEdited: () => void }] {
  const [edited, setEdited] = useState(false);
  const [state, setState] = useState<S>(initialState);

  const setState_ = useCallback(
    (state: SetStateAction<S>, skipIfEdited: boolean = false) => {
      // do not override existing edited state when `skipIfEdited` is true
      if (!skipIfEdited || !edited) {
        setEdited(true);
        setState(state);
      }
    },
    [edited],
  );

  const clearEdited = useCallback(() => setEdited(false), []);

  return [state, setState_, { edited, clearEdited }];
}

export { useEditState };
