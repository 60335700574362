import { makeStyles } from '@material-ui/core';
import React, { ForwardRefRenderFunction, PropsWithChildren, forwardRef } from 'react';

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 calc(var(--ion-padding) / 2) 0',
  },
});

type ComponentType = ForwardRefRenderFunction<HTMLHeadingElement, PropsWithChildren<{}>>;

const Title: ComponentType = ({ children }, ref) => {
  const classes = useStyles();

  return (
    <h1 ref={ref} className={classes.title}>
      {children}
    </h1>
  );
};

export default forwardRef(Title);
