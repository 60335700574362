import { Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { useAddress } from '../../api/address';
import LoadingOrFail from '../../components/containers/LoadingOrFail';
import DashItemText from '../../components/forms/DashItemText';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import DashboardHeader from '../../components/typography/DashboardHeader';
import { DASHBOARD_ADDRESS_LIST, DASHBOARD_USER } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const AddressDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: address, isError } = useAddress(id, { enabled: Boolean(id) });

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Link
            color="inherit"
            variant="overline"
            component={RouterLink}
            to={DASHBOARD_ADDRESS_LIST}>
            Addresses
          </Link>
          <Typography color="textPrimary" variant="overline">
            {id}
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          Address Details
        </Typography>
      </DashboardHeader>
      <LoadingOrFail isLoading={!address} isError={isError}>
        {address ? (
          <Grid container spacing={2}>
            <DashItemText xs={12} md={6} title="ID">
              {id}
            </DashItemText>
            <DashItemText xs={12} md={6} title="User">
              <Link component={RouterLink} to={DASHBOARD_USER.replace(':id', address.userId ?? '')}>
                {address.userId}
              </Link>
            </DashItemText>
            <DashItemText xs={12} md={6} title="Name">
              {address.name ?? '(Not set)'}
            </DashItemText>
            <DashItemText xs={12} md={6} title="Address">
              {address.address ?? '(Not set)'}
            </DashItemText>
            <DashItemText xs={12} sm={6} md={4} title="City">
              {address.city ?? '(Not set)'}
            </DashItemText>
            <DashItemText xs={12} sm={6} md={4} title="State">
              {address.state ?? '(Not set)'}
            </DashItemText>
            <DashItemText xs={12} sm={6} md={4} title="Postal Code">
              {address.zipCode ?? '(Not set)'}
            </DashItemText>
            <DashItemText xs={12} sm={6} md={4} title="Created At">
              {humanizeIsoDate(address.createdAt)}
            </DashItemText>
          </Grid>
        ) : null}
      </LoadingOrFail>
    </DashboardLayout>
  );
};

export default AddressDetails;
