import { Box, Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { Vehicle, getVehicleName, useVehicle } from '../../api/vehicle';
import LoadingOrFail from '../../components/containers/LoadingOrFail';
import DashItemTab, { DashItemTabProps } from '../../components/forms/DashItemTab';
import DashItemTabPanel from '../../components/forms/DashItemTabPanel';
import DashItemText from '../../components/forms/DashItemText';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import TripTable from '../../components/tables/TripTable';
import DashboardHeader from '../../components/typography/DashboardHeader';
import { DASHBOARD_HOUSEHOLD, DASHBOARD_MEMBER, DASHBOARD_VEHICLE_LIST } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const VehicleBasicInfo: React.FC<{ vehicle: Vehicle }> = ({ vehicle }) => {
  return (
    <Box mb={4}>
      <Grid container spacing={2}>
        <DashItemText xs={12} md={6} title="ID">
          {vehicle.id}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Household">
          <Link
            component={RouterLink}
            to={DASHBOARD_HOUSEHOLD.replace(':id', vehicle.householdId ?? '')}>
            {vehicle.householdId}
          </Link>
        </DashItemText>
        <DashItemText xs={12} md={6} title="Model">
          {getVehicleName(vehicle)}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Type">
          {vehicle.type?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Color">
          {vehicle.color?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Fuel">
          {vehicle.fuel?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Bought Used">
          {typeof vehicle.boughtUsed === 'boolean'
            ? vehicle.boughtUsed
              ? 'Yes'
              : 'No'
            : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Possession Time (Year)">
          {typeof vehicle.possessionTime === 'boolean' ? vehicle.possessionTime : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Odometer Reading (Mile)">
          {typeof vehicle.odometerReading === 'boolean' ? vehicle.odometerReading : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Annual Mileage (Mile)">
          {typeof vehicle.annualMileage === 'boolean' ? vehicle.annualMileage : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Driver">
          {typeof vehicle.isHouseholdDriver === 'boolean' ? (
            vehicle.householdDriverId ? (
              <Link
                component={RouterLink}
                to={DASHBOARD_MEMBER.replace(':id', vehicle.householdDriverId ?? '')}>
                {vehicle.householdDriverId}
              </Link>
            ) : (
              'Not household member'
            )
          ) : (
            '(Not set)'
          )}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Created At">
          {humanizeIsoDate(vehicle.createdAt)}
        </DashItemText>
      </Grid>
    </Box>
  );
};

const VehicleLinkedAssets: React.FC<{ vehicle: Vehicle }> = ({ vehicle }) => {
  const tabs = ['transport of'];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const onTabChange: DashItemTabProps['onTabChange'] = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <DashItemTab
      title="Linked Assets"
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={onTabChange}>
      <DashItemTabPanel forTab={tabs[0]} currentTab={currentTab}>
        <TripTable filter={{ isHouseholdVehicle: true, householdVehicle: vehicle.id }} />
      </DashItemTabPanel>
    </DashItemTab>
  );
};

const VehicleDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: vehicle, isError } = useVehicle(id, { enabled: Boolean(id) });

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Link
            color="inherit"
            variant="overline"
            component={RouterLink}
            to={DASHBOARD_VEHICLE_LIST}>
            Vehicles
          </Link>
          <Typography color="textPrimary" variant="overline">
            {id}
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          Vehicle Details
        </Typography>
      </DashboardHeader>
      <LoadingOrFail isLoading={!vehicle} isError={isError}>
        {vehicle ? (
          <>
            <VehicleBasicInfo vehicle={vehicle} />
            <VehicleLinkedAssets vehicle={vehicle} />
          </>
        ) : null}
      </LoadingOrFail>
    </DashboardLayout>
  );
};

export default VehicleDetails;
