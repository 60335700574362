import { InputChangeEventDetail, SelectChangeEventDetail } from '@ionic/core';
import { IonAlert, IonButton, IonList, IonPage } from '@ionic/react';
import { MobileDateTimePicker } from '@material-ui/pickers';
import { isPostalCode } from 'class-validator';
import { parseISO } from 'date-fns';
import { mapOutline, trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { AddressBase } from '../../api/address';
import {
  TripCreate,
  useCurrentDraftTrip,
  usePurposes,
  useTransportModes,
  useTrip,
  useTripCreate,
  useTripDelete,
  useTripUpdate,
} from '../../api/trip';
import { Vehicle, getVehicleName, useVehicle } from '../../api/vehicle';
import { useLocalCache } from '../../components/LocalCache';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import AddressInput from '../../components/forms/AddressInput';
import ItemInput from '../../components/forms/ItemInput';
import LoadableKvSelect from '../../components/forms/LoadableKvSelect';
import FormLayout from '../../components/layouts/FormLayout';
import { ToolbarButton } from '../../components/layouts/ToolbarLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import { APP_TRIP_MAP } from '../../urls';
import { useEditState } from '../../utils/state';
import AddressSelect from '../common/AddressSelect';
import VehicleSelect from '../common/VehicleSelect';

const Trip: React.FC = () => {
  const history = useHistory();
  const cache = useLocalCache();
  const { id } = useParams<{ id: string }>();
  const isCreatingTrip = !Boolean(id) || (id && id.startsWith('draft:'));

  const { data: purposes, status: purposeStatus } = usePurposes();
  const { data: transportModes, status: transportModeStatus } = useTransportModes();

  const [create, resCreate] = useTripCreate({
    onSuccess: () => {
      if (id && id.startsWith('draft:')) {
        deleteDraftTrip(id);
      } else {
        history.goBack();
      }
    },
  });
  const [update, resUpdate] = useTripUpdate({ onSuccess: () => history.goBack() });
  const [deleteTrip, resDelete] = useTripDelete({ onSuccess: () => history.goBack() });
  const [deleteDraftTrip] = useTripDelete({ onSuccess: () => history.goBack() });
  const { data: currentDraftTrip } = useCurrentDraftTrip();
  const { data: trip, ...resQuery } = useTrip(id, {
    enabled: !resDelete.isLoading && !resDelete.isSuccess && Boolean(id),
    onSuccess: (trip_) => {
      setPurpose(trip_?.purpose?.id, true);
      setTransportMode(trip_?.transportMode?.id, true);
      setDistance(trip_?.distance, true);
      setPartySize(trip_?.partySize, true);
      setStartAddress(trip_?.startAddress, true);
      setStartCity(trip_?.startCity, true);
      setStartState(trip_?.startState, true);
      setStartZipCode(trip_?.startZipCode, true);
      setStartAt(trip_?.startAt ? parseISO(trip_?.startAt) : undefined, true);
      setEndAddress(trip_?.endAddress, true);
      setEndCity(trip_?.endCity, true);
      setEndState(trip_?.endState, true);
      setEndZipCode(trip_?.endZipCode, true);
      setEndAt(trip_?.endAt ? parseISO(trip_?.endAt) : undefined, true);
      if (!isHouseholdVehicleEdited) {
        setIsHouseholdVehicle(trip_?.isHouseholdVehicle);
        if (trip_?.isHouseholdVehicle && trip_.householdVehicleId) {
          setHouseholdVehicleId(trip_.householdVehicleId);
        }
      }
    },
  });

  const [isStartAddressSelectVisible, setIsStartAddressSelectVisible] = useState(false);
  const [isEndAddressSelectVisible, setIsEndAddressSelectVisible] = useState(false);
  const [isStartAtPickerVisible, setIsStartAtPickerVisible] = useState(false);
  const [isEndAtPickerVisible, setIsEndAtPickerVisible] = useState(false);
  const [isVehicleSelectVisible, setIsVehicleSelectVisible] = useState<boolean>(false);
  const [isTripDelDialogVisible, setIsTripDelDialogVisible] = useState(false);

  const [purpose, setPurpose] = useEditState(trip?.purpose?.id);
  const [transportMode, setTransportMode] = useEditState<number | undefined>(() => {
    if (!isCreatingTrip) {
      return trip?.transportMode?.id;
    }
    return cache.get(['autofill', 'trip-transport-mode']) ?? undefined;
  });
  const [distance, setDistance] = useEditState(trip?.distance);
  const [partySize, setPartySize] = useEditState<number | undefined>(trip?.partySize ?? 1);
  const [startAddress, setStartAddress] = useEditState(trip?.startAddress);
  const [startCity, setStartCity] = useEditState(trip?.startCity);
  const [startState, setStartState] = useEditState(trip?.startState);
  const [startZipCode, setStartZipCode] = useEditState(trip?.startZipCode);
  const [startAt, setStartAt] = useEditState(trip?.startAt ? parseISO(trip?.startAt) : undefined);
  const [endAddress, setEndAddress] = useEditState(trip?.endAddress);
  const [endCity, setEndCity] = useEditState(trip?.endCity);
  const [endState, setEndState] = useEditState(trip?.endState);
  const [endZipCode, setEndZipCode] = useEditState(trip?.endZipCode);
  const [endAt, setEndAt] = useEditState(trip?.endAt ? parseISO(trip?.endAt) : undefined);
  const [
    isHouseholdVehicle,
    setIsHouseholdVehicle,
    { edited: isHouseholdVehicleEdited },
  ] = useEditState<boolean | null | undefined>(() => {
    if (!isCreatingTrip) {
      return trip?.isHouseholdVehicle;
    }
    return cache.get(['autofill', 'trip-is-household-vehicle']) ?? undefined;
  });
  const [householdVehicleId, setHouseholdVehicleId] = useEditState<string | null | undefined>(
    () => {
      if (!isCreatingTrip) {
        return trip?.householdVehicleId;
      }
      return cache.get(['autofill', 'trip-household-vehicle-id']) ?? undefined;
    },
  );

  const { data: householdVehicle } = useVehicle(householdVehicleId ?? '', {
    enabled: isHouseholdVehicle && Boolean(householdVehicleId),
  });

  const onPurposeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setPurpose(e.detail.value!);
  };

  const onTransportModeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setTransportMode(e.detail.value!);
  };

  const onVehicleChange = (vehicle?: Vehicle) => {
    if (!vehicle) {
      setIsHouseholdVehicle(false);
      setHouseholdVehicleId(undefined);
    } else {
      setIsHouseholdVehicle(true);
      setHouseholdVehicleId(vehicle.id);
    }
  };

  const onDistanceChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setDistance(value);
    } else {
      setDistance(undefined);
    }
  };

  const onPartySizeChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setPartySize(value);
    } else {
      setPartySize(undefined);
    }
  };

  const onStartAddressChange = (address?: AddressBase) => {
    setStartAddress(address?.address);
    setStartCity(address?.city);
    setStartState(address?.state);
    setStartZipCode(address?.zipCode);
  };

  const onStartAtChange = (date: Date | null) => {
    setStartAt(date === null ? undefined : date);
  };

  const onStartAtPickerClose = () => {
    setIsStartAtPickerVisible(false);
  };

  const onEndAddressChange = (address?: AddressBase) => {
    setEndAddress(address?.address);
    setEndCity(address?.city);
    setEndState(address?.state);
    setEndZipCode(address?.zipCode);
  };

  const onEndAtChange = (date: Date | null) => {
    setEndAt(date === null ? undefined : date);
  };

  const onEndAtPickerClose = () => {
    setIsEndAtPickerVisible(false);
  };

  const onSubmit = () => {
    const updates = {
      purpose,
      transportMode,
      isHouseholdVehicle,
      householdVehicle: householdVehicleId,
      distance,
      partySize,
      startAddress,
      startCity,
      startState,
      startZipCode,
      startAt: new Date(startAt!).toISOString(),
      endAddress,
      endCity,
      endState,
      endZipCode,
      endAt: new Date(endAt!).toISOString(),
    };
    if (isCreatingTrip) {
      create({ ...updates, coordinates: trip?.coordinates } as TripCreate);
    } else if (trip) {
      update({ id, ...updates });
    }
    cache.set(['autofill', 'trip-transport-mode'], transportMode);
    cache.set(['autofill', 'trip-is-household-vehicle'], isHouseholdVehicle);
    cache.set(['autofill', 'trip-household-vehicle-id'], householdVehicleId);
  };

  const onDeleteTripClick = (e: React.MouseEvent) => {
    if (id) {
      deleteTrip(id);
    }
  };

  const onTripDelDialogDismiss = () => {
    setIsTripDelDialogVisible(false);
  };

  const isLoading =
    (resQuery.isInitialData && resQuery.isFetching) ||
    resCreate.isLoading ||
    resUpdate.isLoading ||
    resDelete.isLoading;
  const isStartZipCodeValid = isPostalCode(startZipCode!, 'US');
  const isEndZipCodeValid = isPostalCode(endZipCode!, 'US');
  const isFormValid = Boolean(
    typeof purpose === 'number' &&
      typeof transportMode === 'number' &&
      typeof distance === 'number' &&
      typeof partySize === 'number' &&
      startAddress &&
      startCity &&
      startState &&
      startZipCode &&
      isStartZipCodeValid &&
      startAt &&
      endAddress &&
      endCity &&
      endState &&
      endZipCode &&
      isEndZipCodeValid &&
      endAt,
  );
  const canSubmit = !isLoading && !resDelete.isSuccess && isFormValid;
  const isTrackingDraft = 'draft:' + currentDraftTrip?.id === id;

  const vehicleName = (() => {
    if (isHouseholdVehicle) {
      return householdVehicle ? getVehicleName(householdVehicle) : 'Loading...';
    }
    return isHouseholdVehicle === false ? 'Not household vehicle' : undefined;
  })();

  const buttons: ToolbarButton[] = [];

  if (id) {
    if (trip?.coordinates?.length) {
      buttons.push({
        slot: 'end',
        color: 'light',
        icon: mapOutline,
        routerLink: APP_TRIP_MAP.replace(':id', id),
      });
    }

    if (!isTrackingDraft) {
      buttons.push({
        slot: 'end',
        color: 'light',
        icon: trashOutline,
        disabled: resDelete.isLoading || resDelete.isSuccess,
        onClick: () => setIsTripDelDialogVisible(true),
      });
    }
  }

  return (
    <IonPage>
      <FormLayout buttons={buttons} title="Trip Details" subtitle="Tell us more about your trip.">
        <IonList className="input-group">
          <LoadableKvSelect
            label="Trip Purpose"
            required
            value={purpose}
            entries={purposes}
            status={purposeStatus}
            onChange={onPurposeChange}
          />
          <LoadableKvSelect
            label="Transport Mode"
            required
            value={transportMode}
            entries={transportModes}
            status={transportModeStatus}
            onChange={onTransportModeChange}
          />
          <AddressInput
            required
            label="Start Address"
            address={{
              address: startAddress,
              city: startCity,
              state: startState,
              zipCode: startZipCode,
            }}
            onClick={() => setIsStartAddressSelectVisible(true)}
          />
          <ItemInput
            label="Start At"
            required
            readonly
            value={startAt?.toLocaleString()}
            onFocus={() => setIsStartAtPickerVisible(true)}
          />
          <AddressInput
            required
            label="Destination Address"
            address={{
              address: endAddress,
              city: endCity,
              state: endState,
              zipCode: endZipCode,
            }}
            onClick={() => setIsEndAddressSelectVisible(true)}
          />
          <ItemInput
            label="End At"
            required
            readonly
            value={endAt?.toLocaleString()}
            onFocus={() => setIsEndAtPickerVisible(true)}
          />
          <ItemInput
            type="number"
            required
            label="Estimated Distance (Miles)"
            value={distance}
            onChange={onDistanceChange}
          />
          <ItemInput
            type="number"
            required
            label="Party Size"
            value={partySize}
            onChange={onPartySizeChange}
          />
          <ItemInput
            label="Vehicle Taken"
            value={vehicleName}
            readonly
            onFocus={() => setIsVehicleSelectVisible(true)}
          />
        </IonList>
      </FormLayout>
      <ButtonSheet>
        <IonButton
          shape="round"
          expand="block"
          onClick={onSubmit}
          disabled={!canSubmit || isTrackingDraft}>
          {isLoading ? 'Loading...' : 'Save'}
        </IonButton>
      </ButtonSheet>
      <AddressSelect
        value={{
          address: startAddress,
          city: startCity,
          state: startState,
          zipCode: startZipCode,
        }}
        onSelect={onStartAddressChange}
        isOpen={isStartAddressSelectVisible}
        onDismiss={() => setIsStartAddressSelectVisible(false)}
      />
      <AddressSelect
        value={{
          address: endAddress,
          city: endCity,
          state: endState,
          zipCode: endZipCode,
        }}
        onSelect={onEndAddressChange}
        isOpen={isEndAddressSelectVisible}
        onDismiss={() => setIsEndAddressSelectVisible(false)}
      />
      <VehicleSelect
        optional
        title="Vehicle"
        onSelect={onVehicleChange}
        isOpen={isVehicleSelectVisible}
        onDismiss={() => setIsVehicleSelectVisible(false)}
      />
      <MobileDateTimePicker<Date>
        clearable
        value={startAt}
        maxDate={endAt}
        onChange={() => {}}
        renderInput={() => <></>}
        onAccept={onStartAtChange}
        open={isStartAtPickerVisible}
        onClose={onStartAtPickerClose}
        DialogProps={{ disableRestoreFocus: true }}
      />
      <MobileDateTimePicker<Date>
        clearable
        value={endAt}
        minDate={startAt}
        onChange={() => {}}
        renderInput={() => <></>}
        onAccept={onEndAtChange}
        open={isEndAtPickerVisible}
        onClose={onEndAtPickerClose}
        DialogProps={{ disableRestoreFocus: true }}
      />
      <IonAlert
        isOpen={isTripDelDialogVisible}
        onDidDismiss={onTripDelDialogDismiss}
        header="Delete trip?"
        message={`Do you want to delete this trip?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: onTripDelDialogDismiss,
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: onDeleteTripClick,
          },
        ]}
      />
      <QueryResultToast result={isCreatingTrip ? resCreate : resUpdate}>
        Trip details saved.
      </QueryResultToast>
      <QueryResultToast result={resDelete}>Trip has been removed.</QueryResultToast>
    </IonPage>
  );
};

export default Trip;
