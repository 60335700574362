import { ColDef, RowParams, RowsProp } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from 'react-router';

import { FilterOptions, usePagedList } from '../../api/common';
import { Trip, usePurposes, useTransportModes } from '../../api/trip';
import DataGrid from '../../components/datagrid/DataGrid';
import {
  ContainsOperator,
  DateTimeRangeOperators,
  setColumnLookupValueFilter,
} from '../../components/datagrid/Operators';
import useFilter from '../../components/datagrid/useFilter';
import usePagination from '../../components/datagrid/usePagination';
import useSorting from '../../components/datagrid/useSorting';
import { API_TRIPS, DASHBOARD_TRIP } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const columns: ColDef[] = [
  {
    field: 'startAt',
    headerName: 'Start At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('startAt') as string),
  },
  {
    field: 'endAt',
    headerName: 'End At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('endAt') as string),
  },
  {
    field: 'purpose',
    headerName: 'Purpose',
    width: 300,
    valueGetter: (params) => params.row.purpose.title,
  },
  {
    field: 'transportMode',
    headerName: 'Transport Mode',
    width: 300,
    valueGetter: (params) => params.row.transportMode.title,
  },
  {
    field: 'startAddress',
    headerName: 'Start Address',
    width: 300,
    filterOperators: ContainsOperator,
  },
  {
    field: 'startCity',
    headerName: 'Start City',
    width: 180,
    filterOperators: ContainsOperator,
  },
  {
    field: 'startState',
    headerName: 'Start State',
    width: 160,
    filterOperators: ContainsOperator,
  },
  {
    field: 'startZipCode',
    headerName: 'Start Postal Code',
    width: 200,
    filterOperators: ContainsOperator,
  },
  {
    field: 'endAddress',
    headerName: 'End Address',
    width: 300,
    filterOperators: ContainsOperator,
  },
  {
    field: 'endCity',
    headerName: 'End City',
    width: 180,
    filterOperators: ContainsOperator,
  },
  {
    field: 'endState',
    headerName: 'End State',
    width: 160,
    filterOperators: ContainsOperator,
  },
  {
    field: 'endZipCode',
    headerName: 'End Postal Code',
    width: 200,
    filterOperators: ContainsOperator,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('createdAt') as string),
  },
];

export interface TripTableProps {
  filter?: FilterOptions;
}

const TripTable: React.FC<TripTableProps> = ({ filter: extraFilter }) => {
  const history = useHistory();

  const [filter, filterProps] = useFilter();
  const [sorting, sortingPorps] = useSorting();
  const [pagination, paginationProps] = usePagination();

  const { data: purposes } = usePurposes();
  const { data: transportModes } = useTransportModes();
  const { data, totalItems, isLoading } = usePagedList<Trip>(
    API_TRIPS,
    { ...filter, ...extraFilter },
    sorting,
    pagination,
  );

  const onRowClick = (params: RowParams) => {
    history.push(DASHBOARD_TRIP.replace(':id', params.getValue('id') as string));
  };

  const rows: RowsProp = data?.items ?? [];
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'purpose'),
    purposes,
  );
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'transportMode'),
    transportModes,
  );

  return (
    <DataGrid
      {...paginationProps}
      {...filterProps}
      {...sortingPorps}
      rows={rows}
      columns={columns}
      loading={isLoading}
      rowCount={totalItems}
      onRowClick={onRowClick}
    />
  );
};

export default TripTable;
