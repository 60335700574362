import { Capacitor, Plugins } from '@capacitor/core';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonFab,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import { Fab, Typography, makeStyles } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NavigationOutlinedIcon from '@material-ui/icons/NavigationOutlined';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab';
import { parseISO } from 'date-fns';
import { settingsOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { isHouseholdEnrolled, useSelfHousehold } from '../api/household';
import { useIsSurveyExpired } from '../api/setting';
import { useAllTrips } from '../api/trip';
import { isUserDisabled, isUserVerified, isValidUser, useSelfUser } from '../api/user';
import '../capacitor/tracking';
import AccountDisabledCard from '../components/cards/AccountDisabledCard';
import RecruitmentStatusCard from '../components/cards/RecruitmentStatusCard';
import TripCard from '../components/cards/TripCard';
import VerifyEmailCard from '../components/cards/VerifyEmailCard';
import SurveyEndWarning from '../components/notification/SurveyEndWarning';
import { APP_SETTINGS, APP_TRIP, APP_TRIP_LIST, APP_TRIP_TRACKER } from '../urls';

const { Tracking } = Plugins;

const TRIP_LIMIT = 5;

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 'var(--ion-padding)',
  },
  logo: {
    width: 24,
    height: 24,
    marginRight: 'calc(var(--ion-margin) / 2)',
  },
  container: {
    '&::part(background)': {
      background:
        'url(/assets/images/app/fogg-coffee-break.svg) center calc(100% + 25px) / 400px no-repeat',
    },
  },
  listHeader: {
    paddingLeft: 'var(--ion-padding)',
  },
  fabTooltip: {
    ...theme.typography.body2,
    whiteSpace: 'nowrap',
    color: theme.palette.text.secondary,
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  fabIcon: {
    marginRight: theme.spacing(0.5),
  },
  addSign: {
    height: '1rem',
    fontSize: '2rem',
    lineHeight: '1rem',
    display: 'inline-block',
    verticalAlign: 'text-bottom',
  },
}));

const AddTripFab: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const [isTrackingPromptVisible, setIsTrackingPromptVisible] = useState(false);

  useEffect(() => {
    async function updateTrackingState() {
      if (Capacitor.isNative) {
        const status = await Tracking.isTracking();
        setIsTracking(status.value);
      }
    }

    updateTrackingState();
  });

  const onAddManuallyClick = () => {
    setOpen(false);
    history.push(APP_TRIP.replace(':id?', ''));
  };

  const onTrackTripClick = async () => {
    setOpen(false);
    try {
      await Tracking.startTracking();
      history.push(APP_TRIP_TRACKER);
    } catch (e) {
      setIsTrackingPromptVisible(true);
    }
  };

  if (isTracking && Capacitor.isNative) {
    return (
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <Fab color="primary" onClick={onTrackTripClick}>
          <NavigationOutlinedIcon />
        </Fab>
      </IonFab>
    );
  }

  return (
    <>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <SpeedDial
          open={open}
          direction="up"
          ariaLabel="Add trip"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          icon={<SpeedDialIcon />}>
          <SpeedDialAction
            icon={<EditOutlinedIcon />}
            tooltipOpen
            tooltipTitle="Add Manually"
            FabProps={{ size: 'medium' }}
            onClick={onAddManuallyClick}
            classes={{ staticTooltipLabel: classes.fabTooltip }}
          />
          {Capacitor.isNative ? (
            <SpeedDialAction
              icon={<NavigationOutlinedIcon />}
              tooltipOpen
              tooltipTitle="Track Trip"
              FabProps={{ size: 'medium' }}
              onClick={onTrackTripClick}
              classes={{ staticTooltipLabel: classes.fabTooltip }}
            />
          ) : null}
        </SpeedDial>
      </IonFab>
      <IonAlert
        isOpen={isTrackingPromptVisible}
        onDidDismiss={() => setIsTrackingPromptVisible(false)}
        header={"Can't start trip tracking"}
        message={
          'Please make sure the location service is enabled on your device and grant the location permission.'
        }
        buttons={['OK']}
      />
    </>
  );
};

const Home: React.FC = () => {
  const classes = useStyles();

  const { data: user } = useSelfUser();
  const { data: household } = useSelfHousehold({ enabled: isValidUser(user) });
  const isSurveyExpired = useIsSurveyExpired({ enabled: isValidUser(user) });
  const isEnrolled = isHouseholdEnrolled(household);
  const isTripsEnabled = isValidUser(user) && isEnrolled && !isSurveyExpired;
  const {
    data: trips,
    isStale: isTripsStale,
    isInitialData: isInitialTripsData,
    isFetching: isTripsFetching,
    refetch: refetchTrips,
  } = useAllTrips({ user: user?.id, limit: TRIP_LIMIT }, { enabled: isTripsEnabled });

  useIonViewWillEnter(() => {
    if (trips && isTripsStale && isTripsEnabled) {
      refetchTrips();
    }
  }, [trips, isTripsStale, isTripsEnabled, refetchTrips]);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonTitle>
            <div className={classes.title}>
              <img className={classes.logo} src="/assets/icons/logo.svg" alt="App logo" />
              <span>HTS@TN</span>
            </div>
          </IonTitle>
          <IonButtons slot="end">
            <SurveyEndWarning />
            <IonButton routerLink={APP_SETTINGS}>
              <IonIcon slot="icon-only" icon={settingsOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen className={classes.container}>
        {isUserDisabled(user) ? <AccountDisabledCard /> : null}
        {!isUserVerified(user) ? <VerifyEmailCard /> : null}
        {isValidUser(user) ? <RecruitmentStatusCard /> : null}
        {isTripsEnabled ? (
          <>
            <IonList>
              <IonListHeader className={classes.listHeader}>
                <IonLabel>Recent Trips</IonLabel>
                <IonButton size="small" routerLink={APP_TRIP_LIST}>
                  See All
                </IonButton>
              </IonListHeader>
              {trips?.length ? (
                trips
                  .slice(0, TRIP_LIMIT)
                  .map((trip) => (
                    <TripCard
                      key={trip.id}
                      date={parseISO(trip.endAt)}
                      link={APP_TRIP.replace(':id?', trip.id)}
                      draft={trip.id.startsWith('draft:')}
                      address={trip.endAddress}
                      purpose={trip.purpose?.title ?? 'Unknown activity'}
                    />
                  ))
              ) : (
                <IonCard>
                  <IonCardContent>
                    <Typography color="textPrimary" variant="h2">
                      {isInitialTripsData && isTripsFetching
                        ? 'Loading your recent trips...'
                        : 'No trip found'}
                    </Typography>
                    {!(isInitialTripsData && isTripsFetching) ? (
                      <Typography>
                        Use the bottom right <span className={classes.addSign}>+</span> button to
                        save new trips.
                      </Typography>
                    ) : null}
                  </IonCardContent>
                </IonCard>
              )}
            </IonList>
            <AddTripFab />
          </>
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default Home;
