import { ColDef, RowParams, RowsProp } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from 'react-router';

import { FilterOptions, usePagedList } from '../../api/common';
import { Vehicle, useColors, useFuels, useTypes } from '../../api/vehicle';
import DataGrid from '../../components/datagrid/DataGrid';
import {
  ContainsOperator,
  DateTimeRangeOperators,
  EqualsOperator,
  setColumnLookupValueFilter,
} from '../../components/datagrid/Operators';
import useFilter from '../../components/datagrid/useFilter';
import usePagination from '../../components/datagrid/usePagination';
import useSorting from '../../components/datagrid/useSorting';
import { API_VEHICLES, DASHBOARD_VEHICLE } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const columns: ColDef[] = [
  {
    field: 'year',
    headerName: 'Year',
    width: 120,
    filterOperators: EqualsOperator,
    valueGetter: (params) => (params.row.model.year === 0 ? 'Other' : params.row.model.year),
  },
  {
    field: 'make',
    headerName: 'Make',
    width: 160,
    filterOperators: ContainsOperator,
    valueGetter: (params) => params.row.model.make,
  },
  {
    field: 'model',
    headerName: 'Model',
    width: 160,
    filterOperators: ContainsOperator,
    valueGetter: (params) => params.row.model.model,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 250,
    valueGetter: (params) => params.row.type?.title ?? '',
  },
  {
    field: 'color',
    headerName: 'Color',
    width: 120,
    valueGetter: (params) => params.row.color?.title ?? '',
  },
  {
    field: 'fuel',
    headerName: 'Fuel',
    width: 160,
    valueGetter: (params) => params.row.fuel?.title ?? '',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('createdAt') as string),
  },
];

export interface VehicleTableProps {
  filter?: FilterOptions;
}

const VehicleTable: React.FC<VehicleTableProps> = ({ filter: extraFilter }) => {
  const history = useHistory();

  const [filter, filterProps] = useFilter();
  const [sorting, sortingPorps] = useSorting();
  const [pagination, paginationProps] = usePagination();

  const { data: types } = useTypes();
  const { data: colors } = useColors();
  const { data: fuels } = useFuels();
  const { data, totalItems, isLoading } = usePagedList<Vehicle>(
    API_VEHICLES,
    { ...filter, ...extraFilter },
    sorting,
    pagination,
  );

  const onRowClick = (params: RowParams) => {
    history.push(DASHBOARD_VEHICLE.replace(':id', params.getValue('id') as string));
  };

  const rows: RowsProp = data?.items ?? [];
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'type'),
    types,
  );
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'color'),
    colors,
  );
  setColumnLookupValueFilter(
    columns.find((column) => column.field === 'fuel'),
    fuels,
  );

  return (
    <DataGrid
      {...paginationProps}
      {...filterProps}
      {...sortingPorps}
      rows={rows}
      columns={columns}
      loading={isLoading}
      rowCount={totalItems}
      onRowClick={onRowClick}
    />
  );
};

export default VehicleTable;
