import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { isEmail } from 'class-validator';
import React, { ChangeEventHandler, useState } from 'react';
import { QueryStatus } from 'react-query';
import { useHistory } from 'react-router';

import { useSignIn, useSignOut, useToken } from '../../api/auth';
import { useSelfUser } from '../../api/user';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import { getErrorMessage } from '../../utils/error';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  container: {
    marginBottom: theme.spacing(6),
  },
  content: {
    padding: theme.spacing(3),
  },
  helperText: {
    marginBottom: theme.spacing(2),
  },
}));

const SignIn: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: token } = useToken();
  const [signOut] = useSignOut();
  const { data: user } = useSelfUser({ enabled: Boolean(token) });

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [signIn, { status, error, reset }] = useSignIn({
    onSuccess: () => history.replace('/'),
  });

  const onEmailChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setEmail(e.target.value!);
  };

  const onPasswordChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setPassword(e.target.value!);
  };

  const onSubmit = () => {
    reset();
    if (token && user?.role?.title !== 'admin') {
      signOut();
    }
    signIn({ email, password });
  };

  const isEmailValid = isEmail(email);
  const isLoading = status === QueryStatus.Loading;
  const canSubmit = !isLoading && !!email && isEmailValid && !!password;

  let helperText = '';
  let isHelperTextVisible = false;

  if (status === QueryStatus.Error) {
    isHelperTextVisible = true;
    const response = (error as AxiosError).response;
    if (response?.status === 401) {
      helperText = 'Failed to sign in. Please check if email and password are correct.';
    } else {
      helperText = getErrorMessage(error);
    }
  } else if (user && user.role?.title !== 'admin') {
    isHelperTextVisible = true;
    helperText = 'User permission denied. Please sign in an administrator account.';
  }

  return (
    <DashboardLayout>
      <Grid className={classes.root} container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8} md={6}>
          <Card className={classes.container}>
            <CardContent className={classes.content}>
              <Typography variant="h5" component="h2" gutterBottom>
                Sign In
              </Typography>
              <TextField
                required
                fullWidth
                label="Email"
                margin="normal"
                variant="outlined"
                onChange={onEmailChange}
              />
              <TextField
                required
                fullWidth
                type="password"
                margin="normal"
                label="Password"
                variant="outlined"
                onChange={onPasswordChange}
              />
              <FormControl fullWidth margin="normal">
                {isHelperTextVisible ? (
                  <FormHelperText error className={classes.helperText}>
                    {helperText}
                  </FormHelperText>
                ) : null}
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={!canSubmit}>
                  Sign In
                </Button>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default SignIn;
