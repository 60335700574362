import { Plugins } from '@capacitor/core';
import { formatISO, isAfter, isBefore, parseISO } from 'date-fns';
import {
  InfiniteQueryConfig,
  MutationConfig,
  QueryObserverConfig,
  QueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { Track } from '../capacitor/tracking';
import { useLocalCache } from '../components/LocalCache';
import { API_TRIP, API_TRIPS, API_TRIP_PURPOSES, API_TRIP_TRANSPORT_MODES } from '../urls';
import { useApi } from './base';
import { FilterOptions, LookupValue, Pagination, Timestamp, useLookupValues } from './common';

const { Tracking } = Plugins;

export interface TripCoordinate {
  id: number;
  time: string;
  lat: number;
  lng: number;
  alt: number;
  dir: number;
  spd: number;
  hac: number;
  vac: number;
  dac: number;
  sac: number;
}

export interface TripCoordinateCreate extends Omit<TripCoordinate, 'id'> {}

export interface Trip extends Timestamp {
  id: string;
  userId: string;
  purpose: LookupValue | null;
  transportMode: LookupValue | null;
  isHouseholdVehicle: boolean | null;
  householdVehicleId: string | null;
  distance: number;
  partySize: number;
  startAddress: string;
  startCity: string;
  startState: string;
  startZipCode: string;
  startAt: string;
  endAddress: string;
  endCity: string;
  endState: string;
  endZipCode: string;
  endAt: string;
  coordinates: TripCoordinate[];
}

export interface TripCreate {
  purpose: number | null;
  transportMode: number | null;
  isHouseholdVehicle?: boolean | null;
  householdVehicle?: string | null;
  distance: number;
  partySize: number;
  startAddress: string;
  startCity: string;
  startState: string;
  startZipCode: string;
  startAt: string;
  endAddress: string;
  endCity: string;
  endState: string;
  endZipCode: string;
  endAt: string;
  coordinates?: TripCoordinateCreate[];
}

export interface TripUpdate extends Partial<TripCreate> {
  id: string;
}

export function usePurposes() {
  return useLookupValues('trip-purposes', API_TRIP_PURPOSES);
}

export function useTransportModes() {
  return useLookupValues('trip-transport-modes', API_TRIP_TRANSPORT_MODES);
}

function trackToTrip(track: Track) {
  return {
    ...track,
    id: 'draft:' + track.id,
    purpose: null,
    transportMode: null,
    isHouseholdVehicle: null,
    householdVehicleId: null,
    partySize: 1,
    coordinates: track.coordinates?.map((coordinate) => ({
      ...coordinate,
      time: formatISO(new Date(coordinate.time)),
    })),
    createdAt: track.startAt,
    updatedAt: track.startAt,
  } as Trip;
}

export function useTrip(id: string, config?: QueryObserverConfig<Trip>) {
  const api = useApi();
  const store = useLocalCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['trip', id],
    async (key: string, id: string) => {
      if (id.startsWith('draft:')) {
        const { value } = await Tracking.get({ id: parseInt(id.substr(6)) });
        if (!value) {
          throw new Error('Failed to get trip ' + id);
        }
        return trackToTrip(value);
      } else {
        const { data } = await api.get<Trip>(API_TRIP(id));
        return data;
      }
    },
    {
      enabled: enabled ?? Boolean(id),
      initialData: initialData ?? (() => store.get(['trip', id])),
      onSuccess: (data) => {
        store.set(['trip', id], data);
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useTrips(filters?: FilterOptions, config?: InfiniteQueryConfig<Pagination<Trip>>) {
  const api = useApi();
  const store = useLocalCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useInfiniteQuery(
    ['trips', filters],
    async (key: string, filters?: FilterOptions, nextPage?: number) => {
      const { data } = await api.get<Pagination<Trip>>(API_TRIPS, {
        // sort by end time in descendent order
        params: { ...filters, orderBy: '-endAt', page: nextPage },
      });
      return data;
    },
    {
      enabled: enabled ?? Boolean(filters?.user),
      initialData: initialData ?? (() => store.get(['trips', filters])),
      onSuccess(data) {
        if (data && data.length === 1) {
          // only cache first page
          store.set(['trips', filters], data);
        }
        onSuccess?.(data);
      },
      getFetchMore(lastPage) {
        return lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : 0;
      },
      ...config_,
    },
  );
}

export function useCurrentDraftTrip() {
  return useQuery(['trip', 'draft', 'current'], async () => {
    const { value } = await Tracking.getCurrentTrack();
    return value;
  });
}

export function useDraftTrips(filters?: FilterOptions) {
  return useQuery(
    ['trips', 'draft', filters],
    async (_: string, __: string, filters?: FilterOptions) => {
      const { value } = await Tracking.list({
        startAfter: filters?.startAfter,
        endBefore: filters?.endBefore,
      });
      // Also exclude current trip that is been tracked
      const { value: currentTrack } = await Tracking.getCurrentTrack();
      return Array.isArray(value)
        ? value.filter((track) => currentTrack?.id !== track.id).map(trackToTrip)
        : null;
    },
  );
}

// TODO: refactor
export function useAllTrips(
  filters?: FilterOptions,
  config?: InfiniteQueryConfig<Pagination<Trip>>,
) {
  const resRemoteTrips = useTrips(filters, config);
  let trips = (Array.isArray(resRemoteTrips.data) ? resRemoteTrips.data : [])
    .map((page) => page.items)
    .flat();

  let startAfter: Date | undefined;
  for (const trip of trips) {
    const startTime = parseISO(trip.startAt);
    if (!startAfter || isBefore(startTime, startAfter)) {
      startAfter = startTime;
    }
  }

  if (!startAfter) {
    startAfter = filters?.startAfter ? parseISO(filters.startAfter) : undefined;
  } else if (filters?.startAfter && isBefore(parseISO(filters.startAfter), startAfter)) {
    startAfter = parseISO(filters.startAfter);
  }

  if (!filters?.startAfter && !resRemoteTrips.canFetchMore) {
    startAfter = undefined;
  }

  const resDraftTrips = useDraftTrips({
    // load drafts up to the startAfter time.
    // if there is no more remote trips to load, fetch all drafts
    startAfter: startAfter ? formatISO(startAfter) : undefined,
    endBefore: filters?.endBefore,
  });

  const tracks = Array.isArray(resDraftTrips.data) ? resDraftTrips.data : [];
  trips = trips
    .concat(tracks)
    .filter((trip) => trip.startAt && trip.endAt)
    .map((trip) => ({ ...trip, endAt: parseISO(trip.endAt) }))
    // sort by end time in descendent order
    .sort(({ endAt: a }, { endAt: b }) => (isBefore(a, b) ? 1 : isAfter(a, b) ? -1 : 0))
    .map((trip) => ({ ...trip, endAt: formatISO(trip.endAt) }));

  return {
    ...resRemoteTrips,
    data: trips,
    isFetching: resRemoteTrips.isFetching || resDraftTrips.isFetching,
    isStale: resRemoteTrips.isStale || resDraftTrips.isStale,
    refetch() {
      resRemoteTrips.refetch();
      resDraftTrips.refetch();
    },
  } as QueryResult<Trip[]>;
}

export function useTripCreate(config?: MutationConfig<Trip, unknown, TripCreate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (trip: TripCreate) => {
      const { data } = await api.post<Trip>(API_TRIPS, trip);
      return data;
    },
    {
      onSuccess: async (trip, variables) => {
        cache.setQueryData(['trip', trip.id], trip);
        store.set(['trip', trip.id], trip);
        cache.invalidateQueries(['trips']);
        store.remove(['trips']);
        onSuccess?.(trip, variables);
      },
      ...config_,
    },
  );
}

export function useTripUpdate(config?: MutationConfig<Trip, unknown, TripUpdate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (trip: TripUpdate) => {
      const { data } = await api.put<Trip>(API_TRIP(trip.id), trip);
      return data;
    },
    {
      onSuccess: async (trip, variable) => {
        cache.setQueryData(['trip', trip.id], trip);
        store.set(['trip', trip.id], trip);
        cache.invalidateQueries(['trips']);
        store.remove(['trips']);
        onSuccess?.(trip, variable);
      },
      ...config_,
    },
  );
}

export function useTripDelete(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      if (id.startsWith('draft:')) {
        await Tracking.remove({ id: parseInt(id.substr(6)) });
      } else {
        await api.delete(API_TRIP(id));
      }
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['trip', id]);
        store.remove(['trip', id]);
        cache.invalidateQueries(['trips']);
        store.remove(['trips']);
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}
