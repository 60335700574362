import { Plugins } from '@capacitor/core';
import { IonIcon, IonList, IonModal, IonToast } from '@ionic/react';
import { locationOutline, searchOutline } from 'ionicons/icons';
import React, { useState } from 'react';

import {
  AddressBase,
  UNKNOWN_ADDRESS,
  UNKNOWN_CITY,
  UNKNOWN_STATE,
  useAddresses,
  useReverseGeocoding,
} from '../../api/address';
import { useSelfUser } from '../../api/user';
import InfiniteScroll from '../../components/containers/InfiniteScroll';
import EnterAddressDialog from '../../components/forms/EnterAddressDialog';
import ItemText from '../../components/forms/ItemText';
import SearchAddressDialog from '../../components/forms/SearchAddressDialog';
import ModalLayout from '../../components/layouts/ModalLayout';
import { geocoderResultToAddress } from '../../utils/geolocation';

const { Geolocation } = Plugins;

interface AddressSelectProps {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  value?: Partial<AddressBase>;
  onDismiss?: () => void;
  onSelect?: (address?: AddressBase) => void;
}

const AddressSelect: React.FC<AddressSelectProps> = (props) => {
  const { isOpen, value, onSelect, onDismiss } = props;
  const {
    title = 'Addresses',
    subtitle = 'Select from following addresses or use a new one.',
  } = props;

  const { data: user } = useSelfUser();
  const { data, fetchMore, canFetchMore } = useAddresses(user?.id);
  const [geocode, resGeocode] = useReverseGeocoding();

  const [isLocatingFailed, setIsLocatingFailed] = useState(false);
  const [isSearchAddressDialogVisible, setIsSearchAddressDialogVisible] = useState(false);
  const [isEnterAddressDialogVisible, setIsEnterAddressDialogVisible] = useState(false);

  const onAddressEnter = (address: AddressBase) => {
    onSelect?.(address);
    onDismiss?.();
  };

  const onLocateClick = async () => {
    try {
      const location = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        maximumAge: 10,
      });
      const request = {
        location: { lat: location.coords.latitude, lng: location.coords.longitude },
      };
      const results = await geocode(request);
      if (results?.length) {
        const address = geocoderResultToAddress(results[0]);
        if (address.address) {
          onSelect?.(address);
          onDismiss?.();
        } else {
          setIsLocatingFailed(true);
        }
      } else {
        setIsLocatingFailed(true);
      }
    } catch (e) {
      setIsLocatingFailed(true);
    }
  };

  const bucket = new Set();
  const addresses = (Array.isArray(data) ? data : [])
    .map((page) => page.items)
    .flat()
    .filter((address) => (bucket.has(address.id) ? false : (bucket.add(address.id), true)));

  const isLocatingFailed_ = isLocatingFailed || resGeocode.isError;

  return (
    <IonModal isOpen={isOpen}>
      <ModalLayout title={title} subtitle={subtitle} onDismiss={onDismiss}>
        <IonList>
          <ItemText
            key="new"
            title="Search addresses"
            onClick={() => setIsSearchAddressDialogVisible(true)}>
            <IonIcon icon={searchOutline} slot="start" />
          </ItemText>
          <ItemText
            key="locate"
            color={resGeocode.isLoading ? 'medium' : undefined}
            title={resGeocode.isLoading ? 'Locating...' : 'Use current location'}
            onClick={!resGeocode.isLoading ? onLocateClick : undefined}>
            <IonIcon
              slot="start"
              icon={locationOutline}
              color={resGeocode.isLoading ? 'medium' : undefined}
            />
          </ItemText>
          {addresses.map((address) => {
            const { id, name, address: addr, city, state } = address;

            return (
              <ItemText
                key={id}
                title={name ? name : addr ? addr : UNKNOWN_ADDRESS}
                subtitle={`${city ? city : UNKNOWN_CITY}, ${state ? state : UNKNOWN_STATE}`}
                onClick={() => {
                  onDismiss?.();
                  onSelect?.(address);
                }}
              />
            );
          })}
        </IonList>
        <InfiniteScroll
          disabled={!canFetchMore}
          onIonInfinite={async (e: any) => {
            await fetchMore();
            e.target.complete();
          }}
        />
      </ModalLayout>
      <SearchAddressDialog
        isOpen={isSearchAddressDialogVisible}
        onSuccess={onAddressEnter}
        onEnterManuallyClick={() => setIsEnterAddressDialogVisible(true)}
        onDismiss={() => setIsSearchAddressDialogVisible(false)}
      />
      <EnterAddressDialog
        value={value}
        isOpen={isEnterAddressDialogVisible}
        onChange={onAddressEnter}
        onDismiss={() => setIsEnterAddressDialogVisible(false)}
      />
      <IonToast
        duration={2000}
        color="danger"
        message={
          'Failed to get current location. ' +
          'Make sure your network is connected and permissions are granted.'
        }
        isOpen={isLocatingFailed_}
        onDidDismiss={() => {
          setIsLocatingFailed(false);
          resGeocode.reset();
        }}
      />
    </IonModal>
  );
};

export default AddressSelect;
