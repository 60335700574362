import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import React from 'react';

type Button = Parameters<typeof IonButton>[0] & { icon?: string };

export interface IconButtonGroupProps {
  slot?: string;
  buttons?: Button[];
}

const IconButtonGroup: React.FC<IconButtonGroupProps> = ({ slot, buttons }) => {
  return (
    <IonButtons slot={slot}>
      {buttons?.map(({ icon, slot, color, ...button }, i) => (
        <IonButton key={i} {...button}>
          <IonIcon slot="icon-only" icon={icon} />
        </IonButton>
      ))}
    </IonButtons>
  );
};

export default IconButtonGroup;
