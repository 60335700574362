import { Plugins } from '@capacitor/core';
import { CheckboxChangeEventDetail, InputChangeEventDetail } from '@ionic/core';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterLink,
} from '@ionic/react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { isEmail } from 'class-validator';
import { barcodeOutline, lockClosedOutline, mailOutline } from 'ionicons/icons';
import React, { MouseEventHandler, useState } from 'react';
import { QueryStatus } from 'react-query';

import { useSignUp } from '../../api/auth';
import {
  ConfigurableUrls,
  SETTING_CONFIGURABLE_URLS,
  useIsRegistrationEnabled,
  useSettings,
} from '../../api/setting';
import HelperText from '../../components/forms/HelperText';
import IonCheckbox from '../../components/forms/patch/IonCheckbox';
import IonInput from '../../components/forms/patch/IonInput';
import QueryResultToast from '../../components/notification/QueryResultToast';
import Subtitle from '../../components/typography/Subtitle';
import Title from '../../components/typography/Title';
import { APP_SIGNIN } from '../../urls';
import isPassword from '../../utils/password';
import PolicyPopup from '../common/PolicyPopup';

const { App } = Plugins;

const useStyles = makeStyles({
  container: {
    '&::part(background)': {
      background:
        'url(/assets/images/app/fogg-parachute-and-plane.svg) center calc(100% + 25px) / 400px no-repeat',
    },
  },
  caption: {
    fontSize: '0.75rem !important',
  },
});

const SignUp: React.FC = () => {
  const classes = useStyles();
  const isRegistrationEnabled = useIsRegistrationEnabled();
  const { data: settings } = useSettings();

  const urls: ConfigurableUrls | undefined = settings?.[SETTING_CONFIGURABLE_URLS];
  const irbConsentUrl = urls?.['irb_consent_form_url'] || undefined;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referrer, setReferrer] = useState<string | null>(null);
  const [consent, setConsent] = useState(false);
  const [signUp, { status, error, reset }] = useSignUp();

  const [isTermsOfServiceVisible, setIsTermsOfServiceVisible] = useState(false);
  const [isAcceptableUsePolicyVisible, setIsAcceptableUsePolicyVisible] = useState(false);
  const [isPrivacyPolicyVisible, setIsPrivacyPolicyVisible] = useState(false);

  const onEmailChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setEmail(e.detail.value!);
  };

  const onPasswordChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setPassword(e.detail.value!);
  };

  const onReferrerChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setReferrer(e.detail.value ?? null);
  };

  const onConsentChange = (e: CustomEvent<CheckboxChangeEventDetail>) => {
    setConsent(e.detail.checked!);
  };

  const onIrbConsentFormClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    if (typeof irbConsentUrl === 'string') {
      App.openUrl({ url: irbConsentUrl });
    }
  };

  const onTermsOfServiceClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsTermsOfServiceVisible(true);
  };

  const onAcceptableUsePolicyClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsAcceptableUsePolicyVisible(true);
  };

  const onPrivacyPolicyClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsPrivacyPolicyVisible(true);
  };

  const onSubmit = () => signUp({ email, password, referrer });

  const isEmailValid = isEmail(email);
  const hasValidEmail = !!email && isEmailValid;
  const isPasswordValid = isPassword(password);
  const hasValidPassword = !!password && isPasswordValid;
  const isLoading = status === QueryStatus.Loading;
  const canSubmit = !isLoading && hasValidEmail && hasValidPassword && consent;

  return (
    <IonPage>
      <IonContent fullscreen className={['display', classes.container].join(' ')}>
        <Title>{isRegistrationEnabled ? "Let's Get Started!" : 'Registration Closed'}</Title>
        <Subtitle>
          {isRegistrationEnabled
            ? 'Sign up with your email to continue.'
            : 'Public registration is closed at this time.'}
        </Subtitle>
        {isRegistrationEnabled ? (
          <>
            <IonList>
              <IonItem className="ion-margin-top">
                <IonIcon icon={mailOutline} slot="start" />
                <IonInput
                  type="email"
                  placeholder="Email"
                  value={email}
                  onIonChange={onEmailChange}
                />
              </IonItem>
              {email && !isEmailValid ? (
                <HelperText color="error">Email address is invalid</HelperText>
              ) : null}
              <IonItem className="ion-margin-top">
                <IonIcon icon={lockClosedOutline} slot="start" />
                <IonInput
                  type="password"
                  placeholder="Password"
                  value={password}
                  onIonChange={onPasswordChange}
                />
              </IonItem>
              {password && !isPasswordValid ? (
                <HelperText color="error">
                  At least 10 characters, 1 uppercase letter, 1 number, 1 symbol
                </HelperText>
              ) : null}
              <IonItem className="ion-margin-top">
                <IonIcon icon={barcodeOutline} slot="start" />
                <IonInput
                  type="text"
                  placeholder="Referral code (optional)"
                  value={referrer}
                  onIonChange={onReferrerChange}
                />
              </IonItem>
              <IonItem lines="none" className="item-checkbox ion-margin-top">
                <IonCheckbox slot="start" checked={consent} onIonChange={onConsentChange} />
                <IonLabel onClick={() => setConsent(!consent)}>
                  <Typography variant="body2">
                    By signing up, you have read and agree to the{' '}
                    <button className="is-link" onClick={onIrbConsentFormClick}>
                      IRB Consent Form
                    </button>
                    {', '}
                    <button className="is-link" onClick={onTermsOfServiceClick}>
                      Terms of Service
                    </button>
                    {', '}
                    <button className="is-link" onClick={onAcceptableUsePolicyClick}>
                      Acceptable Use Policy
                    </button>
                    {', and '}
                    <button className="is-link" onClick={onPrivacyPolicyClick}>
                      Privacy Policy
                    </button>
                    {'.'}
                  </Typography>
                  <Typography component="p" variant="caption" className={classes.caption}>
                    {'The Institutional Review Board of the University of Tennessee at Chattanooga ' +
                      '(FWA00004149) has approved this research project # 21-139.'}
                  </Typography>
                </IonLabel>
              </IonItem>
            </IonList>
            <IonButton
              shape="round"
              expand="block"
              onClick={onSubmit}
              disabled={!canSubmit}
              className="ion-no-margin ion-margin-top ion-margin-bottom">
              {isLoading ? 'Loading...' : 'Sign Up'}
            </IonButton>
          </>
        ) : (
          <Box py={2}>{/* spacer */}</Box>
        )}
        <Typography variant="body2" align="center">
          {'Already have an account? '}
          <IonRouterLink routerLink={APP_SIGNIN}>Sign in</IonRouterLink>.
        </Typography>
      </IonContent>
      <PolicyPopup
        name="terms-of-service"
        title="Terms of Service"
        isOpen={isTermsOfServiceVisible}
        onDismiss={() => setIsTermsOfServiceVisible(false)}
      />
      <PolicyPopup
        name="acceptable-use-policy"
        title="Acceptable Use Policy"
        isOpen={isAcceptableUsePolicyVisible}
        onDismiss={() => setIsAcceptableUsePolicyVisible(false)}
      />
      <PolicyPopup
        name="privacy-policy"
        title="Privacy Policy"
        isOpen={isPrivacyPolicyVisible}
        onDismiss={() => setIsPrivacyPolicyVisible(false)}
      />
      <QueryResultToast result={{ status, error, reset }}>
        Please check your email for the verification link.
      </QueryResultToast>
    </IonPage>
  );
};

export default SignUp;
