import { AxiosError } from 'axios';

export function getErrorMessage(error: any) {
  const response = (error as AxiosError).response;
  if (response?.data?.message) {
    return response.data.message;
  } else {
    return (error as Error).message;
  }
}
