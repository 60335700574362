import { IonItem, IonLabel, IonTextarea } from '@ionic/react';
import React from 'react';

import { AddressBase } from '../../api/address';

export interface AddressInputProps {
  label?: string;
  required?: boolean;
  readonly?: boolean;
  address?: Partial<AddressBase>;
  onClick?: (event: React.MouseEvent<HTMLIonTextareaElement>) => void;
  onFocus?: (event: CustomEvent<FocusEvent>) => void;
}

const AddressInput: React.FC<AddressInputProps> = (props) => {
  const { required, readonly, label, address, onClick, onFocus } = props;
  const formatted = [
    address?.address,
    [address?.city, address?.state, address?.zipCode].filter(Boolean).join(', '),
  ]
    .filter(Boolean)
    .join('\n');

  return (
    <IonItem className={readonly ? 'readonly' : ''}>
      <IonLabel position="floating">
        {label}
        {required ? <em className="required">*</em> : null}
      </IonLabel>
      <IonTextarea
        readonly
        value={formatted}
        onClick={onClick}
        onIonFocus={onFocus}
        placeholder="Enter address here"
      />
    </IonItem>
  );
};

export default AddressInput;
