export function capitalize(str?: string): string {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function pad(n: number, width: number, z: string = '0') {
  const x = n + '';
  return x.length >= width ? x : new Array(width - x.length + 1).join(z) + x;
}
