import {
  InfiniteQueryConfig,
  MutationConfig,
  QueryObserverConfig,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import {
  API_VEHICLE,
  API_VEHICLES,
  API_VEHICLE_COLORS,
  API_VEHICLE_FUELS,
  API_VEHICLE_MODELS,
  API_VEHICLE_TYPES,
} from '../urls';
import { useApi } from './base';
import { FilterOptions, LookupValue, Pagination, Timestamp, useLookupValues } from './common';

export const START_YEAR = 1992;

export interface VehicleModel {
  id: number;
  year: number;
  make: string;
  model: string;
}

export interface Vehicle extends Timestamp {
  id: string;
  householdId: string;
  model: VehicleModel | null;
  type: LookupValue | null;
  color: LookupValue | null;
  fuel: LookupValue | null;
  boughtUsed: boolean | null;
  isHouseholdDriver: boolean | null;
  householdDriverId: string | null;
  possessionTime: number | null;
  odometerReading: number | null;
  annualMileage: number | null;
}

export interface VehicleCreate {
  model?: number | null;
  type?: number | null;
  color?: number | null;
  fuel?: number | null;
  boughtUsed?: boolean | null;
  isHouseholdDriver?: boolean | null;
  householdDriver?: string | null;
  possessionTime?: number | null;
  odometerReading?: number | null;
  annualMileage?: number | null;
}

export interface VehicleUpdate extends Partial<VehicleCreate> {
  id: string;
}

export function getVehicleYear(vehicle?: Vehicle) {
  const { year } = vehicle?.model || {};
  return typeof year === 'undefined' || year === 0 ? 'Other' : `${year}`;
}

export function getVehicleMakeModel(vehicle?: Vehicle) {
  const { year, make, model } = vehicle?.model || {};
  return typeof year === 'undefined' || year === 0 ? 'Other' : `${make} ${model}`;
}

export function getVehicleName(vehicle?: Vehicle) {
  const year = getVehicleYear(vehicle);
  if (year === 'Other') {
    return year;
  }
  return `${year} ${getVehicleMakeModel(vehicle)}`;
}

export function useModels(filter: FilterOptions) {
  const api = useApi();

  return useQuery(
    ['vehicle-models', filter],
    async (key: string, filter: FilterOptions) => {
      const { data } = await api.get<VehicleModel[]>(API_VEHICLE_MODELS, { params: filter });
      return Array.isArray(data) ? data : [];
    },
    { enabled: typeof filter.year === 'number', staleTime: 5 * 60 * 1000 },
  );
}

export function useTypes() {
  return useLookupValues('vehicle-types', API_VEHICLE_TYPES);
}

export function useColors() {
  return useLookupValues('vehicle-colors', API_VEHICLE_COLORS);
}

export function useFuels() {
  return useLookupValues('vehicle-fuels', API_VEHICLE_FUELS);
}

export function useVehicle(id: string, config?: QueryObserverConfig<Vehicle>) {
  const api = useApi();
  const store = useLocalCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['vehicle', id],
    async (key: string, id: string) => {
      const { data } = await api.get<Vehicle>(API_VEHICLE(id));
      return data;
    },
    {
      enabled: enabled ?? Boolean(id),
      initialData: initialData ?? (() => store.get(['vehicle', id])),
      onSuccess: (data) => {
        store.set(['vehicle', id], data);
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useVehicles(household?: string, config?: InfiniteQueryConfig<Pagination<Vehicle>>) {
  const api = useApi();
  const store = useLocalCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useInfiniteQuery(
    ['vehicles', { household }],
    async (key: string, filters?: FilterOptions, nextPage?: number) => {
      const { data } = await api.get<Pagination<Vehicle>>(API_VEHICLES, {
        params: { ...filters, page: nextPage },
      });
      return data;
    },
    {
      enabled: enabled ?? Boolean(household),
      initialData: initialData ?? (() => store.get(['vehicles', { household }])),
      onSuccess(data) {
        if (data && data.length === 1) {
          // only cache first page
          store.set(['vehicles', { household }], data);
        }
        onSuccess?.(data);
      },
      getFetchMore(lastPage) {
        return lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : 0;
      },
      ...config_,
    },
  );
}

export function useVehicleCreate(config?: MutationConfig<Vehicle, unknown, VehicleCreate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (vehicle: VehicleCreate) => {
      const { data } = await api.post<Vehicle>(API_VEHICLES, vehicle);
      return data;
    },
    {
      onSuccess: async (vehicle, variables) => {
        cache.setQueryData(['vehicle', vehicle.id], vehicle);
        store.set(['vehicle', vehicle.id], vehicle);
        cache.invalidateQueries(['vehicles']);
        store.remove(['vehicles']);
        onSuccess?.(vehicle, variables);
      },
      ...config_,
    },
  );
}

export function useVehicleUpdate(config?: MutationConfig<Vehicle, unknown, VehicleUpdate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (vehicle: VehicleUpdate) => {
      const { data } = await api.put<Vehicle>(API_VEHICLE(vehicle.id), vehicle);
      return data;
    },
    {
      onSuccess: async (vehicle, variable) => {
        cache.setQueryData(['vehicle', vehicle.id], vehicle);
        store.set(['vehicle', vehicle.id], vehicle);
        cache.invalidateQueries(['vehicles']);
        store.remove(['vehicles']);
        onSuccess?.(vehicle, variable);
      },
      ...config_,
    },
  );
}

export function useVehicleDelete(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      await api.delete(API_VEHICLE(id));
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['vehicle', id]);
        store.remove(['vehicle', id]);
        cache.invalidateQueries(['vehicles']);
        store.remove(['vehicles']);
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}
