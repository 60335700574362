import { Box } from '@material-ui/core';
import React from 'react';

export interface DashItemTabPanelProps {
  forTab: string;
  currentTab?: string;
}

const DashItemTabPanel: React.FC<DashItemTabPanelProps> = ({ forTab, currentTab, children }) => {
  return <Box hidden={forTab !== currentTab}>{forTab === currentTab ? children : null}</Box>;
};

export default DashItemTabPanel;
