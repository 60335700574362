import {
  MutationConfig,
  QueryObserverConfig,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import {
  API_HOUSEHOLD,
  API_HOUSEHOLDS,
  API_HOUSEHOLD_HOUSING_TYPES,
  API_HOUSEHOLD_INCOME_LEVELS,
  API_HOUSEHOLD_OWNERSHIP_TYPES,
  API_HOUSEHOLD_STATUS_CHANGE,
} from '../urls';
import { useApi } from './base';
import { LookupValue, Timestamp, useLookupValues } from './common';

export interface Household extends Timestamp {
  id: string;
  ownerId: string | null;
  enrollmentStatus: LookupValue | null;
  enrolledAt: string | null;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  housingType: LookupValue | null;
  housingOwnershipType: LookupValue | null;
  livingTime: number | null;
  incomeLevel: LookupValue | null;
}

export interface HouseholdCreate {
  address: string;
  city: string;
  state: string;
  zipCode: string;
  housingType?: number | null;
  housingOwnershipType?: number | null;
  livingTime?: number | null;
  incomeLevel?: number | null;
}

export interface HouseholdUpdate extends Partial<HouseholdCreate> {
  id: string;
}

export function isHouseholdReadOnly(household?: Household) {
  if (!household?.enrollmentStatus?.title) {
    return false;
  }
  return !['none', 'rejected'].includes(household?.enrollmentStatus?.title);
}

export function isHouseholdEnrolled(household?: Household) {
  return household?.enrollmentStatus?.title === 'accepted' && Boolean(household.enrolledAt);
}

export function useHousingTypes() {
  return useLookupValues('household-housing-types', API_HOUSEHOLD_HOUSING_TYPES);
}

export function useOwnershipTypes() {
  return useLookupValues('household-ownership-types', API_HOUSEHOLD_OWNERSHIP_TYPES);
}

export function useIncomeLevels() {
  return useLookupValues('household-income-levels', API_HOUSEHOLD_INCOME_LEVELS);
}

export function useHousehold(id: string, config?: QueryObserverConfig<Household>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['household', id],
    async (key: string, id: string) => {
      const { data } = await api.get<Household>(API_HOUSEHOLD(id));
      return data;
    },
    {
      enabled: enabled ?? Boolean(id),
      initialData: initialData ?? (() => store.get(['household', id])),
      onSuccess: (data) => {
        store.set(['household', id], data);
        if (data && data.id !== id) {
          cache.setQueryData(['household', data.id], data);
          store.set(['household', data.id], data);
        }
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useSelfHousehold(config?: QueryObserverConfig<Household>) {
  return useHousehold('self', config);
}

export function useHouseholdCreate(config?: MutationConfig<Household, unknown, HouseholdCreate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (household: HouseholdCreate) => {
      const { data } = await api.post<Household>(API_HOUSEHOLDS, household);
      return data;
    },
    {
      onSuccess: async (household, variables) => {
        cache.setQueryData(['household', household.id], household);
        store.set(['household', household.id], household);
        const selfHousehold = cache.getQueryData<Household>(['household', 'self']);
        if (!selfHousehold?.id) {
          cache.invalidateQueries(['household', 'self']);
          store.remove(['household', 'self']);
        }
        onSuccess?.(household, variables);
      },
      ...config_,
    },
  );
}

export function useHouseholdUpdate(config?: MutationConfig<Household, unknown, HouseholdUpdate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (household: HouseholdUpdate) => {
      const { data } = await api.put<Household>(API_HOUSEHOLD(household.id), household);
      return data;
    },
    {
      onSuccess: (household, variables) => {
        cache.setQueryData(['household', household.id], household);
        store.set(['household', household.id], household);
        const selfHousehold = cache.getQueryData<Household>(['household', 'self']);
        if (household?.id === selfHousehold?.id) {
          cache.setQueryData(['household', 'self'], household);
          store.set(['household', 'self'], household);
        }
        onSuccess?.(household, variables);
      },
      ...config_,
    },
  );
}

export function useHouseholdDelete(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      await api.delete(API_HOUSEHOLD(id));
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['household', id]);
        store.remove(['household', id]);
        const selfHousehold = cache.getQueryData<Household>(['household', 'self']);
        if (id === selfHousehold?.id) {
          cache.invalidateQueries(['household', 'self']);
          store.remove(['household', 'self']);
        }
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}

function useHouseholdStatusChange(status: string, config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const urlFactory = (id: string) => API_HOUSEHOLD_STATUS_CHANGE(id, status);
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      await api.post(urlFactory(id));
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['household', id]);
        store.remove(['household', id]);
        const selfHousehold = cache.getQueryData<Household>(['household', 'self']);
        if (id === selfHousehold?.id) {
          cache.invalidateQueries(['household', 'self']);
          store.remove(['household', 'self']);
        }
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}

export function useHouseholdSubmit(config?: MutationConfig<void, unknown, string>) {
  return useHouseholdStatusChange('submitted', config);
}

export function useHouseholdReview(config?: MutationConfig<void, unknown, string>) {
  return useHouseholdStatusChange('reviewing', config);
}

export function useHouseholdAccept(config?: MutationConfig<void, unknown, string>) {
  return useHouseholdStatusChange('accepted', config);
}

export function useHouseholdReject(config?: MutationConfig<void, unknown, string>) {
  return useHouseholdStatusChange('rejected', config);
}
