import { RangeChangeEventDetail, TextareaChangeEventDetail } from '@ionic/core';
import { IonButton, IonIcon, IonItem, IonItemDivider, IonList, IonPage } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import { happyOutline, sadOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import { useFeedbackCreate, useFeedbackUpdate, useSelfFeedback } from '../api/feedback';
import ButtonSheet from '../components/buttons/ButtonSheet';
import IonRange from '../components/forms/patch/IonRange';
import IonTextarea from '../components/forms/patch/IonTextarea';
import FormLayout from '../components/layouts/FormLayout';
import QueryResultToast from '../components/notification/QueryResultToast';
import { useEditState } from '../utils/state';

const useStyles = makeStyles({
  form: {
    '& ion-item-divider': {
      paddingInlineStart: 0,
    },
    '& ion-icon[slot="start"]': {
      marginRight: 'var(--ion-margin)',
    },
  },
});

const Feedback: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();

  const [create, resCreate] = useFeedbackCreate({ onSuccess: () => history.goBack() });
  const [update, resUpdate] = useFeedbackUpdate({ onSuccess: () => history.goBack() });

  const { data: feedback, ...resQuery } = useSelfFeedback({
    retry: false,
    onSuccess: (feedback_) => {
      setOverallRating(feedback_?.overallRating, true);
      setFunctionalityRating(feedback_?.functionalityRating, true);
      setUsabilityRating(feedback_?.usabilityRating, true);
      setStabilityRating(feedback_?.stabilityRating, true);
      setLookAndFeelRating(feedback_?.lookAndFeelRating, true);
      setUserComment(feedback_?.userComment, true);
    },
  });

  const isCreatingFeedback = !Boolean(feedback?.id);

  const [overallRating, setOverallRating] = useEditState(feedback?.overallRating);
  const [functionalityRating, setFunctionalityRating] = useEditState(feedback?.functionalityRating);
  const [usabilityRating, setUsabilityRating] = useEditState(feedback?.usabilityRating);
  const [stabilityRating, setStabilityRating] = useEditState(feedback?.stabilityRating);
  const [lookAndFeelRating, setLookAndFeelRating] = useEditState(feedback?.lookAndFeelRating);
  const [userComment, setUserComment] = useEditState(feedback?.userComment);

  const onOverallRatingChange = (e: CustomEvent<RangeChangeEventDetail>) => {
    const value = e.detail.value as number;
    setOverallRating(isNaN(value) ? undefined : value);
  };

  const onFunctionalityRatingChange = (e: CustomEvent<RangeChangeEventDetail>) => {
    const value = e.detail.value as number;
    setFunctionalityRating(isNaN(value) ? undefined : value);
  };

  const onUsabilityRatingChange = (e: CustomEvent<RangeChangeEventDetail>) => {
    const value = e.detail.value as number;
    setUsabilityRating(isNaN(value) ? undefined : value);
  };

  const onStabilityRatingChange = (e: CustomEvent<RangeChangeEventDetail>) => {
    const value = e.detail.value as number;
    setStabilityRating(isNaN(value) ? undefined : value);
  };

  const onLookAndFeelRatingChange = (e: CustomEvent<RangeChangeEventDetail>) => {
    const value = e.detail.value as number;
    setLookAndFeelRating(isNaN(value) ? undefined : value);
  };

  const onUserCommentChange = (e: CustomEvent<TextareaChangeEventDetail>) => {
    setUserComment(e.detail.value ? e.detail.value : null);
  };

  const onSubmit = () => {
    const updates = {
      overallRating: overallRating ?? 1,
      functionalityRating: functionalityRating ?? 1,
      usabilityRating: usabilityRating ?? 1,
      stabilityRating: stabilityRating ?? 1,
      lookAndFeelRating: lookAndFeelRating ?? 1,
      userComment: userComment ?? null,
    };
    if (isCreatingFeedback) {
      create(updates);
    } else if (feedback) {
      update({ id: feedback.id, ...updates });
    }
  };

  const isLoading =
    (resQuery.isInitialData && resQuery.isFetching) || resCreate.isLoading || resUpdate.isLoading;
  const canSubmit = !isLoading;

  return (
    <IonPage>
      <FormLayout title="Feedback" subtitle="Your comments help us improve.">
        <IonList className={classes.form}>
          <IonItemDivider>How is your overall experience?</IonItemDivider>
          <IonItem lines="none">
            <IonRange
              snaps
              min={1}
              max={5}
              step={1}
              value={overallRating}
              onIonChange={onOverallRatingChange}
            />
            <IonIcon slot="start" icon={sadOutline} />
            <IonIcon slot="end" icon={happyOutline} />
          </IonItem>
          <IonItemDivider>How is the functionality of our app?</IonItemDivider>
          <IonItem lines="none">
            <IonRange
              snaps
              min={1}
              max={5}
              step={1}
              value={functionalityRating}
              onIonChange={onFunctionalityRatingChange}
            />
            <IonIcon slot="start" icon={sadOutline} />
            <IonIcon slot="end" icon={happyOutline} />
          </IonItem>
          <IonItemDivider>How is the usability of our app?</IonItemDivider>
          <IonItem lines="none">
            <IonRange
              snaps
              min={1}
              max={5}
              step={1}
              value={usabilityRating}
              onIonChange={onUsabilityRatingChange}
            />
            <IonIcon slot="start" icon={sadOutline} />
            <IonIcon slot="end" icon={happyOutline} />
          </IonItem>
          <IonItemDivider>How is the stability of our app?</IonItemDivider>
          <IonItem lines="none">
            <IonRange
              snaps
              min={1}
              max={5}
              step={1}
              value={stabilityRating}
              onIonChange={onStabilityRatingChange}
            />
            <IonIcon slot="start" icon={sadOutline} />
            <IonIcon slot="end" icon={happyOutline} />
          </IonItem>
          <IonItemDivider>How is the look and feel of our app?</IonItemDivider>
          <IonItem lines="none">
            <IonRange
              snaps
              min={1}
              max={5}
              step={1}
              value={lookAndFeelRating}
              onIonChange={onLookAndFeelRatingChange}
            />
            <IonIcon slot="start" icon={sadOutline} />
            <IonIcon slot="end" icon={happyOutline} />
          </IonItem>
          <IonItemDivider>How can we improve our app?</IonItemDivider>
          <IonItem>
            <IonTextarea
              autoGrow
              value={userComment}
              onIonChange={onUserCommentChange}
              placeholder="Enter your comments here..."
            />
          </IonItem>
        </IonList>
      </FormLayout>
      <ButtonSheet>
        <IonButton shape="round" expand="block" onClick={onSubmit} disabled={!canSubmit}>
          {isLoading ? 'Loading...' : 'Save'}
        </IonButton>
      </ButtonSheet>
      <QueryResultToast result={isCreatingFeedback ? resCreate : resUpdate}>
        User feedback saved.
      </QueryResultToast>
    </IonPage>
  );
};

export default Feedback;
