import { addDays, isAfter, parseISO, setMilliseconds } from 'date-fns';
import {
  MutationConfig,
  QueryObserverConfig,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import { API_SETTINGS } from '../urls';
import { useApi } from './base';
import { useSelfHousehold } from './household';

const SETTING_STALE_TIME = 60 * 1000;
export const SETTING_SITE_CLOSED_DATE = 'site_closed_date';
export const SETTING_SURVEY_EXPIRATION_IN_DAYS = 'survey_expiration_in_days';
export const SETTING_REGISTRATION_ENABLED = 'registration_enabled';
export const SETTING_CONFIGURABLE_URLS = 'configurable_urls';
export const SETTING_CONTACT_INFO = 'contact_info';

export interface ContactInfo {
  name: string | null;
  email: string | null;
}

export interface ConfigurableUrls {
  [key: string]: string | null;
}

export function useIsRegistrationEnabled(config?: { enabled?: boolean }) {
  const { data: settings } = useSettings(config);
  return settings?.[SETTING_REGISTRATION_ENABLED] ?? true;
}

export function useSurveyExpirationDate(config?: { enabled?: boolean }) {
  const { data: settings } = useSettings(config);
  const { data: household } = useSelfHousehold({
    enabled:
      config?.enabled && typeof settings?.[SETTING_SURVEY_EXPIRATION_IN_DAYS] !== 'undefined',
  });
  if (
    typeof settings?.[SETTING_SURVEY_EXPIRATION_IN_DAYS] !== 'number' ||
    household?.enrollmentStatus?.title !== 'accepted' ||
    !household?.enrolledAt
  ) {
    return null;
  }
  try {
    const date = parseISO(household.enrolledAt);
    return addDays(setMilliseconds(date, 0), settings?.[SETTING_SURVEY_EXPIRATION_IN_DAYS]);
  } catch (e) {
    return null;
  }
}

export function useIsSurveyExpired(config?: { enabled?: boolean }) {
  const expirationDate = useSurveyExpirationDate(config);
  return Boolean(expirationDate) && isAfter(new Date(), expirationDate!);
}

export function useSiteClosingDate(config?: { enabled?: boolean }) {
  const { data: settings } = useSettings(config);
  if (!settings?.[SETTING_SITE_CLOSED_DATE]) {
    return null;
  }
  try {
    const date = parseISO(settings[SETTING_SITE_CLOSED_DATE]);
    return setMilliseconds(date, 0);
  } catch (e) {
    return null;
  }
}

export function useIsSiteClosed(config?: { enabled?: boolean }) {
  const closingDate = useSiteClosingDate(config);
  return closingDate && isAfter(new Date(), closingDate);
}

export function useSettings(config?: QueryObserverConfig<Record<string, any>>) {
  const api = useApi();
  const store = useLocalCache();
  const { staleTime = SETTING_STALE_TIME, initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['settings'],
    async (key: string) => {
      const { data } = await api.get<Record<string, any>>(API_SETTINGS);
      return data;
    },
    {
      staleTime,
      initialData: initialData ?? (() => store.get(['settings'])),
      onSuccess(data) {
        store.set(['settings'], data);
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useSettingsUpdate(
  config?: MutationConfig<Record<string, any>, unknown, Record<string, any>>,
) {
  const api = useApi();
  const cache = useQueryCache();
  const store = useLocalCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (settings: Record<string, any>) => {
      const { data } = await api.put<Record<string, any>>(API_SETTINGS, settings);
      return data;
    },
    {
      onSuccess(data, variables) {
        cache.setQueryData(['settings'], data);
        store.set(['settings'], data);
        onSuccess?.(data, variables);
      },
      ...config_,
    },
  );
}
