import { IonAlert, IonButton, IonList, IonPage } from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { isHouseholdReadOnly, useSelfHousehold } from '../../api/household';
import { useSelfUser } from '../../api/user';
import {
  Vehicle,
  getVehicleMakeModel,
  getVehicleName,
  getVehicleYear,
  useVehicleDelete,
  useVehicles,
} from '../../api/vehicle';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import InfiniteScroll from '../../components/containers/InfiniteScroll';
import AddonButton from '../../components/forms/AddonButton';
import ItemText from '../../components/forms/ItemText';
import FormLayout from '../../components/layouts/FormLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import { APP_RECRUITMENT_VEHICLE } from '../../urls';

const VehicleSummary: React.FC = () => {
  const history = useHistory();
  const { data: user } = useSelfUser();
  const { data: household } = useSelfHousehold();
  const { data, fetchMore, canFetchMore } = useVehicles(household?.id);
  const [deleteVehicle, resDeleteVehicle] = useVehicleDelete();

  const [isVehicleDelDialogVisible, setIsVehicleDelDialogVisible] = useState(false);

  const [currentVehicle, setCurrentVehicle] = useState<Vehicle>();

  const householdOwned = user?.householdOwnedId;
  const isHouseholdOwner = householdOwned && householdOwned === household?.id;
  const isReadOnly = !isHouseholdOwner || isHouseholdReadOnly(household);

  const onDeleteClick = (e: React.MouseEvent, vehicle: Vehicle) => {
    e.stopPropagation();
    setCurrentVehicle(vehicle);
    setIsVehicleDelDialogVisible(true);
  };

  const onDeleteVehicleClick = (e: React.MouseEvent) => {
    if (currentVehicle?.id) {
      deleteVehicle(currentVehicle.id);
    }
  };

  const onVehicleDelDialogDismiss = () => {
    setIsVehicleDelDialogVisible(false);
  };

  const bucket = new Set();
  const vehicles = (Array.isArray(data) ? data : [])
    .map((page) => page.items)
    .flat()
    .filter((vehicle) => (bucket.has(vehicle.id) ? false : (bucket.add(vehicle.id), true)));

  return (
    <IonPage>
      <FormLayout
        title="Vehicle Summary"
        subtitle={
          vehicles.length
            ? `Tap on a vehicle to ${isReadOnly ? 'view' : 'edit'}.`
            : 'No vehicle found.'
        }>
        <IonList className="input-group readonly">
          {vehicles.map((vehicle) => {
            const target = APP_RECRUITMENT_VEHICLE.replace(':id?', vehicle.id);

            return (
              <ItemText
                key={vehicle.id}
                title={getVehicleMakeModel(vehicle)}
                subtitle={getVehicleYear(vehicle)}
                onClick={() => history.push(target)}>
                {!isReadOnly && isHouseholdOwner ? (
                  <AddonButton
                    color="danger"
                    icon={trashOutline}
                    onClick={(e) => onDeleteClick(e, vehicle)}
                  />
                ) : null}
              </ItemText>
            );
          })}
        </IonList>
        <InfiniteScroll
          disabled={!canFetchMore}
          onIonInfinite={async (e: any) => {
            await fetchMore();
            e.target.complete();
          }}
        />
      </FormLayout>
      {!isReadOnly ? (
        <ButtonSheet>
          <IonButton
            shape="round"
            expand="block"
            routerLink={APP_RECRUITMENT_VEHICLE.replace(':id?', '')}>
            Add another vehicle
          </IonButton>
        </ButtonSheet>
      ) : null}
      <IonAlert
        isOpen={isVehicleDelDialogVisible}
        onDidDismiss={onVehicleDelDialogDismiss}
        header="Delete vehicle?"
        message={`Do you want to remove vehicle ${getVehicleName(currentVehicle)}?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: onVehicleDelDialogDismiss,
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: onDeleteVehicleClick,
          },
        ]}
      />
      <QueryResultToast result={resDeleteVehicle}>
        {`Vehicle ${getVehicleName(currentVehicle)} has been removed.`}
      </QueryResultToast>
    </IonPage>
  );
};

export default VehicleSummary;
