import { RangeChangeEventDetail } from '@ionic/core';
import { IonRange } from '@ionic/react';
import React from 'react';

type IonRangeProps = Parameters<typeof IonRange>[0];

const IonRange_: React.FC<IonRangeProps> = ({ value, onIonChange, ...props }) => {
  const onIonChange_ = (e: CustomEvent<RangeChangeEventDetail>) => {
    if (value !== e.detail.value) {
      onIonChange?.(e);
    }
  };

  return <IonRange value={value} onIonChange={onIonChange_} {...props} />;
};

export default IonRange_;
