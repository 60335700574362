import { TextField } from '@material-ui/core';
import { FilterInputValueProps } from '@material-ui/data-grid';
import { DateTimePicker } from '@material-ui/pickers';
import { isDate } from 'class-validator';
import { formatISO, parseISO } from 'date-fns';
import React from 'react';

const DateTimeFilterInput: React.FC<FilterInputValueProps> = (props) => {
  const { item, applyValue } = props;

  const value = item.value ? parseISO(item.value) : null;

  const onDateChange = (date: Date | null) => {
    applyValue({ ...item, value: date && isDate(date) ? formatISO(date) : undefined });
  };

  return (
    <DateTimePicker
      renderInput={(props) => {
        const { label, helperText, ...props_ } = props;
        return (
          <TextField
            label="Date"
            variant="standard"
            InputLabelProps={{ shrink: true }}
            {...props_}
          />
        );
      }}
      inputFormat="yyyy-MM-dd HH:mm:ss"
      value={isDate(value) ? value : null}
      onChange={onDateChange}
    />
  );
};

export default DateTimeFilterInput;
