import { IonCard } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import React from 'react';

export interface CardProps {
  top?: boolean;
  left?: boolean;
  right?: boolean;
  bottom?: boolean;
  className?: string;
}

const useStyles = makeStyles({
  root: {
    margin: 0,
    position: 'absolute',
    border: 'none',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.2)',
    '--ion-safe-area-left': 'env(safe-area-inset-left)',
    '--ion-safe-area-right': 'env(safe-area-inset-right)',
    top: ({ top }: Partial<CardProps>) =>
      top ? 'calc(var(--ion-margin) + var(--ion-safe-area-top, 0))' : undefined,
    left: ({ left }: Partial<CardProps>) =>
      left ? 'calc(var(--ion-margin) + var(--ion-safe-area-left, 0))' : undefined,
    right: ({ right }: Partial<CardProps>) =>
      right ? 'calc(var(--ion-margin) + var(--ion-safe-area-right, 0))' : undefined,
    bottom: ({ bottom }: Partial<CardProps>) =>
      bottom ? 'calc(var(--ion-margin) * 1.5 + var(--ion-safe-area-bottom, 0))' : undefined,
  },
});

const MapCard: React.FC<CardProps> = ({ children, ...props }) => {
  const { className, top, left, right, bottom } = props;
  const classes = useStyles({ top, left, right, bottom });

  return <IonCard className={[classes.root, className ?? ''].join(' ').trim()}>{children}</IonCard>;
};

export default MapCard;
