import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMemo } from 'react';

import { useSignOut, useToken } from './auth';

const API_ROOT = process.env.REACT_APP_API_ROOT;

export function useLocal() {
  return useMemo(() => axios.create(), []);
}

export function useApi() {
  const { data: token } = useToken();
  const [signOut] = useSignOut();

  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: API_ROOT,
      headers: { Authorization: `Bearer ${token}` },
    });

    const onResponse = (response: AxiosResponse) => response;
    const onHttpError = (error: AxiosError) => {
      if (error.response?.status === 401 && token) {
        signOut();
      }
      throw error;
    };

    instance.interceptors.response.use(onResponse, onHttpError);
    return instance;
  }, [token, signOut]);

  return api;
}
