const STATE_MAPPINGS = [
  ['DC', 'District of Columbia'],
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
];

const ABBR_TO_STATE = new Map();
const STATE_TO_ABBR = new Map();

STATE_MAPPINGS.forEach(([abbr, state]) => {
  ABBR_TO_STATE.set(abbr, state);
  STATE_TO_ABBR.set(state, abbr);
  STATE_TO_ABBR.set(state.toLowerCase(), abbr);
});

export function stateToAbbr(state: string) {
  if (STATE_TO_ABBR.has(state.toLowerCase())) {
    return STATE_TO_ABBR.get(state.toLowerCase());
  }
  return state;
}

function filterAddressComponent(components: google.maps.GeocoderAddressComponent[], type: string) {
  return components.find((component) => component.types.includes(type));
}

export function geocoderResultToAddress(result: google.maps.GeocoderResult) {
  const { address_components: cs } = result;

  const postalCode = filterAddressComponent(cs, 'postal_code')?.short_name ?? '';
  const locality = filterAddressComponent(cs, 'locality')?.long_name ?? '';
  const subLocality = filterAddressComponent(cs, 'sublocality')?.long_name ?? '';
  const thoroughfare = filterAddressComponent(cs, 'route')?.long_name ?? '';
  const subThoroughfare = filterAddressComponent(cs, 'street_number')?.long_name ?? '';
  const administrativeArea =
    filterAddressComponent(cs, 'administrative_area_level_1')?.long_name ?? '';

  const addressLine = thoroughfare
    ? (subThoroughfare ? subThoroughfare + ' ' : '') + thoroughfare
    : '';
  const city = locality ? locality : subLocality;
  return {
    address: addressLine,
    city: city,
    state: stateToAbbr(administrativeArea),
    zipCode: postalCode,
  };
}

export { STATE_MAPPINGS, ABBR_TO_STATE, STATE_TO_ABBR };
