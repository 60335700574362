import { IonIcon, IonItem, IonLabel, IonList, IonPage, IonSelectOption } from '@ionic/react';
import {
  chatbubbleOutline,
  contrastOutline,
  exitOutline,
  helpBuoyOutline,
  mapOutline,
  personOutline,
  shieldCheckmarkOutline,
} from 'ionicons/icons';
import React, { useContext } from 'react';

import { useSignOut } from '../api/auth';
import { isValidUser, useSelfUser } from '../api/user';
import { ThemeContext } from '../components/ThemeProvider';
import ItemText from '../components/forms/ItemText';
import IonSelect from '../components/forms/patch/IonSelect';
import FormLayout from '../components/layouts/FormLayout';
import { APP_ADDRESS_LIST, APP_FEEDBACK, APP_HELP, APP_PROFILE, APP_SECURITY } from '../urls';

const Settings: React.FC = () => {
  const { mode, setMode } = useContext(ThemeContext);
  const { data: user } = useSelfUser();

  const [signOut] = useSignOut();

  return (
    <IonPage>
      <FormLayout title="Settings" subtitle="Customize your experience.">
        <IonList>
          {isValidUser(user) ? (
            <ItemText title="Profile" routerLink={APP_PROFILE}>
              <IonIcon icon={personOutline} slot="start" />
            </ItemText>
          ) : null}
          {isValidUser(user) ? (
            <ItemText title="Security" routerLink={APP_SECURITY}>
              <IonIcon icon={shieldCheckmarkOutline} slot="start" />
            </ItemText>
          ) : null}
          {isValidUser(user) ? (
            <ItemText title="Saved addresses" routerLink={APP_ADDRESS_LIST}>
              <IonIcon icon={mapOutline} slot="start" />
            </ItemText>
          ) : null}
          <IonItem>
            <IonLabel>App theme</IonLabel>
            <IonIcon icon={contrastOutline} slot="start" />
            <IonSelect
              value={mode}
              interface="action-sheet"
              interfaceOptions={{ header: 'App theme' }}
              onIonChange={(e) => setMode?.(e.detail.value)}>
              <IonSelectOption value="light">Light</IonSelectOption>
              <IonSelectOption value="dark">Dark</IonSelectOption>
              <IonSelectOption value="system">Use system</IonSelectOption>
            </IonSelect>
          </IonItem>
          {isValidUser(user) ? (
            <ItemText title="Feedback" routerLink={APP_FEEDBACK}>
              <IonIcon icon={chatbubbleOutline} slot="start" />
            </ItemText>
          ) : null}
          <ItemText title="Help" routerLink={APP_HELP}>
            <IonIcon icon={helpBuoyOutline} slot="start" />
          </ItemText>
          <ItemText title="Sign out" color="danger" onClick={() => signOut()}>
            <IonIcon icon={exitOutline} color="danger" slot="start" />
          </ItemText>
        </IonList>
      </FormLayout>
    </IonPage>
  );
};

export default Settings;
