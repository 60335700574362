import { Plugins } from '@capacitor/core';
import { IonActionSheet, IonButton, IonList, IonPage, IonToast } from '@ionic/react';
import { closeOutline, locationOutline, searchOutline, trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';

import {
  Address,
  AddressBase,
  AddressCreate,
  AddressUpdate,
  UNKNOWN_ADDRESS,
  UNKNOWN_CITY,
  UNKNOWN_STATE,
  useAddressCreate,
  useAddressDelete,
  useAddressUpdate,
  useAddresses,
  useReverseGeocoding,
} from '../../api/address';
import { useSelfUser } from '../../api/user';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import InfiniteScroll from '../../components/containers/InfiniteScroll';
import AddonButton from '../../components/forms/AddonButton';
import EnterAddressDialog from '../../components/forms/EnterAddressDialog';
import ItemText from '../../components/forms/ItemText';
import SearchAddressDialog from '../../components/forms/SearchAddressDialog';
import FormLayout from '../../components/layouts/FormLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import { geocoderResultToAddress } from '../../utils/geolocation';

const { Geolocation } = Plugins;

const AddressList: React.FC = () => {
  const { data: user } = useSelfUser();
  const { data, isFetching, fetchMore, canFetchMore } = useAddresses(user?.id);
  const [create, resCreate] = useAddressCreate();
  const [update, resUpdate] = useAddressUpdate();
  const [delete_, resDelete] = useAddressDelete();
  const [geocode, resGeocode] = useReverseGeocoding();

  const [currentAddress, setCurrentAddress] = useState<AddressBase>();

  const [isLocatingFailed, setIsLocatingFailed] = useState(false);
  const [isSearchAddressDialogVisible, setIsSearchAddressDialogVisible] = useState(false);
  const [isEnterAddressDialogVisible, setIsEnterAddressDialogVisible] = useState(false);
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);

  const onSearchAddressClick = () => {
    setIsSearchAddressDialogVisible(true);
  };

  const onEnterManuallyClick = () => {
    setCurrentAddress({ address: '', city: '', state: '', zipCode: '' });
    setIsEnterAddressDialogVisible(true);
  };

  const onLocateClick = async () => {
    try {
      const location = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        maximumAge: 10,
      });
      const request = {
        location: { lat: location.coords.latitude, lng: location.coords.longitude },
      };
      const results = await geocode(request);
      if (results?.length) {
        const address = geocoderResultToAddress(results[0]);
        if (address.address) {
          onSaveAddress?.(address);
        } else {
          setIsLocatingFailed(true);
        }
      } else {
        setIsLocatingFailed(true);
      }
    } catch (e) {
      setIsLocatingFailed(true);
    }
  };

  const onEditAddressClick = (value: Address) => {
    setCurrentAddress(value);
    setIsEnterAddressDialogVisible(true);
  };

  const onSaveAddress = (value: AddressBase) => {
    if (value?.hasOwnProperty('id')) {
      update(value as AddressUpdate);
    } else {
      create(value as AddressCreate);
    }
    setCurrentAddress(undefined);
  };

  const onDeleteClick = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    delete_(id);
  };

  const bucket = new Set();
  const addresses = (Array.isArray(data) ? data : [])
    .map((page) => page.items)
    .flat()
    .filter((address) => (bucket.has(address.id) ? false : (bucket.add(address.id), true)));

  const subtitle =
    !data && isFetching
      ? 'Loading your addresses...'
      : addresses.length
      ? 'Tap on a address to edit.'
      : 'No addess found.';

  const isLocatingFailed_ = isLocatingFailed || resGeocode.isError;

  return (
    <IonPage>
      <FormLayout title="Save Addresses" subtitle={subtitle}>
        <IonList className="input-group readonly">
          {addresses.map((address) => {
            const { id, name, address: addr, city, state } = address;

            return (
              <ItemText
                key={id}
                title={name ? name : addr ? addr : UNKNOWN_ADDRESS}
                subtitle={`${city ? city : UNKNOWN_CITY}, ${state ? state : UNKNOWN_STATE}`}
                onClick={() => onEditAddressClick(address)}>
                <AddonButton
                  color="danger"
                  icon={trashOutline}
                  onClick={(e) => onDeleteClick(e, id)}
                />
              </ItemText>
            );
          })}
        </IonList>
        <InfiniteScroll
          disabled={!canFetchMore}
          onIonInfinite={async (e: any) => {
            await fetchMore();
            e.target.complete();
          }}
        />
      </FormLayout>
      <ButtonSheet>
        <IonButton shape="round" expand="block" onClick={() => setIsActionSheetVisible(true)}>
          {resCreate.isLoading || resUpdate.isLoading ? 'Saving...' : 'Add another address'}
        </IonButton>
      </ButtonSheet>
      <IonActionSheet
        buttons={[
          {
            text: 'Search addresses',
            icon: searchOutline,
            handler: onSearchAddressClick,
          },
          {
            text: resGeocode.isLoading ? 'Locating' : 'Use current location',
            icon: locationOutline,
            handler: !resGeocode.isLoading ? onLocateClick : undefined,
          },
          {
            text: 'Cancel',
            role: 'cancel',
            icon: closeOutline,
            handler: () => setIsActionSheetVisible(false),
          },
        ]}
        isOpen={isActionSheetVisible}
        onDidDismiss={() => setIsActionSheetVisible(false)}
      />
      <SearchAddressDialog
        isOpen={isSearchAddressDialogVisible}
        onSuccess={onSaveAddress}
        onEnterManuallyClick={() => onEnterManuallyClick()}
        onDismiss={() => setIsSearchAddressDialogVisible(false)}
      />
      <EnterAddressDialog
        value={currentAddress}
        onChange={onSaveAddress}
        isOpen={isEnterAddressDialogVisible}
        onDismiss={() => setIsEnterAddressDialogVisible(false)}
      />
      <IonToast
        duration={2000}
        color="danger"
        message={
          'Failed to get current location. ' +
          'Make sure your network is connected and permissions are granted.'
        }
        isOpen={isLocatingFailed_}
        onDidDismiss={() => {
          setIsLocatingFailed(false);
          resGeocode.reset();
        }}
      />
      <QueryResultToast result={resCreate}>Address has been saved.</QueryResultToast>
      <QueryResultToast result={resUpdate}>Address has been saved.</QueryResultToast>
      <QueryResultToast result={resDelete}>Address has been removed.</QueryResultToast>
    </IonPage>
  );
};

export default AddressList;
