import { Color } from '@ionic/core';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import React from 'react';

export interface AddonButtonProps {
  icon?: string;
  title?: string;
  color?: Color;
  align?: 'center' | 'end';
  onClick?: (event: React.MouseEvent<HTMLIonButtonElement>) => void;
}

const AddonButton: React.FC<AddonButtonProps> = (props) => {
  const { title, icon, color, align = 'end', onClick } = props;

  return (
    <IonButton
      slot="end"
      fill="clear"
      size="default"
      color={color}
      onClick={onClick}
      className={['help', align ?? ''].join(' ').trim()}>
      <IonIcon icon={icon} />
      {title ? <IonText>{title}</IonText> : null}
    </IonButton>
  );
};

export default AddonButton;
