import { IonButton, IonPage } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';

import ButtonSheet from '../../components/buttons/ButtonSheet';
import FormLayout from '../../components/layouts/FormLayout';
import { APP_RECRUITMENT_SUMMARY } from '../../urls';

const Welcome: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <FormLayout
        title="Start your survey"
        subtitle={
          'Take the recruitment questionnaire to determine your eligibility for HTS@TN survey.'
        }
      />
      <ButtonSheet>
        <IonButton
          expand="block"
          shape="round"
          onClick={() => history.replace(APP_RECRUITMENT_SUMMARY)}>
          Start Now
        </IonButton>
      </ButtonSheet>
    </IonPage>
  );
};

export default Welcome;
