import { IonIcon, IonList, IonProgressBar, IonSpinner } from '@ionic/react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  InputBase,
  Snackbar,
  debounce,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { addOutline, createOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';

import { AddressBase, useGeocoding, usePlaceSearch } from '../../api/address';
import { geocoderResultToAddress } from '../../utils/geolocation';
import ItemText from './ItemText';

export interface SearchAddressDialogProps {
  isOpen: boolean;
  onSuccess?: (address: AddressBase) => void;
  onDismiss?: () => void;
  onEnterManuallyClick?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  search: {
    flex: 1,
  },
  loading: {
    top: 0,
    left: 0,
    height: 3,
    position: 'absolute',
  },
  content: {
    padding: 0,
    position: 'relative',
    borderBottom: 'none',
    '& .item-button': {
      margin: '0 !important',
      '--padding-start': theme.spacing(2),
      '--padding-end': theme.spacing(2),
      '--border-width': '0',
    },
    '& .item-button ion-icon, & .item-button ion-spinner': {
      alignSelf: 'center',
    },
  },
  alert: {
    '& .MuiPaper-root': {
      color: 'var(--ion-color-danger-contrast)',
      backgroundColor: 'var(--ion-color-danger)',
    },
  },
}));

const SearchAddressDialog: React.FC<SearchAddressDialogProps> = (props) => {
  const classes = useStyles();
  const [search, resSearch] = usePlaceSearch();
  const [geocode, resGeocode] = useGeocoding();
  const { isOpen, onSuccess, onDismiss, onEnterManuallyClick } = props;

  const [keyword, setKeyword] = useState('');
  const [selected, setSelected] = useState<google.maps.places.AutocompletePrediction>();
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  const [isResolutionFailed, setIsResolutionFailed] = useState(false);
  const [isAddressInvalidVisible, setIsAddressInvalidVisible] = useState(false);

  const debouncedSearch = useCallback(
    debounce(async (input: string) => {
      if (input?.trim()) {
        const suggestions = await search({ input: input.trim() });
        setSuggestions(suggestions ?? []);
      } else {
        setSuggestions([]);
      }
    }, 250),
    [search],
  );

  useEffect(() => {
    if (isOpen) {
      debouncedSearch(keyword);
    }
  }, [isOpen, keyword, debouncedSearch]);

  const onKeywordChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeyword(e.target.value);
  };

  const onEnterManuallyClick_ = () => {
    onEnterManuallyClick?.();
    onDismiss?.();
  };

  const onSelectPlace = async (place: google.maps.places.AutocompletePrediction) => {
    setSelected(place);
    try {
      const results = await geocode({ placeId: place.place_id });
      if (results?.length) {
        const address = geocoderResultToAddress(results[0]) as AddressBase;
        if (address.address) {
          address.name = place.structured_formatting.main_text;
          onSuccess?.(address);
          onDismiss?.();
        } else {
          setIsAddressInvalidVisible(true);
        }
      } else {
        setIsResolutionFailed(true);
      }
    } catch (e) {
      setIsResolutionFailed(true);
    } finally {
      setSelected(undefined);
    }
  };

  const isResolvingAddress = Boolean(selected);
  const isResolutionFailed_ = isResolutionFailed || resGeocode.isError;

  return (
    <Dialog scroll="paper" classes={{ paper: classes.root }} open={isOpen} onClose={onDismiss}>
      <DialogTitle disableTypography className={classes.header}>
        <IconButton disabled>
          <SearchIcon />
        </IconButton>
        <InputBase
          autoFocus
          placeholder="Search addresses"
          className={classes.search}
          disabled={isResolvingAddress}
          onChange={onKeywordChange}
        />
        <IconButton onClick={onDismiss}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        {resSearch.isLoading ? (
          <IonProgressBar color="primary" type="indeterminate" className={classes.loading} />
        ) : null}
        <IonList className="input-group readonly">
          {onEnterManuallyClick ? (
            <ItemText
              color="primary"
              title="Enter manually"
              disabled={isResolvingAddress}
              onClick={onEnterManuallyClick_}>
              <IonIcon slot="end" color="primary" icon={createOutline} />
            </ItemText>
          ) : null}
          {suggestions.map((prediction) => {
            const { place_id, structured_formatting } = prediction;
            let subtitle = structured_formatting.secondary_text;
            if (subtitle?.endsWith(', USA')) {
              subtitle = subtitle.substring(0, subtitle.length - 5);
            }
            return (
              <ItemText
                key={place_id}
                disabled={isResolvingAddress}
                title={structured_formatting.main_text}
                subtitle={subtitle}
                onClick={() => onSelectPlace(prediction)}>
                {selected?.place_id === place_id ? (
                  <IonSpinner slot="end" color="primary" />
                ) : (
                  <IonIcon slot="end" color="primary" icon={addOutline} />
                )}
              </ItemText>
            );
          })}
        </IonList>
      </DialogContent>
      <Snackbar
        autoHideDuration={2000}
        className={classes.alert}
        TransitionComponent={Fade}
        open={isResolutionFailed_}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          resGeocode.reset();
          setIsResolutionFailed(false);
        }}
        message="Failed to get address of selected location. Please try again."
      />
      <Snackbar
        autoHideDuration={2000}
        className={classes.alert}
        TransitionComponent={Fade}
        open={isAddressInvalidVisible}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setIsAddressInvalidVisible(false)}
        message="Selected address is not a street address."
      />
    </Dialog>
  );
};

export default SearchAddressDialog;
