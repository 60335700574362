import { IonList, IonModal } from '@ionic/react';
import React from 'react';

import { Member, UNNAMED_MEMBER, useMembers, useSelfMember } from '../../api/member';
import InfiniteScroll from '../../components/containers/InfiniteScroll';
import ItemText from '../../components/forms/ItemText';
import ModalLayout from '../../components/layouts/ModalLayout';

interface MemberSelectProps {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  optional?: boolean;
  onDismiss?: () => void;
  onSelect?: (member?: Member) => void;
}

const MemberSelect: React.FC<MemberSelectProps> = (props) => {
  const { isOpen, optional, onSelect, onDismiss } = props;
  const { title = 'Members', subtitle = 'Select from following household members.' } = props;

  const { data: self } = useSelfMember();
  const { data, fetchMore, canFetchMore } = useMembers(self?.householdId);

  const bucket = new Set();
  const members = (Array.isArray(data) ? data : [])
    .map((page) => page.items)
    .flat()
    .filter((member) => member.id !== self?.id)
    .filter((member) => (bucket.has(member.id) ? false : (bucket.add(member.id), true)));
  if (self) {
    members.unshift(self);
  }

  return (
    <IonModal isOpen={isOpen}>
      <ModalLayout title={title} subtitle={subtitle} onDismiss={onDismiss}>
        <IonList>
          {optional ? (
            <ItemText
              key={-1}
              title="Not household member"
              onClick={() => {
                onDismiss?.();
                onSelect?.(undefined);
              }}
            />
          ) : null}
          {members.map((member) => {
            const isSelfMember = member.id === self?.id;
            const name = member.name ? member.name : UNNAMED_MEMBER;
            const relationship = isSelfMember ? 'Self' : member.relationship?.title ?? 'Member';

            return (
              <ItemText
                key={member.id}
                title={name}
                subtitle={relationship}
                onClick={() => {
                  onDismiss?.();
                  onSelect?.(member);
                }}
              />
            );
          })}
        </IonList>
        <InfiniteScroll
          disabled={!canFetchMore}
          onIonInfinite={async (e: any) => {
            await fetchMore();
            e.target.complete();
          }}
        />
      </ModalLayout>
    </IonModal>
  );
};

export default MemberSelect;
