import { Plugins } from '@capacitor/core';
import { IonButton, IonCardContent, IonIcon, IonToast } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import { Coords } from 'google-map-react';
import { addOutline, locateOutline, removeOutline } from 'ionicons/icons';
import React, { useState } from 'react';

const { Geolocation } = Plugins;

const useStyles = makeStyles({
  controls: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& ion-button': {
      margin: 0,
      width: 40,
      height: 40,
      '--padding-end': 0,
      '--padding-start': 0,
      '--border-radius': 0,
      '&.toggled': {
        color: 'var(--ion-color-primary-contrast)',
        backgroundColor: 'var(--ion-color-primary)',
      },
      '&:not(:last-child)': {
        borderBottom: '0.75px solid var(--ion-border-color)',
      },
    },
  },
});

export interface MapControlsProps {
  mapApi?: { map: any; maps: any };
  enableCenterToggle?: boolean;
  defaultCenterToggled?: boolean;
  onCenterToggle?: (toggled: boolean) => void;
  onCenterClick?: () => void;
  onZoomInClick?: () => void;
  onZoomOutClick?: () => void;
  onCenterLocated?: (coordinate: Coords) => void;
}

const MapControls: React.FC<MapControlsProps> = ({ mapApi, ...props }) => {
  const classes = useStyles();
  const { enableCenterToggle, defaultCenterToggled = false, onCenterToggle } = props;
  const { onCenterClick, onZoomInClick, onZoomOutClick, onCenterLocated } = props;

  const [isCenterToggled, setIsCenterToggled] = useState(defaultCenterToggled);
  const [isLocateFailVisible, setIsLocateFailVisible] = useState(false);

  const onCenterToggle_ = () => {
    setIsCenterToggled(!isCenterToggled);
    onCenterToggle?.(!isCenterToggled);
  };

  const onCenterClick_ = async () => {
    try {
      const location = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
      const coordinate = { lat: location.coords.latitude, lng: location.coords.longitude };
      onCenterLocated?.(coordinate);
      mapApi?.map.setCenter(coordinate);
      mapApi?.map.setZoom(17);
    } catch (e) {
      setIsLocateFailVisible(true);
    }
  };

  const onZoomInClick_ = () => {
    mapApi?.map?.setZoom(mapApi.map?.getZoom() + 1);
  };

  const onZoomOutClick_ = () => {
    mapApi?.map?.setZoom(mapApi.map?.getZoom() - 1);
  };

  return (
    <>
      <IonCardContent className={classes.controls}>
        <IonButton
          fill="clear"
          slot="icon-only"
          className={isCenterToggled ? 'toggled' : ''}
          onClick={
            enableCenterToggle
              ? onCenterToggle_
              : !mapApi || onCenterClick
              ? onCenterClick
              : onCenterClick_
          }>
          <IonIcon icon={locateOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          slot="icon-only"
          onClick={!mapApi || onZoomInClick ? onZoomInClick : onZoomInClick_}>
          <IonIcon icon={addOutline} />
        </IonButton>
        <IonButton
          fill="clear"
          slot="icon-only"
          onClick={!mapApi || onZoomOutClick ? onZoomOutClick : onZoomOutClick_}>
          <IonIcon icon={removeOutline} />
        </IonButton>
      </IonCardContent>
      <IonToast
        duration={2000}
        color="danger"
        message="Failed to get current location"
        isOpen={isLocateFailVisible}
        onDidDismiss={() => setIsLocateFailVisible(false)}
      />
    </>
  );
};

export default MapControls;
