import DateFnsUtils from '@date-io/date-fns';
import { IonApp } from '@ionic/react';
import { LocalizationProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import React from 'react';

import ThemeProvider from './components/ThemeProvider';
import Router from './components/navigation/Router';
import UrlListener from './components/navigation/UrlListener';

const history = createBrowserHistory();

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <IonApp>
          <Router history={history} />
          <UrlListener history={history} />
        </IonApp>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
