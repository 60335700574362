import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import React from 'react';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import VehicleTable from '../../components/tables/VehicleTable';
import DashboardHeader from '../../components/typography/DashboardHeader';

const VehicleList: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Typography color="inherit" variant="overline">
            Data
          </Typography>
          <Typography color="textPrimary" variant="overline">
            Vehicles
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          All Vehicles
        </Typography>
      </DashboardHeader>
      <Paper variant="outlined">
        <VehicleTable />
      </Paper>
    </DashboardLayout>
  );
};

export default VehicleList;
