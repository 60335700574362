import { IonButton, IonIcon, IonPage } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import {
  checkmarkCircleOutline,
  enterOutline,
  glassesOutline,
  golfOutline,
  warningOutline,
} from 'ionicons/icons';
import React from 'react';
import { QueryStatus } from 'react-query';
import { useHistory } from 'react-router';

import { useSelfHousehold } from '../../api/household';
import { useIsSurveyExpired } from '../../api/setting';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import FormLayout from '../../components/layouts/FormLayout';
import { APP_RECRUITMENT_SUMMARY } from '../../urls';

const useStyles = makeStyles({
  title: {
    '& h1 ion-icon': {
      marginRight: 'calc(var(--ion-margin) / 2)',
    },
  },
});

const Status: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: household, status } = useSelfHousehold();
  const isSurveyExpired = useIsSurveyExpired();

  let title;
  let subtitle;
  let button;

  switch (status) {
    case QueryStatus.Error:
      title = (
        <>
          <IonIcon slot="start" icon={warningOutline} color="danger" />
          <span>Error</span>
        </>
      );
      subtitle = 'Failed to load recruitment status.';
      break;
    case QueryStatus.Loading:
      title = 'Loading...';
      break;
    default:
      switch (household?.enrollmentStatus?.title) {
        case 'submitted':
          title = (
            <>
              <IonIcon slot="start" icon={enterOutline} color="tertiary" />
              <span>Response Submitted</span>
            </>
          );
          subtitle = `You have submitted your recruitment survey response.
          You will be updated once we start reviewing your response.`;
          button = 'Review';
          break;
        case 'reviewing':
          title = (
            <>
              <IonIcon slot="start" icon={glassesOutline} color="warning" />
              <span>Under Review</span>
            </>
          );
          subtitle = `We are reviewing your recruitment survey response.
          You will be updated once a decision is made.`;
          button = 'Review';
          break;
        case 'accepted':
          if (!isSurveyExpired) {
            title = (
              <>
                <IonIcon slot="start" icon={checkmarkCircleOutline} color="success" />
                <span>You Are Enrolled</span>
              </>
            );
            subtitle = `Thank you for your participation.
            You can now record and save your trips.`;
            button = 'Review';
          } else {
            title = (
              <>
                <IonIcon slot="start" icon={golfOutline} color="medium" />
                <span>Survey Completed</span>
              </>
            );
            subtitle = `Your survey is completed. Thank you for your participation.`;
          }
          break;
        case 'rejected':
          title = (
            <>
              <IonIcon slot="start" icon={warningOutline} color="danger" />
              <span>Enrollment Failed</span>
            </>
          );
          subtitle = `We cannot enroll you right now based on your response.
          Please make sure your answers are accurate and try again.`;
          button = 'Review & Revise';
          break;
      }
      break;
  }

  return (
    <IonPage>
      <FormLayout className={classes.title} title={title} subtitle={subtitle} />
      {button ? (
        <ButtonSheet>
          <IonButton
            expand="block"
            shape="round"
            onClick={() => history.replace(APP_RECRUITMENT_SUMMARY)}>
            {button}
          </IonButton>
        </ButtonSheet>
      ) : null}
    </IonPage>
  );
};

export default Status;
