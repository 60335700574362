import { IonButton, IonCardContent } from '@ionic/react';
import { Box, Breadcrumbs, Grid, Link, Paper, Typography, makeStyles } from '@material-ui/core';
import { Coords } from 'google-map-react';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { Trip, useTrip } from '../../api/trip';
import LoadingOrFail from '../../components/containers/LoadingOrFail';
import DashItemText from '../../components/forms/DashItemText';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import Map from '../../components/map/Map';
import MapCard from '../../components/map/MapCard';
import MapControls from '../../components/map/MapControls';
import MapMarker from '../../components/map/MapMarker';
import useDrawTrack from '../../components/map/useDrawTrack';
import DashboardHeader from '../../components/typography/DashboardHeader';
import { DASHBOARD_TRIP_LIST, DASHBOARD_USER, DASHBOARD_VEHICLE } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const useStyles = makeStyles({
  controls: {
    padding: 0,
    '& ion-button': {
      margin: 0,
    },
  },
});

const TripBasicInfo: React.FC<{ trip: Trip }> = ({ trip }) => {
  return (
    <Box mb={4}>
      <Grid container spacing={2}>
        <DashItemText xs={12} md={6} title="ID">
          {trip.id}
        </DashItemText>
        <DashItemText xs={12} md={6} title="User">
          <Link component={RouterLink} to={DASHBOARD_USER.replace(':id', trip.userId ?? '')}>
            {trip.userId}
          </Link>
        </DashItemText>
        <DashItemText xs={12} md={8} title="Purpose">
          {trip.purpose?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={2} title="Distance (Miles)">
          {typeof trip.distance === 'number' ? trip.distance : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={2} title="Party Size">
          {typeof trip.partySize === 'number' ? trip.partySize : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Transport Mode">
          {trip.transportMode?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Vehicle">
          {typeof trip.isHouseholdVehicle === 'boolean' ? (
            trip.householdVehicleId ? (
              <Link
                component={RouterLink}
                to={DASHBOARD_VEHICLE.replace(':id', trip.householdVehicleId ?? '')}>
                {trip.householdVehicleId}
              </Link>
            ) : (
              'Not household vehicle'
            )
          ) : (
            '(Not set)'
          )}
        </DashItemText>
        <DashItemText xs={12} md={8} title="Start Address">
          {trip.startAddress ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Start City">
          {trip.startCity ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Start State">
          {trip.startState ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Start Postal Code">
          {trip.startZipCode ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Start At">
          {trip.startAt ? humanizeIsoDate(trip.startAt) : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={8} title="End Address">
          {trip.endAddress ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="End City">
          {trip.endCity ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="End State">
          {trip.endState ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="End Postal Code">
          {trip.endZipCode ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="End At">
          {trip.endAt ? humanizeIsoDate(trip.endAt) : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Created At">
          {humanizeIsoDate(trip.createdAt)}
        </DashItemText>
      </Grid>
    </Box>
  );
};

const TripMap: React.FC<{ trip: Trip }> = ({ trip }) => {
  const classes = useStyles();

  const [mapApi, setMapApi] = useState<{ map: any; maps: any }>();
  const [center, setCenter] = useState<Coords>();

  const coordinates = trip?.coordinates;
  const { resize } = useDrawTrack(mapApi, coordinates);

  let start: Coords | null = null;
  let end: Coords | null = null;
  if (coordinates && coordinates.length) {
    start = { lat: coordinates[0].lat, lng: coordinates[0].lng };
    if (coordinates.length > 1) {
      end = {
        lat: coordinates[coordinates.length - 1].lat,
        lng: coordinates[coordinates.length - 1].lng,
      };
    }
  }

  return (
    <Paper variant="outlined">
      <Box px={2} py={1}>
        <Typography color="textSecondary" variant="overline">
          Trip Track
        </Typography>
      </Box>
      <Box height={500} position="relative">
        <Map onGoogleApiLoaded={setMapApi}>
          {center ? <MapMarker type="current" {...center} /> : null}
          {start ? <MapMarker type="start" {...start} /> : null}
          {end ? <MapMarker type="end" {...end} /> : null}
        </Map>
        <MapCard bottom right>
          <MapControls mapApi={mapApi} onCenterLocated={setCenter} />
        </MapCard>
        <MapCard bottom left>
          <IonCardContent className={classes.controls}>
            <IonButton fill="clear" size="small" onClick={() => resize()}>
              Reset View
            </IonButton>
          </IonCardContent>
        </MapCard>
      </Box>
    </Paper>
  );
};

const TripDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: trip, isError } = useTrip(id, { enabled: Boolean(id) });

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Link color="inherit" variant="overline" component={RouterLink} to={DASHBOARD_TRIP_LIST}>
            Trips
          </Link>
          <Typography color="textPrimary" variant="overline">
            {id}
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          Trip Details
        </Typography>
      </DashboardHeader>
      <LoadingOrFail isLoading={!trip} isError={isError}>
        {trip ? (
          <>
            <TripBasicInfo trip={trip} />
            {trip.coordinates?.length ? <TripMap trip={trip} /> : null}
          </>
        ) : null}
      </LoadingOrFail>
    </DashboardLayout>
  );
};

export default TripDetails;
