import { FeatureMode, SortModelParams } from '@material-ui/data-grid';
import { useState } from 'react';

import { SortingOptions } from '../../api/common';

interface SortingProps {
  sortingMode: FeatureMode;
  onSortModelChange: (param: SortModelParams) => void;
}

export const defaultSortingFactory = (params: SortModelParams): SortingOptions => {
  const item = params.sortModel.length ? params.sortModel[0] : undefined;
  return { column: item?.field, order: item?.sort ?? undefined };
};

export default function useSorting(
  sortingFactory = defaultSortingFactory,
): [SortingOptions, SortingProps] {
  const [sorting, setSorting] = useState<SortingOptions>({});

  const onSortChange = (params: SortModelParams) => setSorting(sortingFactory(params));

  return [
    sorting,
    {
      sortingMode: 'server' as FeatureMode,
      onSortModelChange: onSortChange,
    },
  ];
}
