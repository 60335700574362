import { IonCol, IonGrid, IonRow } from '@ionic/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { isPostalCode } from 'class-validator';
import React, { useEffect, useState } from 'react';

import { AddressBase } from '../../api/address';
import { STATE_MAPPINGS } from '../../utils/geolocation';
import { capitalize } from '../../utils/string';

export interface EnterAddressDialogProps {
  isOpen: boolean;
  value?: Partial<AddressBase>;
  onChange?: (value: AddressBase) => void;
  onDismiss?: () => void;
}

const useStyles = makeStyles({
  title: {
    paddingBottom: 0,
  },
  grid: {
    padding: 0,
    '& ion-col': {
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
      },
    },
  },
});

const STATES = STATE_MAPPINGS as [string | undefined, string][];

const EnterAddressDialog: React.FC<EnterAddressDialogProps> = (props) => {
  const { isOpen, value, onChange, onDismiss } = props;
  const classes = useStyles();

  const [name, setName] = useState(value?.name ?? '');
  const [address, setAddress] = useState(value?.address ?? '');
  const [city, setCity] = useState(capitalize(value?.city) ?? '');
  const [state, setState] = useState(value?.state ?? 'DC');
  const [zipCode, setZipCode] = useState(value?.zipCode ?? '');

  useEffect(() => {
    setName(value?.name ?? '');
    setAddress(value?.address ?? '');
    setCity(capitalize(value?.city) ?? '');
    setState(value?.state ?? 'DC');
    setZipCode(value?.zipCode ?? '');
  }, [value]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  const onCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(capitalize(e.target.value));
  };

  const onStateChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setState(e.target.value as string);
  };

  const onZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipCode(e.target.value);
  };

  const onSubmit = () => {
    if (
      name !== value?.name ||
      address !== value?.address ||
      city !== value?.city ||
      state !== value?.state ||
      zipCode !== value?.zipCode
    ) {
      onChange?.(Object.assign({}, value, { name, address, city, state, zipCode }));
    }
    onDismiss?.();
  };

  const isZipCodeValid = isPostalCode(zipCode, 'US');
  const canSubmit = Boolean(address && city && state && zipCode && isZipCodeValid);

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={onDismiss}>
      <DialogTitle classes={{ root: classes.title }}>Enter address</DialogTitle>
      <DialogContent>
        <IonGrid className={classes.grid}>
          <IonRow>
            <IonCol>
              <TextField
                fullWidth
                label="Name"
                variant="standard"
                value={name}
                onChange={onNameChange}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                required
                fullWidth
                label="Address"
                variant="standard"
                value={address}
                onChange={onAddressChange}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <TextField
                required
                fullWidth
                label="City"
                variant="standard"
                value={city}
                onChange={onCityChange}
              />
            </IonCol>
            <IonCol>
              <FormControl required fullWidth>
                <InputLabel>State</InputLabel>
                <Select
                  value={state}
                  onChange={onStateChange}
                  renderValue={(value) => value as string}>
                  {STATES.map(([key, name]) => (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </IonCol>
            <IonCol>
              <TextField
                required
                fullWidth
                label="Postal code"
                variant="standard"
                value={zipCode}
                onChange={onZipCodeChange}
                error={Boolean(zipCode && !isZipCodeValid)}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onDismiss}>
          Cancel
        </Button>
        <Button color="primary" disabled={!canSubmit} onClick={onSubmit}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnterAddressDialog;
