import { IonToast } from '@ionic/react';
import React from 'react';
import { QueryStatus } from 'react-query';

import { getErrorMessage } from '../../utils/error';

export interface QueryResultToastProps {
  result: { status: QueryStatus; error: unknown; reset?: () => void };
  onDismiss?: () => void;
}

const QueryResultToast: React.FC<QueryResultToastProps> = ({ result, onDismiss, children }) => {
  const { status, error, reset } = result;
  const color =
    status === QueryStatus.Error
      ? 'danger'
      : status === QueryStatus.Success
      ? 'success'
      : 'primary';
  const text = status === QueryStatus.Error ? getErrorMessage(error) : children;
  const isVisible = status === QueryStatus.Error || status === QueryStatus.Success;

  // FIXME: currently toast will dismiss before the duration ends
  const onDidDismiss = () => {
    reset?.();
    onDismiss?.();
  };

  return (
    <IonToast
      duration={2000}
      color={color}
      message={text}
      isOpen={isVisible}
      onDidDismiss={onDidDismiss}
    />
  );
};

export default QueryResultToast;
