import { InputChangeEventDetail } from '@ionic/core';
import { IonButton, IonIcon, IonItem, IonList, IonModal } from '@ionic/react';
import { keyOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { QueryStatus } from 'react-query';

import { useConfirmPasswordReset } from '../../api/auth';
import IonInput from '../../components/forms/patch/IonInput';
import ModalLayout from '../../components/layouts/ModalLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';

interface ConfirmPasswordProps {
  email: string;
  password: string;
  isOpen: boolean;
  onDismiss?: () => void;
  onSuccess?: () => void;
}

const ConfirmPassword: React.FC<ConfirmPasswordProps> = (props) => {
  const { email, password, isOpen, onDismiss, onSuccess } = props;

  const [token, setToken] = useState('');
  const [confirm, { status, error, reset }] = useConfirmPasswordReset();

  const onTokenChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setToken(e.detail.value!);
  };

  const onToastDismiss = () => {
    if (status === QueryStatus.Success) {
      onSuccess?.();
    }
  };

  const onSubmit = () => confirm({ email, password, token });

  const isLoading = status === QueryStatus.Loading;
  const isFormValid = Boolean(email && password && token);
  const canSubmit = isFormValid && !isLoading;

  useEffect(() => {
    if (status === QueryStatus.Success) {
      onDismiss?.();
    }
  }, [status, onDismiss]);

  return (
    <>
      <IonModal isOpen={isOpen}>
        <ModalLayout
          title="Enter Reset Token"
          subtitle="Please check your email for the token and enter here."
          onDismiss={onDismiss}>
          <IonList>
            <IonItem className="ion-margin-top">
              <IonIcon icon={keyOutline} slot="start" />
              <IonInput
                placeholder="Password reset token"
                value={token}
                onIonChange={onTokenChange}
              />
            </IonItem>
          </IonList>
          <IonButton
            shape="round"
            expand="block"
            disabled={!canSubmit}
            onClick={onSubmit}
            className="ion-no-margin ion-margin-top">
            {isLoading ? 'Loading...' : 'Confirm'}
          </IonButton>
        </ModalLayout>
      </IonModal>
      <QueryResultToast result={{ status, error, reset }} onDismiss={onToastDismiss}>
        Password reset successfully. Redirecting to sign in page...
      </QueryResultToast>
    </>
  );
};

export default ConfirmPassword;
