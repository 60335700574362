import { makeStyles } from '@material-ui/core';
import React from 'react';
import { QueryStatus } from 'react-query';

import { LookupValue } from '../../api/common';
import { ItemSelectProps } from './ItemSelect';
import KvSelect from './KvSelect';

const useStyles = makeStyles({
  danger: {
    color: 'var(--ion-color-danger)',
  },
});

export interface LoadableKvSelectProps<K extends string | number, V> extends ItemSelectProps<K> {
  status?: QueryStatus;
  entries?: LookupValue<K, V>[];
}

function LoadableKvSelect<K extends string | number, V>(props: LoadableKvSelectProps<K, V>) {
  const { status, entries, readonly, selectedText, ...props_ } = props;
  const classes = useStyles();
  const isReadOnly = status === QueryStatus.Loading || status === QueryStatus.Error || readonly;

  let title;

  switch (status) {
    case QueryStatus.Loading:
      title = 'Loading...';
      break;
    case QueryStatus.Error:
      title = 'Failed to fetch available options';
      break;
    default:
      title = selectedText;
      break;
  }

  return (
    <KvSelect
      readonly={isReadOnly}
      selectedText={title}
      className={status === QueryStatus.Error ? classes.danger : ''}
      entries={(entries || []).map(({ id, title }) => [id, title])}
      {...props_}
    />
  );
}

export default LoadableKvSelect;
