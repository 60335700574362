import { InputChangeEventDetail } from '@ionic/core';
import { IonInput } from '@ionic/react';
import React from 'react';

type IonInputProps = Parameters<typeof IonInput>[0];

const IonInput_: React.FC<IonInputProps> = ({ value, onIonChange, ...props }) => {
  const onIonChange_ = (e: CustomEvent<InputChangeEventDetail>) => {
    if (value !== e.detail.value) {
      onIonChange?.(e);
    }
  };

  return <IonInput value={value} onIonChange={onIonChange_} {...props} />;
};

export default IonInput_;
