import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import {
  Household,
  useHousehold,
  useHouseholdAccept,
  useHouseholdReject,
  useHouseholdReview,
} from '../../api/household';
import { useIsSiteClosed } from '../../api/setting';
import LoadingOrFail from '../../components/containers/LoadingOrFail';
import DashItemTab, { DashItemTabProps } from '../../components/forms/DashItemTab';
import DashItemTabPanel from '../../components/forms/DashItemTabPanel';
import DashItemText from '../../components/forms/DashItemText';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import MemberTable from '../../components/tables/MemberTable';
import VehicleTable from '../../components/tables/VehicleTable';
import DashboardHeader from '../../components/typography/DashboardHeader';
import { DASHBOARD_HOUSEHOLD_LIST, DASHBOARD_USER } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const HouseholdBasicInfo: React.FC<{ household: Household }> = ({ household }) => {
  const isSiteClosed = useIsSiteClosed();
  const [review, resReview] = useHouseholdReview();
  const [accept, resAccept] = useHouseholdAccept();
  const [reject, resReject] = useHouseholdReject();

  const [currentAction, setCurrentAction] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onChangeStatusClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onChangeStatusMenuClose = () => {
    setAnchorEl(null);
  };

  const onChangeStatusMenuClick = (name: string) => {
    setCurrentAction(name);
    onChangeStatusMenuClose();
    switch (name) {
      case 'reviewing':
        review(household.id);
        break;
      case 'accepted':
        accept(household.id);
        break;
      case 'rejected':
        reject(household.id);
        break;
    }
  };

  const availableStatus = ['reviewing', 'accepted', 'rejected'];
  const canChangeStatus = !resReview.isLoading && !resAccept.isLoading && !resReject.isLoading;
  const currentActionResult = (() => {
    switch (currentAction) {
      case 'reviewing':
        return resReview;
      case 'accepted':
        return resAccept;
      case 'rejected':
        return resReject;
    }
    return null;
  })();

  return (
    <Box mb={4}>
      <Grid container spacing={2}>
        <DashItemText xs={12} md={6} title="ID">
          {household.id}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Owner">
          {household.ownerId ? (
            <Link component={RouterLink} to={DASHBOARD_USER.replace(':id', household.ownerId)}>
              {household.ownerId}
            </Link>
          ) : null}
        </DashItemText>
        <DashItemText xs={12} md={8} title="Address">
          {household.address}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="City">
          {household.city}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="State">
          {household.state}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Postal Code">
          {household.zipCode}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Housing Type">
          {household.housingType?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Housing Ownership Type">
          {household.housingOwnershipType?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Living Time">
          {typeof household.livingTime === 'number' ? household.livingTime : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Household Income">
          {household.incomeLevel?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Enrollment Status">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              {household.enrollmentStatus?.title.toUpperCase() ?? 'NONE'}
            </Grid>
            {household.enrollmentStatus?.title !== 'none' && !isSiteClosed ? (
              <Grid item xs="auto">
                <Button
                  size="small"
                  disabled={!canChangeStatus}
                  style={{ margin: '-4px -5px' }}
                  onClick={onChangeStatusClick}>
                  Change status
                </Button>
                <Menu
                  keepMounted
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={onChangeStatusMenuClose}>
                  {availableStatus
                    .filter((status) => household.enrollmentStatus?.title !== status)
                    .map((status) => (
                      <MenuItem key={status} onClick={() => onChangeStatusMenuClick(status)}>
                        <ListItemText
                          primary={status.toUpperCase()}
                          primaryTypographyProps={{ variant: 'body2' }}
                        />
                      </MenuItem>
                    ))}
                </Menu>
              </Grid>
            ) : null}
          </Grid>
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Enrolled At">
          {household.enrolledAt ? humanizeIsoDate(household.enrolledAt) : 'N/A'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Created At">
          {humanizeIsoDate(household.createdAt)}
        </DashItemText>
      </Grid>
      {currentActionResult ? (
        <QueryResultToast result={currentActionResult}>
          {'Household status has been updated successfully.'}
        </QueryResultToast>
      ) : null}
    </Box>
  );
};

const HouseholdAssets: React.FC<{ household: Household }> = ({ household }) => {
  const tabs = ['members', 'vehicles'];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const onTabChange: DashItemTabProps['onTabChange'] = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <DashItemTab title="Assets" tabs={tabs} currentTab={currentTab} onTabChange={onTabChange}>
      <DashItemTabPanel forTab={tabs[0]} currentTab={currentTab}>
        <MemberTable filter={{ household: household.id }} />
      </DashItemTabPanel>
      <DashItemTabPanel forTab={tabs[1]} currentTab={currentTab}>
        <VehicleTable filter={{ household: household.id }} />
      </DashItemTabPanel>
    </DashItemTab>
  );
};

const HouseholdDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: household, isError } = useHousehold(id, { enabled: Boolean(id) });

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Link
            color="inherit"
            variant="overline"
            component={RouterLink}
            to={DASHBOARD_HOUSEHOLD_LIST}>
            Households
          </Link>
          <Typography color="textPrimary" variant="overline">
            {id}
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          Household Details
        </Typography>
      </DashboardHeader>
      <LoadingOrFail isLoading={!household} isError={isError}>
        {household ? (
          <>
            <HouseholdBasicInfo household={household} />
            <HouseholdAssets household={household} />
          </>
        ) : null}
      </LoadingOrFail>
    </DashboardLayout>
  );
};

export default HouseholdDetails;
