import { closeOutline } from 'ionicons/icons';
import React from 'react';

import ToolbarLayout, { ToolbarLayoutProps } from './ToolbarLayout';

export interface ModalLayoutProps extends ToolbarLayoutProps {
  onDismiss?: () => void;
}

const ModalLayout: React.FC<ModalLayoutProps> = ({ children, buttons, onDismiss, ...props }) => {
  const back = {
    slot: 'start',
    color: 'light',
    icon: closeOutline,
    onClick: onDismiss,
  };

  return (
    <ToolbarLayout buttons={[back, ...(buttons ?? [])]} {...props}>
      {children}
    </ToolbarLayout>
  );
};

export default ModalLayout;
