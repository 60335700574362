import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  parseISO,
} from 'date-fns';

import { pad } from './string';

export function humanizeIsoDate(date: string | null) {
  if (!date) {
    return '';
  }
  return format(parseISO(date), 'yyyy-MM-dd HH:mm:ss');
}

export function formatDuration(start: Date, end: Date) {
  let temp = new Date(start.getTime());
  const days = differenceInDays(end, temp);
  temp = addDays(temp, days);
  const hours = differenceInHours(end, temp);
  temp = addHours(temp, hours);
  const minutes = differenceInMinutes(end, temp);
  temp = addMinutes(temp, minutes);
  let value = [pad(hours, 2), pad(minutes, 2)].join(':');

  if (days > 0) {
    value = [days, value].join(':');
  }
  return value;
}
