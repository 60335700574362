import { IonAlert, IonButton, IonList, IonPage } from '@ionic/react';
import { isEmail } from 'class-validator';
import { mailOutline, removeCircleOutline, trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { isHouseholdReadOnly, useSelfHousehold } from '../../api/household';
import {
  Member,
  UNNAMED_MEMBER,
  useMemberDelete,
  useMembers,
  useRemoveMemberInvite,
  useSelfMember,
  useSendMemberInvite,
} from '../../api/member';
import { useSelfUser } from '../../api/user';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import InfiniteScroll from '../../components/containers/InfiniteScroll';
import AddonButton from '../../components/forms/AddonButton';
import ItemText from '../../components/forms/ItemText';
import FormLayout from '../../components/layouts/FormLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import { APP_RECRUITMENT_MEMBER } from '../../urls';

const MemberSummary: React.FC = () => {
  const history = useHistory();
  const { data: user } = useSelfUser();
  const { data: self } = useSelfMember();
  const { data: household } = useSelfHousehold();
  const { data, fetchMore, canFetchMore } = useMembers(household?.id);
  const [deleteMember, resDeleteMember] = useMemberDelete();
  const [sendInvite, resSendInvite] = useSendMemberInvite();
  const [removeInvite, resRemoveInvite] = useRemoveMemberInvite();

  const [currentMember, setCurrentMember] = useState<Member>();
  const [inviteEmail, setInviteEmail] = useState<string>();

  const [isInviteDialogVisible, setIsInviteDialogVisible] = useState(false);
  const [isDisinviteDialogVisible, setIsDisinviteDialogVisible] = useState(false);
  const [isMemberDelDialogVisible, setIsMemberDelDialogVisible] = useState(false);
  const [isInvalidEmailDialogVisible, setIsInvalidEmailDialogVisible] = useState(false);

  const isHouseholdOwner = user?.householdOwnedId && user?.householdOwnedId === household?.id;
  const isReadOnly = !isHouseholdOwner || isHouseholdReadOnly(household);

  const onInviteClick = (e: React.MouseEvent, member: Member) => {
    e.stopPropagation();
    setCurrentMember(member);
    setIsInviteDialogVisible(true);
  };

  const onCancelInvite = () => {
    setIsInviteDialogVisible(false);
  };

  const onSendInviteClick = ({ email }: { email: string }) => {
    setIsInviteDialogVisible(false);
    setInviteEmail(email);
    if (currentMember) {
      if (isEmail(email)) {
        sendInvite({ id: currentMember?.id, email });
      } else {
        setIsInvalidEmailDialogVisible(true);
      }
    }
  };

  const onDisinviteClick = (e: React.MouseEvent, member: Member) => {
    e.stopPropagation();
    setCurrentMember(member);
    setIsDisinviteDialogVisible(true);
  };

  const onDisinviteDialogDismiss = () => {
    setIsDisinviteDialogVisible(false);
  };

  const onRemoveInviteClick = () => {
    setIsDisinviteDialogVisible(false);
    if (currentMember) {
      removeInvite(currentMember.id);
    }
  };

  const onDeleteClick = (e: React.MouseEvent, member: Member) => {
    e.stopPropagation();
    setCurrentMember(member);
    setIsMemberDelDialogVisible(true);
  };

  const onDeleteMemberClick = () => {
    if (currentMember?.id) {
      deleteMember(currentMember.id);
    }
  };

  const onMemberDelDialogDismiss = () => {
    setIsMemberDelDialogVisible(false);
  };

  const bucket = new Set();
  const members = (Array.isArray(data) ? data : [])
    .map((page) => page.items)
    .flat()
    .filter((member) => member.id !== self?.id)
    .filter((member) => (bucket.has(member.id) ? false : (bucket.add(member.id), true)));
  if (self) {
    members.unshift(self);
  }

  return (
    <IonPage>
      <FormLayout
        title="Member Summary"
        subtitle={
          members.length
            ? `Tap on a person to ${isReadOnly ? 'view' : 'edit'}.`
            : 'No member found.'
        }>
        <IonList className="input-group readonly">
          {members.map((member) => {
            const isSelfMember = member.id === self?.id;
            const canHaveInvite = !isSelfMember && isHouseholdOwner && !member.userId;
            const name = member.name ? member.name : UNNAMED_MEMBER;
            const relationship = isSelfMember ? 'Self' : member.relationship?.title ?? 'Member';
            const target = APP_RECRUITMENT_MEMBER.replace(':id?', member.id);

            return (
              <ItemText
                key={member.id}
                title={name}
                subtitle={relationship}
                onClick={() => history.push(target)}>
                {canHaveInvite && !member.invite?.id ? (
                  <AddonButton
                    color="primary"
                    title="Invite"
                    icon={mailOutline}
                    onClick={(e) => onInviteClick(e, member)}
                  />
                ) : null}
                {canHaveInvite && member.invite?.id ? (
                  <AddonButton
                    color="danger"
                    title="Disinvite"
                    icon={removeCircleOutline}
                    onClick={(e) => onDisinviteClick(e, member)}
                  />
                ) : null}
                {!isReadOnly && !isSelfMember ? (
                  <AddonButton
                    color="danger"
                    icon={trashOutline}
                    onClick={(e) => onDeleteClick(e, member)}
                  />
                ) : null}
              </ItemText>
            );
          })}
        </IonList>
        <InfiniteScroll
          disabled={!canFetchMore}
          onIonInfinite={async (e: any) => {
            await fetchMore();
            e.target.complete();
          }}
        />
      </FormLayout>
      {!isReadOnly ? (
        <ButtonSheet>
          <IonButton
            shape="round"
            expand="block"
            routerLink={APP_RECRUITMENT_MEMBER.replace(':id?', '')}>
            Add another member
          </IonButton>
        </ButtonSheet>
      ) : null}
      <IonAlert
        header="Send Invite"
        message={`Invite another user to join your household as 
          ${currentMember?.name ?? UNNAMED_MEMBER}.`}
        isOpen={isInviteDialogVisible}
        onDidDismiss={onCancelInvite}
        inputs={[
          {
            name: 'email',
            type: 'email',
            placeholder: 'Email',
          },
        ]}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: onCancelInvite,
          },
          {
            text: 'Ok',
            handler: onSendInviteClick,
          },
        ]}
      />
      <IonAlert
        isOpen={isInvalidEmailDialogVisible}
        onDidDismiss={() => setIsInvalidEmailDialogVisible(false)}
        message={`${inviteEmail} is not a valid email address. Please try again.`}
        buttons={['OK']}
      />
      <IonAlert
        isOpen={isDisinviteDialogVisible}
        onDidDismiss={onDisinviteDialogDismiss}
        header="Disinvite member?"
        message={`Do you want to remove invite for member ${
          currentMember?.name ?? UNNAMED_MEMBER
        }?`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: onDisinviteDialogDismiss,
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: onRemoveInviteClick,
          },
        ]}
      />
      <IonAlert
        isOpen={isMemberDelDialogVisible}
        onDidDismiss={onMemberDelDialogDismiss}
        header="Delete member?"
        message={`Do you want to remove member ${currentMember?.name ?? UNNAMED_MEMBER}? 
          This will also remove corresponding invited user from your household.`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: onMemberDelDialogDismiss,
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: onDeleteMemberClick,
          },
        ]}
      />
      <QueryResultToast result={resDeleteMember}>
        {`Member ${currentMember?.name ?? UNNAMED_MEMBER} has been removed.`}
      </QueryResultToast>
      <QueryResultToast result={resSendInvite}>
        {`The invite has been sent to ${inviteEmail}`}
      </QueryResultToast>
      <QueryResultToast result={resRemoveInvite}>
        {`The invite has been removed for ${currentMember?.name ?? UNNAMED_MEMBER}.`}
      </QueryResultToast>
    </IonPage>
  );
};

export default MemberSummary;
