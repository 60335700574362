import { ScrollDetail } from '@ionic/core';
import { IonButton, IonContent, IonFabButton, IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import FabButtonGroup from '../buttons/FabButtonGroup';
import IconButtonGroup from '../buttons/IconButtonGroup';
import Subtitle from '../typography/Subtitle';
import Title from '../typography/Title';

type IonButtonProps = Parameters<typeof IonButton>[0];
type IonFabButtonProps = Parameters<typeof IonFabButton>[0];
type ToolbarButtonProps = IonButtonProps & IonFabButtonProps;

export interface ToolbarButton extends ToolbarButtonProps {
  icon?: string;
}

export interface ToolbarLayoutProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  buttons?: ToolbarButton[];
  className?: string;
}

interface ToolbarStyleProps {
  opaque: boolean;
  visible: boolean;
}

const useStyles = makeStyles({
  toolbar: {
    transition: '.2s ease opacity',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05), 0 0 1px rgba(0, 0, 0, 0.2)',
    opacity: ({ opaque }: ToolbarStyleProps) => (opaque ? 1 : 0),
    visibility: ({ visible }: ToolbarStyleProps) => (visible ? 'visible' : 'hidden'),
  },
});

const ToolbarLayout: React.FC<ToolbarLayoutProps> = (props) => {
  const { title, subtitle, buttons, className, children } = props;

  const titleRef = useRef<HTMLHeadingElement>(null);
  const [isToolbarOpaque, setIsToolbarOpaque] = useState(false);
  const [isToolbarVisible, setIsToolbarVisible] = useState(false);

  const classes = useStyles({ opaque: isToolbarOpaque, visible: isToolbarVisible });

  useEffect(() => {
    if (isToolbarOpaque) {
      setIsToolbarVisible(true);
    } else {
      const timer = setTimeout(() => setIsToolbarVisible(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isToolbarOpaque]);

  const onScroll = (event: CustomEvent<ScrollDetail>) => {
    if (!titleRef.current || event.detail.scrollTop >= titleRef.current.offsetTop) {
      setIsToolbarOpaque(true);
    } else {
      setIsToolbarOpaque(false);
    }
  };

  const startButtonGroup = buttons?.filter((button) => button.slot === 'start') ?? [];
  const endButtonGroup = buttons?.filter((button) => button.slot === 'end') ?? [];

  return (
    <>
      <IonHeader className={['ion-no-border', classes.toolbar].join(' ')}>
        <IonToolbar>
          {startButtonGroup.length ? (
            <IconButtonGroup slot="start" buttons={startButtonGroup} />
          ) : null}
          {title ? <IonTitle>{title}</IonTitle> : null}
          {endButtonGroup.length ? <IconButtonGroup slot="end" buttons={endButtonGroup} /> : null}
        </IonToolbar>
      </IonHeader>
      <IonContent
        scrollEvents
        onIonScroll={onScroll}
        className={['display', 'toolbar', className].filter(Boolean).join(' ')}>
        {startButtonGroup.length ? (
          <FabButtonGroup
            vertical="top"
            horizontal="start"
            className="fab-group flat"
            buttons={startButtonGroup}
          />
        ) : null}
        {endButtonGroup.length ? (
          <FabButtonGroup
            vertical="top"
            horizontal="end"
            className="fab-group flat"
            buttons={endButtonGroup}
          />
        ) : null}
        {title ? <Title ref={titleRef}>{title}</Title> : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {children}
      </IonContent>
    </>
  );
};

export default ToolbarLayout;
