import { useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    transition: 'opacity 0.75s ease',
    '&.enter': {
      opacity: '1',
    },
    '&.leave': {
      opacity: '0',
    },
  },
  inner: {
    paddingTop: 'calc(var(--ion-padding) * 2)',
    paddingLeft: 'calc(var(--ion-padding) * 2 + var(--ion-safe-area-left, 0))',
    paddingRight: 'calc(var(--ion-padding) * 2 + var(--ion-safe-area-right, 0))',
    paddingBottom: 'calc(var(--ion-padding) * 2 + var(--ion-safe-area-bottom, 0))',
    background:
      'linear-gradient(to bottom, rgba(var(--ion-background-color-rgb), 0) 0, ' +
      'var(--ion-background-color) calc(var(--ion-padding) * 2)) no-repeat',
  },
});

export interface ButtonSheetProps {
  initialVisibility?: boolean;
}

// FIXME: there is a bug that the sheet will overlap scrollable area in firefox
// see https://stackoverflow.com/questions/29986977/firefox-ignores-padding-when-using-overflowscroll
const ButtonSheet: React.FC<ButtonSheetProps> = ({ initialVisibility = false, children }) => {
  const classes = useStyles();
  const [visibleState, setVisibleState] = useState(initialVisibility ? 'enter' : 'leave');

  useIonViewWillEnter(() => {
    setVisibleState('enter');
  });

  useIonViewWillLeave(() => {
    setVisibleState('leave');
  });

  return (
    <div className={[classes.container, visibleState].join(' ').trim()}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export default ButtonSheet;
