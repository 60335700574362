import { MenuList, makeStyles } from '@material-ui/core';
import { ColDef, FilterMenuItem, SortMenuItems } from '@material-ui/data-grid';
import React, { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiMenuItem-root': {
      ...theme.typography.body2,
    },
  },
}));

export interface ColumnMenuProps {
  hideMenu: () => void;
  currentColumn: ColDef;
}

const ColumnMenu: React.FC<ColumnMenuProps> = ({ hideMenu, currentColumn }) => {
  const classes = useStyles();

  const onListKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        hideMenu();
      }
    },
    [hideMenu],
  );

  return (
    <MenuList className={classes.menu} onKeyDown={onListKeyDown}>
      <SortMenuItems onClick={hideMenu} column={currentColumn!} />
      <FilterMenuItem onClick={hideMenu} column={currentColumn!} />
    </MenuList>
  );
};

export default ColumnMenu;
