import { FeatureMode, PageChangeParams } from '@material-ui/data-grid';
import { useState } from 'react';

import { PaginationOptions } from '../../api/common';

interface PaginationProps {
  paginationMode: FeatureMode;
  page: number;
  pageSize: number;
  rowsPerPageOptions: number[];
  onPageChange: (param: PageChangeParams) => void;
  onPageSizeChange: (param: PageChangeParams) => void;
}

export default function usePagination(
  defaultPageSizes: number[] = [20, 50, 100],
): [PaginationOptions, PaginationProps] {
  const [pagination, setPagination] = useState<PaginationOptions>({
    page: 1,
    limit: defaultPageSizes[0],
  });

  const onPaginationChange = (params: PageChangeParams) => {
    setPagination({ page: params.page + 1, limit: params.pageSize });
  };

  const props = {
    paginationMode: 'server' as FeatureMode,
    page: pagination.page - 1,
    pageSize: pagination.limit,
    rowsPerPageOptions: defaultPageSizes,
    onPageChange: onPaginationChange,
    onPageSizeChange: onPaginationChange,
  };

  return [pagination, props];
}
