import { Coords } from 'google-map-react';
import React from 'react';

export interface MapMarkerProps extends Coords {
  type?: 'current' | 'start' | 'end';
}

const MapMarker: React.FC<MapMarkerProps> = ({ type = 'current' }) => {
  return <span className={['map-marker', type].join(' ')} />;
};

export default MapMarker;
