import { IonInput, IonItem, IonLabel } from '@ionic/react';
import React from 'react';

export interface ItemTextProps {
  title: string;
  subtitle?: string;
  disabled?: boolean;
  color?: string;
  routerLink?: string;
  onClick?: (event: React.MouseEvent<HTMLIonItemElement>) => void;
}

const ItemText: React.FC<ItemTextProps> = (props) => {
  const { title, subtitle, disabled, color, onClick, routerLink, children } = props;

  return (
    <IonItem
      button
      className="item-text item-button readonly"
      onClick={onClick}
      disabled={disabled}
      routerLink={routerLink}>
      {subtitle ? <IonLabel position="stacked">{subtitle}</IonLabel> : null}
      <IonInput value={title} color={color} readonly />
      {children}
    </IonItem>
  );
};

export default ItemText;
