import { createBrowserHistory } from 'history';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';

import { useToken } from '../../api/auth';
import { useSelfUser } from '../../api/user';
import Index from '../../pages/Index';
import AddressDetails from '../../pages/dashboard/AddressDetails';
import AddressList from '../../pages/dashboard/AddressList';
import FeedbackDetails from '../../pages/dashboard/FeedbackDetails';
import FeedbackList from '../../pages/dashboard/FeedbackList';
import HouseholdDetails from '../../pages/dashboard/HouseholdDetails';
import HouseholdList from '../../pages/dashboard/HouseholdList';
import MemberDetails from '../../pages/dashboard/MemberDetails';
import MemberList from '../../pages/dashboard/MemberList';
import Settings from '../../pages/dashboard/Settings';
import SignIn from '../../pages/dashboard/SignIn';
import TripDetails from '../../pages/dashboard/TripDetails';
import TripList from '../../pages/dashboard/TripList';
import UserDetails from '../../pages/dashboard/UserDetails';
import UserList from '../../pages/dashboard/UserList';
import VehicleDetails from '../../pages/dashboard/VehicleDetails';
import VehicleList from '../../pages/dashboard/VehicleList';
import AcceptableUsePolicy from '../../pages/landing/AcceptableUsePolicy';
import EmailVerification from '../../pages/landing/EmailVerification';
import Faq from '../../pages/landing/Faq';
import MemberInvite from '../../pages/landing/MemberInvite';
import PrivacyPolicy from '../../pages/landing/PrivacyPolicy';
import TermsOfService from '../../pages/landing/TermsOfService';
import {
  DASHBOARD,
  DASHBOARD_ADDRESS,
  DASHBOARD_ADDRESS_LIST,
  DASHBOARD_FEEDBACK,
  DASHBOARD_FEEDBACK_LIST,
  DASHBOARD_HOUSEHOLD,
  DASHBOARD_HOUSEHOLD_LIST,
  DASHBOARD_MEMBER,
  DASHBOARD_MEMBER_LIST,
  DASHBOARD_SETTINGS,
  DASHBOARD_SIGNIN,
  DASHBOARD_TRIP,
  DASHBOARD_TRIP_LIST,
  DASHBOARD_USER,
  DASHBOARD_USER_LIST,
  DASHBOARD_VEHICLE,
  DASHBOARD_VEHICLE_LIST,
  LANDING_ACCEPTABLE_USE_POLICY,
  LANDING_EMAIL_VERIFY,
  LANDING_FAQ,
  LANDING_MEMBER_INVITE,
  LANDING_PRIVACY_POLICY,
  LANDING_TERMS_OF_SERVICE,
} from '../../urls';

const history = createBrowserHistory();

const ServerRouter: React.FC = () => {
  const { data: token, isLoading } = useToken();
  const { data: user } = useSelfUser({ enabled: Boolean(token) });

  if (isLoading) {
    return null;
  }

  if (!user || user?.role?.title !== 'admin') {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path={DASHBOARD_SIGNIN} component={SignIn} />
          <Route exact path={LANDING_FAQ} component={Faq} />
          <Route exact path={LANDING_TERMS_OF_SERVICE} component={TermsOfService} />
          <Route exact path={LANDING_ACCEPTABLE_USE_POLICY} component={AcceptableUsePolicy} />
          <Route exact path={LANDING_PRIVACY_POLICY} component={PrivacyPolicy} />
          <Route exact path={LANDING_EMAIL_VERIFY} component={EmailVerification} />
          <Route exact path={LANDING_MEMBER_INVITE} component={MemberInvite} />
          <Redirect from={DASHBOARD} to={DASHBOARD_SIGNIN} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <Route exact path={DASHBOARD_USER_LIST} component={UserList} />
        <Route exact path={DASHBOARD_USER} component={UserDetails} />
        <Route exact path={DASHBOARD_HOUSEHOLD_LIST} component={HouseholdList} />
        <Route exact path={DASHBOARD_HOUSEHOLD} component={HouseholdDetails} />
        <Route exact path={DASHBOARD_MEMBER_LIST} component={MemberList} />
        <Route exact path={DASHBOARD_MEMBER} component={MemberDetails} />
        <Route exact path={DASHBOARD_VEHICLE_LIST} component={VehicleList} />
        <Route exact path={DASHBOARD_VEHICLE} component={VehicleDetails} />
        <Route exact path={DASHBOARD_TRIP_LIST} component={TripList} />
        <Route exact path={DASHBOARD_TRIP} component={TripDetails} />
        <Route exact path={DASHBOARD_ADDRESS_LIST} component={AddressList} />
        <Route exact path={DASHBOARD_ADDRESS} component={AddressDetails} />
        <Route exact path={DASHBOARD_FEEDBACK_LIST} component={FeedbackList} />
        <Route exact path={DASHBOARD_FEEDBACK} component={FeedbackDetails} />
        <Route exact path={DASHBOARD_SETTINGS} component={Settings} />
        <Route exact path={LANDING_TERMS_OF_SERVICE} component={TermsOfService} />
        <Route exact path={LANDING_ACCEPTABLE_USE_POLICY} component={AcceptableUsePolicy} />
        <Route exact path={LANDING_PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route exact path={LANDING_EMAIL_VERIFY} component={EmailVerification} />
        <Route exact path={LANDING_MEMBER_INVITE} component={MemberInvite} />
        <Route render={() => <Redirect to={DASHBOARD_USER_LIST} />} />
      </Switch>
    </Router>
  );
};

export default ServerRouter;
