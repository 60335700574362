import { InputChangeEventDetail } from '@ionic/core';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRouterLink,
  IonToast,
} from '@ionic/react';
import { Typography, makeStyles } from '@material-ui/core';
import { AxiosError } from 'axios';
import { isEmail } from 'class-validator';
import { lockClosedOutline, mailOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { QueryStatus } from 'react-query';
import { useHistory } from 'react-router';

import { useSignIn } from '../../api/auth';
import HelperText from '../../components/forms/HelperText';
import IonInput from '../../components/forms/patch/IonInput';
import Subtitle from '../../components/typography/Subtitle';
import Title from '../../components/typography/Title';
import { APP_HOME, APP_PASSWORD_REST, APP_SIGNUP } from '../../urls';
import { getErrorMessage } from '../../utils/error';

const useStyles = makeStyles({
  container: {
    '&::part(background)': {
      background:
        'url(/assets/images/app/fogg-kayaking.svg) center calc(100% + 25px) / 400px no-repeat',
    },
  },
});

const SignIn: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signIn, { status, error, reset }] = useSignIn({
    onSuccess: () => history.replace(APP_HOME),
  });

  const onEmailChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setEmail(e.detail.value!);
  };

  const onPasswordChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setPassword(e.detail.value!);
  };

  const onSubmit = () => signIn({ email, password });

  const isEmailValid = isEmail(email);
  const isLoading = status === QueryStatus.Loading;
  const canSubmit = !isLoading && !!email && isEmailValid && !!password;

  let toastText = '';
  let toastType = '';
  let isToastVisible = false;

  if (status === QueryStatus.Error) {
    isToastVisible = true;
    toastType = 'danger';
    const response = (error as AxiosError).response;
    if (response?.status === 401) {
      toastText = 'Failed to sign in. Please check if email and password are correct.';
    } else {
      toastText = getErrorMessage(error);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen className={['display', classes.container].join(' ')}>
        <Title>Welcome Back!</Title>
        <Subtitle>Sign in with your email to continue.</Subtitle>
        <IonList>
          <IonItem className="ion-margin-top">
            <IonIcon icon={mailOutline} slot="start" />
            <IonInput type="email" placeholder="Email" value={email} onIonChange={onEmailChange} />
          </IonItem>
          {email && !isEmailValid ? (
            <HelperText color="error">Email address is invalid</HelperText>
          ) : null}
          <IonItem className="ion-margin-top">
            <IonIcon icon={lockClosedOutline} slot="start" />
            <IonInput
              type="password"
              placeholder="Password"
              value={password}
              onIonChange={onPasswordChange}
            />
          </IonItem>
          <HelperText align="right">
            <IonRouterLink routerLink={APP_PASSWORD_REST}>Forget password?</IonRouterLink>
          </HelperText>
        </IonList>
        <IonButton
          shape="round"
          expand="block"
          onClick={onSubmit}
          disabled={!canSubmit}
          className="ion-no-margin ion-margin-top ion-margin-bottom">
          {status === QueryStatus.Loading ? 'Loading...' : 'Sign In'}
        </IonButton>
        <Typography variant="body2" align="center">
          {"Don't have an account? "}
          <IonRouterLink routerLink={APP_SIGNUP}>Sign up</IonRouterLink>.
        </Typography>
      </IonContent>
      <IonToast
        duration={2000}
        color={toastType}
        message={toastText}
        isOpen={isToastVisible}
        onDidDismiss={() => reset()}
      />
    </IonPage>
  );
};

export default SignIn;
