import { Box, Breadcrumbs, Button, Grid, Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { useMember } from '../../api/member';
import { useIsSiteClosed } from '../../api/setting';
import {
  User,
  isUserDisabled,
  useSelfUser,
  useUser,
  useUserDisable,
  useUserEnable,
} from '../../api/user';
import LoadingOrFail from '../../components/containers/LoadingOrFail';
import DashItemTab, { DashItemTabProps } from '../../components/forms/DashItemTab';
import DashItemTabPanel from '../../components/forms/DashItemTabPanel';
import DashItemText from '../../components/forms/DashItemText';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import AddressTable from '../../components/tables/AddressTable';
import FeedbackTable from '../../components/tables/FeedbackTable';
import TripTable from '../../components/tables/TripTable';
import DashboardHeader from '../../components/typography/DashboardHeader';
import { DASHBOARD_HOUSEHOLD, DASHBOARD_MEMBER, DASHBOARD_USER_LIST } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const UserBasicInfo: React.FC<{ user: User }> = ({ user }) => {
  const isSiteClosed = useIsSiteClosed();
  const { data: selfUser } = useSelfUser();
  const { data: member } = useMember(user?.memberLinkedId ?? '', {
    enabled: Boolean(user?.memberLinkedId),
  });

  const [enableUser, resEnable] = useUserEnable();
  const [disableUser, resDisable] = useUserDisable();

  const onEnableOrDisableUserClick = () => {
    if (isEnablingUser) {
      enableUser(user.id);
    } else {
      disableUser(user.id);
    }
  };

  const isEnablingUser = isUserDisabled(user);
  const username = [user?.profile.firstName, user?.profile.lastName].filter(Boolean).join(' ');

  return (
    <Box mb={4}>
      <Grid container spacing={2}>
        <DashItemText xs={12} md={6} title="ID">
          {user.id}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Email">
          {user.email}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Name">
          {username ? username : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Phone Number">
          {user.profile.phoneNumber ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Role">
          {user.role?.title.toUpperCase()}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Verified At">
          {user.verifiedAt ? humanizeIsoDate(user.verifiedAt) : 'N/A'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Disabled At">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs="auto">
              {user.disabledAt ? humanizeIsoDate(user.disabledAt) : 'N/A'}
            </Grid>
            {user.id !== selfUser?.id && !isSiteClosed ? (
              <Grid item xs="auto">
                <Button
                  size="small"
                  style={{ margin: '-4px -5px' }}
                  onClick={onEnableOrDisableUserClick}>
                  {user.disabledAt ? 'Enable' : 'Disable'}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Household Owner">
          {Boolean(user.householdOwnedId) ? 'Yes' : 'No'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Associated Household">
          {member?.householdId ? (
            <Link
              component={RouterLink}
              to={DASHBOARD_HOUSEHOLD.replace(':id', member.householdId)}>
              {member.householdId}
            </Link>
          ) : (
            'N/A'
          )}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Associated Member">
          {user.memberLinkedId ? (
            <Link component={RouterLink} to={DASHBOARD_MEMBER.replace(':id', user.memberLinkedId)}>
              {user.memberLinkedId}
            </Link>
          ) : (
            'N/A'
          )}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Referral Code">
          {user.referrer ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Created At">
          {humanizeIsoDate(user.createdAt)}
        </DashItemText>
      </Grid>
      <QueryResultToast result={isEnablingUser ? resEnable : resDisable}>
        {'User successfully ' + (isEnablingUser ? 'enabled' : 'disabled') + '.'}
      </QueryResultToast>
    </Box>
  );
};

const UserAssets: React.FC<{ user: User }> = ({ user }) => {
  const tabs = ['trips', 'saved addresses', 'feedback'];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const onTabChange: DashItemTabProps['onTabChange'] = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <DashItemTab title="Assets" tabs={tabs} currentTab={currentTab} onTabChange={onTabChange}>
      <DashItemTabPanel forTab={tabs[0]} currentTab={currentTab}>
        <TripTable filter={{ user: user.id }} />
      </DashItemTabPanel>
      <DashItemTabPanel forTab={tabs[1]} currentTab={currentTab}>
        <AddressTable filter={{ user: user.id }} />
      </DashItemTabPanel>
      <DashItemTabPanel forTab={tabs[2]} currentTab={currentTab}>
        <FeedbackTable filter={{ user: user.id }} />
      </DashItemTabPanel>
    </DashItemTab>
  );
};

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: user, isError } = useUser(id, { enabled: Boolean(id) });

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Link color="inherit" variant="overline" component={RouterLink} to={DASHBOARD_USER_LIST}>
            Users
          </Link>
          <Typography color="textPrimary" variant="overline">
            {id}
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          User Details
        </Typography>
      </DashboardHeader>
      <LoadingOrFail isLoading={!user} isError={isError}>
        {user ? (
          <>
            <UserBasicInfo user={user} />
            <UserAssets user={user} />
          </>
        ) : null}
      </LoadingOrFail>
    </DashboardLayout>
  );
};

export default UserDetails;
