import { IonCard, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { homeOutline } from 'ionicons/icons';
import React from 'react';
import { QueryStatus } from 'react-query';

import { useSelfHousehold } from '../../api/household';
import { useIsSurveyExpired } from '../../api/setting';
import { isValidUser, useSelfUser } from '../../api/user';
import {
  APP_RECRUITMENT_STATUS,
  APP_RECRUITMENT_SUMMARY,
  APP_RECRUITMENT_WELCOME,
} from '../../urls';

const RecruitmentStatusCard: React.FC = () => {
  const { data: user } = useSelfUser();
  const isSurveyExpired = useIsSurveyExpired({ enabled: isValidUser(user) });
  const { data: household, status, refetch } = useSelfHousehold({ enabled: isValidUser(user) });

  let target;
  let color = 'success';
  let title = 'Start your recruitment survey';
  let nextPage = APP_RECRUITMENT_WELCOME;

  switch (status) {
    case QueryStatus.Error:
      color = 'danger';
      title = 'Failed to load recruitment survey';
      target = { onClick: () => refetch?.() };
      break;
    case QueryStatus.Loading:
      color = 'medium';
      title = 'Loading recruitment status...';
      break;
    default:
      switch (household?.enrollmentStatus?.title) {
        case 'none':
          color = 'primary';
          title = 'Complete your recruitment survey';
          nextPage = APP_RECRUITMENT_SUMMARY;
          break;
        case 'submitted':
          color = 'tertiary';
          title = 'Recruitment survey submitted';
          nextPage = APP_RECRUITMENT_STATUS;
          break;
        case 'reviewing':
          color = 'warning';
          title = 'Recruitment survey under reivew';
          nextPage = APP_RECRUITMENT_STATUS;
          break;
        case 'accepted':
          if (!isSurveyExpired) {
            color = 'success';
            title = 'Your are successfully enrolled';
          } else {
            color = 'medium';
            title = 'Your survey is closed';
          }
          nextPage = APP_RECRUITMENT_STATUS;
          break;
        case 'rejected':
          color = 'danger';
          title = 'Recruitment survey rejected';
          nextPage = APP_RECRUITMENT_STATUS;
          break;
        default:
          color = 'primary';
          title = 'Start your recruitment survey';
          nextPage = APP_RECRUITMENT_WELCOME;
          break;
      }
      target = { routerLink: nextPage };
      break;
  }

  return (
    <IonCard {...target}>
      <IonItem button detail lines="none" color={color}>
        <IonIcon icon={homeOutline} slot="start" style={{ marginRight: 'var(--ion-margin)' }} />
        <IonLabel>{title}</IonLabel>
      </IonItem>
    </IonCard>
  );
};

export default RecruitmentStatusCard;
