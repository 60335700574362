import { InputChangeEventDetail, SelectChangeEventDetail } from '@ionic/core';
import { IonAlert, IonButton, IonList, IonPage } from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { isHouseholdReadOnly, useSelfHousehold } from '../../api/household';
import { Member, UNNAMED_MEMBER, useMember } from '../../api/member';
import { useSelfUser } from '../../api/user';
import {
  START_YEAR,
  useColors,
  useFuels,
  useModels,
  useTypes,
  useVehicle,
  useVehicleCreate,
  useVehicleUpdate,
} from '../../api/vehicle';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import AddonButton from '../../components/forms/AddonButton';
import BinarySelect from '../../components/forms/BinarySelect';
import ItemInput from '../../components/forms/ItemInput';
import KvSelect from '../../components/forms/KvSelect';
import LoadableKvSelect from '../../components/forms/LoadableKvSelect';
import FormLayout from '../../components/layouts/FormLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import { useEditState } from '../../utils/state';
import MemberSelect from '../common/MemberSelect';

const Vehicle: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isCreatingVehicle = !Boolean(id);

  const { data: types, status: typeStatus } = useTypes();
  const { data: colors, status: colorStatus } = useColors();
  const { data: fuels, status: fuelStatus } = useFuels();

  const [create, resCreate] = useVehicleCreate({ onSuccess: () => history.goBack() });
  const [update, resUpdate] = useVehicleUpdate({ onSuccess: () => history.goBack() });

  const { data: user } = useSelfUser();
  const { data: household } = useSelfHousehold();
  const { data: vehicle, ...resQuery } = useVehicle(id, {
    onSuccess: (vehicle_) => {
      if (!isModelEdited) {
        setYear(vehicle_?.model?.year);
        setMake(vehicle_?.model?.make);
        setModel(vehicle_?.model?.id);
      }
      setType(vehicle_?.type?.id, true);
      setColor(vehicle_?.color?.id, true);
      setFuel(vehicle_?.fuel?.id, true);
      setBoughtUsed(vehicle_?.boughtUsed, true);
      if (!isHouseholdDriverEdited) {
        setIsHouseholdDriver(vehicle_?.isHouseholdDriver);
        if (vehicle_?.isHouseholdDriver && vehicle_.householdDriverId) {
          setHouseholdDriverId(vehicle_?.householdDriverId);
        }
      }
      setPossessionTime(vehicle_?.possessionTime, true);
      setOdometerReading(vehicle_?.odometerReading, true);
      setAnnualMileage(vehicle_?.annualMileage, true);
    },
  });

  const [isMemberSelectVisible, setIsMemberSelectVisible] = useState<boolean>(false);
  const [isPossessionYearTipVisible, setIsPossessionYearTipVisible] = useState<boolean>(false);

  const [year, setYear] = useState(vehicle?.model?.year);
  const [make, setMake] = useState(vehicle?.model?.make);
  const [model, setModel, { edited: isModelEdited }] = useEditState(vehicle?.model?.id);
  const [type, setType] = useEditState(vehicle?.type?.id);
  const [color, setColor] = useEditState(vehicle?.color?.id);
  const [fuel, setFuel] = useEditState(vehicle?.fuel?.id);
  const [boughtUsed, setBoughtUsed] = useEditState(vehicle?.boughtUsed);
  const [possessionTime, setPossessionTime] = useEditState(vehicle?.possessionTime);
  const [odometerReading, setOdometerReading] = useEditState(vehicle?.odometerReading);
  const [annualMileage, setAnnualMileage] = useEditState(vehicle?.annualMileage);
  const [householdDriverId, setHouseholdDriverId] = useEditState(vehicle?.householdDriverId);
  const [
    isHouseholdDriver,
    setIsHouseholdDriver,
    { edited: isHouseholdDriverEdited },
  ] = useEditState(vehicle?.isHouseholdDriver);

  const { data: householdDriver } = useMember(householdDriverId ?? '', {
    enabled: isHouseholdDriver && Boolean(householdDriverId),
  });

  const { data: models = [], status: modelStatus } = useModels({ year });

  const onYearChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      if (!id || (typeof year !== 'undefined' && year !== value)) {
        setMake(undefined);
        setModel(undefined);
      }
      setYear(value);
    }
  };

  const onMakeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    const value = e.detail.value!;
    if (!id || (typeof make !== 'undefined' && make !== value)) {
      setModel(undefined);
    }
    setMake(value);
  };

  const onModelChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setModel(value);
    }
  };

  const onTypeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setType(e.detail.value!);
  };

  const onColorChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setColor(e.detail.value!);
  };

  const onFuelChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setFuel(e.detail.value!);
  };

  const onBoughtUsedChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setBoughtUsed(e.detail.value!);
  };

  const onDriverChange = (member?: Member) => {
    if (!member) {
      setIsHouseholdDriver(false);
      setHouseholdDriverId(undefined);
    } else {
      setIsHouseholdDriver(true);
      setHouseholdDriverId(member.id);
    }
  };

  const onPossessionTimeChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setPossessionTime(value);
    } else {
      setPossessionTime(null);
    }
  };

  const onOdometerReadingChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setOdometerReading(value);
    } else {
      setOdometerReading(null);
    }
  };

  const onAnnualMileageChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setAnnualMileage(value);
    } else {
      setAnnualMileage(null);
    }
  };

  const onSubmit = () => {
    const updates = {
      model,
      type,
      color,
      fuel,
      boughtUsed,
      isHouseholdDriver,
      householdDriver: householdDriverId,
      possessionTime,
      odometerReading,
      annualMileage,
    };
    if (isCreatingVehicle) {
      create(updates);
    } else if (vehicle) {
      update({ id: vehicle.id, ...updates });
    }
  };

  const isFormValid = Boolean(model);
  const isLoading =
    (resQuery.isInitialData && resQuery.isFetching) || resCreate.isLoading || resUpdate.isLoading;
  const isHouseholdOwner = user?.householdOwnedId && user?.householdOwnedId === household?.id;
  const isReadOnly = !isHouseholdOwner || isHouseholdReadOnly(household);
  const canSubmit = !isReadOnly && !isLoading && isFormValid;

  const title = 'Vehicle';
  const subtitle = isReadOnly
    ? 'View vehicle information'
    : 'Tell us about your household vehicle.';

  const driverName = (() => {
    if (isHouseholdDriver) {
      return householdDriver ? householdDriver.name ?? UNNAMED_MEMBER : 'Loading...';
    }
    return isHouseholdDriver === false ? 'Not household member' : undefined;
  })();

  const years = new Array(new Date().getUTCFullYear() - START_YEAR + 1)
    .fill(0)
    .map((_, i): [number, string] => [START_YEAR + i, `${START_YEAR + i}`])
    .reverse()
    .concat([[0, 'Other']]);

  const makes = Array.from(new Set(models.map((model) => model.make))).map((make): {
    id: string;
    title: string;
  } => ({ id: make, title: make }));

  const models_ = models
    .filter((model) => model.make === make)
    .map((model): { id: number; title: string } => ({ id: model.id, title: model.model }));

  return (
    <IonPage>
      <FormLayout title={title} subtitle={subtitle}>
        <IonList className="input-group">
          <KvSelect
            label="Year"
            required
            value={year}
            readonly={isReadOnly}
            entries={years}
            onChange={onYearChange}
          />
          <LoadableKvSelect
            label="Make"
            required
            value={make}
            entries={makes}
            readonly={isReadOnly || typeof year === 'undefined'}
            status={modelStatus}
            selectedText={typeof year === 'undefined' ? 'Please select year first' : undefined}
            onChange={onMakeChange}
          />
          <LoadableKvSelect
            label="Model"
            required
            value={model}
            entries={models_}
            readonly={isReadOnly || typeof make === 'undefined'}
            status={modelStatus}
            selectedText={typeof make === 'undefined' ? 'Please select make first' : undefined}
            onChange={onModelChange}
          />
          <LoadableKvSelect
            label="Type"
            value={type}
            entries={types}
            readonly={isReadOnly}
            status={typeStatus}
            onChange={onTypeChange}
          />
          <LoadableKvSelect
            label="Color"
            value={color}
            entries={colors}
            readonly={isReadOnly}
            status={colorStatus}
            onChange={onColorChange}
          />
          <LoadableKvSelect
            label="Fuel"
            value={fuel}
            entries={fuels}
            readonly={isReadOnly}
            status={fuelStatus}
            onChange={onFuelChange}
          />
          <BinarySelect
            label="Condition When Bought"
            value={boughtUsed}
            readonly={isReadOnly}
            entries={[
              ['no', 'New'],
              ['yes', 'Used'],
            ]}
            onChange={onBoughtUsedChange}
          />
          <ItemInput
            label="Driver"
            value={driverName}
            readonly
            onFocus={isReadOnly ? undefined : () => setIsMemberSelectVisible(true)}
          />
          <ItemInput
            type="number"
            label="Years Since Possession"
            value={possessionTime}
            readonly={isReadOnly}
            onChange={onPossessionTimeChange}
            addons={
              <AddonButton
                icon={helpCircleOutline}
                onClick={() => setIsPossessionYearTipVisible(true)}
              />
            }
          />
          <ItemInput
            type="number"
            label="Odometer Reading (Miles)"
            value={odometerReading}
            readonly={isReadOnly}
            onChange={onOdometerReadingChange}
          />
          <ItemInput
            type="number"
            label="Annual Mileage (Miles)"
            value={annualMileage}
            readonly={isReadOnly}
            onChange={onAnnualMileageChange}
          />
        </IonList>
      </FormLayout>
      {!isReadOnly ? (
        <ButtonSheet>
          <IonButton shape="round" expand="block" onClick={onSubmit} disabled={!canSubmit}>
            {isLoading ? 'Loading...' : 'Save'}
          </IonButton>
        </ButtonSheet>
      ) : null}
      <MemberSelect
        optional
        title="Driver"
        onSelect={onDriverChange}
        isOpen={isMemberSelectVisible}
        onDismiss={() => setIsMemberSelectVisible(false)}
      />
      <IonAlert
        isOpen={isPossessionYearTipVisible}
        onDidDismiss={() => setIsPossessionYearTipVisible(false)}
        message={`Enter the number of years your household had this vehicle.`}
        buttons={['OK']}
      />
      <QueryResultToast result={isCreatingVehicle ? resCreate : resUpdate}>
        Member information saved.
      </QueryResultToast>
    </IonPage>
  );
};

export default Vehicle;
