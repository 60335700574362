export const APP_HOME = '/home';
export const APP_SIGNIN = '/signin';
export const APP_SIGNUP = '/signup';
export const APP_PASSWORD_REST = '/password/reset';
export const APP_PROFILE = '/profile';
export const APP_SECURITY = '/security';
export const APP_RECRUITMENT_WELCOME = '/recruitment/welcome';
export const APP_RECRUITMENT_HOUSEHOLD = '/recruitment/household/:id?';
export const APP_RECRUITMENT_MEMBER = '/recruitment/member/:id?';
export const APP_RECRUITMENT_MEMBER_SUMMARY = '/recruitment/member/summary';
export const APP_RECRUITMENT_VEHICLE = '/recruitment/vehicle/:id?';
export const APP_RECRUITMENT_VEHICLE_SUMMARY = '/recruitment/vehicle/summary';
export const APP_RECRUITMENT_SUMMARY = '/recruitment/summary';
export const APP_RECRUITMENT_COMPLETE = '/recruitment/complete';
export const APP_RECRUITMENT_STATUS = '/recruitment/status';
export const APP_TRIP_LIST = '/trips';
export const APP_TRIP = '/trip/:id?';
export const APP_TRIP_MAP = '/trip/:id/map';
export const APP_TRIP_TRACKER = '/trip/tracker';
export const APP_SETTINGS = '/settings';
export const APP_ADDRESS_LIST = '/addresses';
export const APP_FEEDBACK = '/feedback';
export const APP_HELP = '/help';

export const DASHBOARD = '/dashboard';
export const DASHBOARD_SIGNIN = '/dashboard/signin';
export const DASHBOARD_USER_LIST = '/dashboard/users';
export const DASHBOARD_USER = '/dashboard/user/:id';
export const DASHBOARD_HOUSEHOLD_LIST = '/dashboard/households';
export const DASHBOARD_HOUSEHOLD = '/dashboard/household/:id';
export const DASHBOARD_MEMBER_LIST = '/dashboard/members';
export const DASHBOARD_MEMBER = '/dashboard/member/:id';
export const DASHBOARD_VEHICLE_LIST = '/dashboard/vehicles';
export const DASHBOARD_VEHICLE = '/dashboard/vehicle/:id';
export const DASHBOARD_TRIP_LIST = '/dashboard/trips';
export const DASHBOARD_TRIP = '/dashboard/trip/:id';
export const DASHBOARD_FEEDBACK_LIST = '/dashboard/feedback';
export const DASHBOARD_FEEDBACK = '/dashboard/feedback/:id';
export const DASHBOARD_ADDRESS_LIST = '/dashboard/addresses';
export const DASHBOARD_ADDRESS = '/dashboard/address/:id';
export const DASHBOARD_SETTINGS = '/dashboard/settings';
export const LANDING_FAQ = '/terms/faq';
export const LANDING_TERMS_OF_SERVICE = '/terms/terms-of-service';
export const LANDING_ACCEPTABLE_USE_POLICY = '/terms/acceptable-use-policy';
export const LANDING_PRIVACY_POLICY = '/terms/privacy-policy';
export const LANDING_EMAIL_VERIFY = '/landing/email/verify';
export const LANDING_MEMBER_INVITE = '/landing/member/invite';

export const API_SIGNIN = '/auth/signin';
export const API_SIGNUP = '/auth/signup';
export const API_VERIFY_EMAIL = '/auth/email/verify';
export const API_RESEND_EMAIL = '/auth/email/resend';
export const API_PASSWORD_RESET = '/auth/password/reset';
export const API_USERS = '/users';
export const API_USER = (id: string) => `/users/${id}`;
export const API_USER_PROFILE = (id: string) => `/users/${id}/profile`;
export const API_USER_PASSWORD = (id: string) => `/users/${id}/password`;
export const API_USER_ENABLE = (id: string) => `/users/${id}/enable`;
export const API_USER_DISABLE = (id: string) => `/users/${id}/disable`;
export const API_HOUSEHOLDS = '/households';
export const API_HOUSEHOLD = (id: string) => `/households/${id}`;
export const API_HOUSEHOLD_STATUS_CHANGE = (id: string, status: string) =>
  `/households/${id}/enrollment/${status}`;
export const API_HOUSEHOLD_SELF = '/households/self';
export const API_HOUSEHOLD_HOUSING_TYPES = '/households/housing_types';
export const API_HOUSEHOLD_OWNERSHIP_TYPES = '/households/housing_ownership_types';
export const API_HOUSEHOLD_INCOME_LEVELS = '/households/income_levels';
export const API_MEMBERS = '/members';
export const API_MEMBER = (id: string) => `/members/${id}`;
export const API_MEMBER_RELATIONSHIPS = '/members/relationships';
export const API_MEMBER_GENDERS = '/members/genders';
export const API_MEMBER_RACES = '/members/races';
export const API_MEMBER_IMMIGRATION_STATUSES = '/members/immigration_statuses';
export const API_MEMBER_MARITAL_STATUSES = '/members/marital_statuses';
export const API_MEMBER_DEGREES = '/members/degrees';
export const API_MEMBER_EDUCATION_LEVELS = '/members/education_levels';
export const API_MEMBER_EMPLOYMENT_STATUSES = '/members/employment_statuses';
export const API_MEMBER_OCCUPATIONS = '/members/occupations';
export const API_MEMBER_INVITE = (id: string) => `/members/${id}/invite`;
export const API_VEHICLES = '/vehicles';
export const API_VEHICLE = (id: string) => `/vehicles/${id}`;
export const API_VEHICLE_TYPES = '/vehicles/types';
export const API_VEHICLE_COLORS = '/vehicles/colors';
export const API_VEHICLE_FUELS = '/vehicles/fuels';
export const API_VEHICLE_MODELS = '/vehicles/models';
export const API_TRIPS = '/trips';
export const API_TRIP = (id: string) => `/trips/${id}`;
export const API_TRIP_PURPOSES = '/trips/purposes';
export const API_TRIP_TRANSPORT_MODES = '/trips/transport_modes';
export const API_ADDRESSES = '/addresses';
export const API_ADDRESS = (id: string) => `/addresses/${id}`;
export const API_FEEDBACK_LIST = '/feedback';
export const API_FEEDBACK = (id: string) => `/feedback/${id}`;
export const API_SETTINGS = '/settings';
