import { Plugins } from '@capacitor/core';
import { History } from 'history';
import React, { useEffect } from 'react';

import { APP_TRIP_TRACKER } from '../../urls';

const { Tracking } = Plugins;

const UrlListener: React.FC<{ history: History }> = ({ history }) => {
  useEffect(() => {
    const handle = Tracking.addListener('redirect', () => history.push(APP_TRIP_TRACKER));
    return () => handle.remove();
  });

  return null;
};

export default UrlListener;
