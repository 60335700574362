import { Plugins } from '@capacitor/core';
import {
  IonAlert,
  IonButton,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  IonToast,
} from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import { parseISO } from 'date-fns';
import { Coords } from 'google-map-react';
import { arrowBackOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useQueryCache } from 'react-query';
import { RouteComponentProps } from 'react-router';

import { TrackCoordinate } from '../../capacitor/tracking';
import { ThemeContext } from '../../components/ThemeProvider';
import Map from '../../components/map/Map';
import MapCard from '../../components/map/MapCard';
import MapControls from '../../components/map/MapControls';
import MapMarker from '../../components/map/MapMarker';
import useDrawTrack from '../../components/map/useDrawTrack';
import { APP_TRIP } from '../../urls';
import { formatDuration } from '../../utils/date';

const { Tracking } = Plugins;

const useStyles = makeStyles({
  controls: {
    padding: 0,
    '& ion-button': {
      margin: 0,
    },
  },
  indicator: {
    maxWidth: '400px',
    width: 'calc(100vw - var(--ion-margin) * 4 - 56px)',
  },
});

const TripTracker: React.FC<RouteComponentProps> = ({ history }) => {
  const classes = useStyles();
  const theme = useContext(ThemeContext);
  const cache = useQueryCache();

  const [isStopToastVisible, setIsStopToastVisible] = useState(false);
  const [isStopRecordingDialogVisible, setIsStopRecordingDialogVisible] = useState(false);

  const [isFocusing, setIsFocusing] = useState(true);
  const [trackUpdateInterval, setTrackUpdateInterval] = useState(2 * 1000);
  const [mapApi, setMapApi] = useState<{ map: any; maps: any }>();
  const [trackId, setTrackId] = useState<number>();
  const [endAddress, setEndAddress] = useState<string>();
  const [distance, setDistance] = useState<number>();
  const [durationText, setDurationText] = useState<string>();
  const [coordinates, setCoordinates] = useState<TrackCoordinate[]>();

  const { redraw, resize } = useDrawTrack(mapApi, coordinates);

  let start: Coords | null = null;
  let end: Coords | null = null;
  if (coordinates && coordinates.length) {
    start = { lat: coordinates[0].lat, lng: coordinates[0].lng };
    if (coordinates.length > 1) {
      end = {
        lat: coordinates[coordinates.length - 1].lat,
        lng: coordinates[coordinates.length - 1].lng,
      };
    }
  }

  useEffect(() => {
    async function updateTrackInfo() {
      const { value } = !trackId
        ? await Tracking.getCurrentTrack()
        : await Tracking.get({ id: trackId });
      setTrackId(value?.id);
      if (value?.startAt) {
        setDurationText(formatDuration(parseISO(value.startAt), new Date()));
      }
      setDistance(value?.distance);
      setEndAddress(value?.endAddress);
      if (value?.endAddress) {
        setTrackUpdateInterval(30 * 1000);
      }
      if (typeof coordinates === 'undefined') {
        setCoordinates([...(value?.coordinates ?? [])]);
      }
    }

    updateTrackInfo();
    const interval = setInterval(updateTrackInfo, trackUpdateInterval);

    return () => clearInterval(interval);
  }, [trackId, coordinates, trackUpdateInterval]);

  useEffect(() => {
    const locationListener = Tracking.addListener('location', (coordinate: TrackCoordinate) => {
      coordinates?.push(coordinate);
      redraw();
      if (mapApi && isFocusing) {
        mapApi.map.setCenter(coordinate);
        mapApi.map.setZoom(17);
      }
    });

    return () => locationListener.remove();
  }, [mapApi, redraw, coordinates, isFocusing]);

  const onStopTracking = async () => {
    setIsStopToastVisible(true);
    const { value } = await Tracking.getCurrentTrack();
    await Tracking.stopTracking();
    cache.invalidateQueries(['trip', 'draft']);
    cache.invalidateQueries(['trips', 'draft']);
    setIsStopToastVisible(false);
    if (value?.id) {
      history.replace(APP_TRIP.replace(':id?', 'draft:' + value.id));
    }
  };

  const onStopTrackingClick = () => {
    setIsStopRecordingDialogVisible(true);
  };

  const onFocusingToggle = (toggled: boolean) => {
    if (mapApi && toggled) {
      mapApi.map.setCenter(end);
      mapApi.map.setZoom(17);
    }
    setIsFocusing(toggled);
  };

  const distanceText =
    typeof distance === 'number' ? `${distance} mile${distance > 1 ? 's' : ''}` : '';

  return (
    <IonPage>
      <IonContent fullscreen className="fullscreen">
        <IonFab vertical="top" horizontal="start" slot="fixed" className="flat">
          <IonFabButton
            color={theme.isDarkMode ? 'light' : 'medium'}
            onClick={() => history.goBack()}>
            <IonIcon icon={arrowBackOutline} />
          </IonFabButton>
        </IonFab>
        <Map onGoogleApiLoaded={setMapApi}>
          {end ? <MapMarker type="current" {...end} /> : null}
          {start ? <MapMarker type="start" {...start} /> : null}
        </Map>
        <MapCard bottom right>
          <MapControls
            mapApi={mapApi}
            enableCenterToggle
            onCenterToggle={onFocusingToggle}
            defaultCenterToggled={isFocusing}
          />
        </MapCard>
        <MapCard bottom left>
          <IonCardContent className={classes.controls}>
            <IonButton fill="clear" size="small" onClick={() => resize()}>
              Reset view
            </IonButton>
          </IonCardContent>
        </MapCard>
        <MapCard top right className={classes.indicator}>
          <IonCardHeader>
            <IonCardSubtitle>
              {[durationText, distanceText].filter(Boolean).join(' · ')}
            </IonCardSubtitle>
            <IonCardTitle>{!endAddress ? 'Loading address...' : endAddress}</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <IonButton
              size="small"
              fill="solid"
              color="danger"
              shape="round"
              expand="block"
              onClick={onStopTrackingClick}>
              Stop Tracking
            </IonButton>
          </IonCardContent>
        </MapCard>
      </IonContent>
      <IonAlert
        isOpen={isStopRecordingDialogVisible}
        onDidDismiss={() => setIsStopRecordingDialogVisible(false)}
        header={'Stop trip tracking?'}
        message={"You can't resume tracking after it is stopped."}
        buttons={[
          { text: 'No', handler: () => setIsStopRecordingDialogVisible(false) },
          { text: 'Yes', handler: onStopTracking },
        ]}
      />
      <IonToast
        color="primary"
        message="Stopping tracking... Please wait."
        isOpen={isStopToastVisible}
      />
    </IonPage>
  );
};

export default TripTracker;
