import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import React from 'react';

type Button = Parameters<typeof IonFabButton>[0] & { icon?: string };

export interface FabButtonGroupProps {
  slot?: 'start' | 'end' | 'center';
  horizontal?: 'start' | 'end' | 'center';
  vertical?: 'top' | 'bottom' | 'center';
  className?: string;
  buttons?: Button[];
}

const FabButtonGroup: React.FC<FabButtonGroupProps> = (props) => {
  const { slot, horizontal, vertical, className, buttons } = props;

  return (
    <IonFab
      className={className}
      vertical={vertical}
      horizontal={horizontal}
      {...(slot ? { slot } : undefined)}>
      {buttons?.map(({ icon, slot, ...button }, i) => (
        <IonFabButton key={i} {...button}>
          <IonIcon icon={icon} />
        </IonFabButton>
      ))}
    </IonFab>
  );
};

export default FabButtonGroup;
