import { IonNote } from '@ionic/react';
import React from 'react';
import { QueryStatus } from 'react-query';

export interface StatusIndicatorProps {
  color?: string;
  status: QueryStatus;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ color, status, children }) => {
  let text;
  let color_;
  switch (status) {
    case QueryStatus.Loading:
      text = 'Loading...';
      color_ = 'medium';
      break;
    case QueryStatus.Error:
      text = 'Failed';
      color_ = 'danger';
      break;
    default:
      color_ = color ?? 'success';
      break;
  }

  return (
    <IonNote color={color_} slot="end" className="ion-text-uppercase">
      {text ?? children}
    </IonNote>
  );
};

export default StatusIndicator;
