import { Capacitor, Plugins, StatusBarStyle } from '@capacitor/core';
import '@ionic/react/css/ionic.bundle.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import 'capacitor-navigationbar';
import React, { createContext, useEffect, useMemo, useState } from 'react';

import '../theme/action-sheet.scss';
import '../theme/alert.scss';
import '../theme/button.scss';
import '../theme/card.scss';
import '../theme/fab.scss';
import '../theme/index.scss';
import '../theme/input.scss';
import '../theme/list.scss';
import '../theme/map.scss';
import '../theme/page.scss';
import '../theme/picker.scss';
import '../theme/toolbar.scss';
import '../theme/variables.scss';

const { Storage, StatusBar, NavigationBar } = Plugins;

export type AppMode = 'light' | 'dark' | 'system';

export interface ThemeProps {
  mode: AppMode;
  isDarkMode: boolean;
  setMode?: (mode: AppMode) => void;
}

export const ThemeContext = createContext<ThemeProps>({ mode: 'system', isDarkMode: false });

const ThemeProvider: React.FC = ({ children }) => {
  const [isDarkMode, setDarkMode] = useState(false);
  const [mode, setMode] = useState<AppMode>('system');

  useMemo(() => {
    async function init() {
      const { value } = await Storage.get({ key: 'mode' });
      setMode(value === 'dark' || value === 'light' ? value : 'system');

      if (!Capacitor.isNative) {
        await import('capacitor-dark-mode');
        Plugins.DarkMode.registerDarkModeChangeListener();
      }

      const state = await Plugins.DarkMode.isDarkModeOn();
      setDarkMode(state.isDarkModeOn);
      Plugins.DarkMode.addListener('darkModeStateChanged', (state: any) => {
        setDarkMode(state.isDarkModeOn);
      });
    }

    init();
  }, []);

  const isDarkModeFinal = mode === 'dark' || (mode === 'system' && isDarkMode);

  useEffect(() => {
    if (isDarkModeFinal) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [isDarkModeFinal]);

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: isDarkModeFinal ? 'dark' : 'light',
          primary: {
            main: '#3880ff',
          },
          secondary: {
            main: '#3880ff',
          },
          background: {
            default: isDarkModeFinal ? '#121212' : '#fafafa',
            paper: isDarkModeFinal ? '#1e1e1e' : '#ffffff',
          },
        },
        typography: {
          fontFamily: 'var(--ion-font-family)',
        },
      }),
    [isDarkModeFinal],
  );

  const onModeChange = async (mode: AppMode) => {
    await Storage.set({ key: 'mode', value: mode });
    setMode(mode);
  };

  if (Capacitor.isNative) {
    StatusBar.setStyle({ style: isDarkModeFinal ? StatusBarStyle.Dark : StatusBarStyle.Light });
    StatusBar.setBackgroundColor({ color: isDarkModeFinal ? '#121212' : '#ffffff' });
    NavigationBar.setBackgroundColor({ color: isDarkModeFinal ? '#121212' : '#ffffff' });
  }

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeContext.Provider value={{ mode, isDarkMode: isDarkModeFinal, setMode: onModeChange }}>
        {children}
      </ThemeContext.Provider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
