import { Capacitor, Plugins } from '@capacitor/core';
import { IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonText } from '@ionic/react';
import { Box, Typography } from '@material-ui/core';
import {
  documentOutline,
  helpCircleOutline,
  mailOutline,
  openOutline,
  playCircleOutline,
} from 'ionicons/icons';
import React, { useState } from 'react';

import {
  ConfigurableUrls,
  ContactInfo,
  SETTING_CONFIGURABLE_URLS,
  SETTING_CONTACT_INFO,
  useSettings,
} from '../api/setting';
import FormLayout from '../components/layouts/FormLayout';
import PolicyPopup from './common/PolicyPopup';

const { App } = Plugins;

const Link: React.FC<{ href?: string; onClick?: () => any }> = ({ href, onClick, children }) => {
  const onClick_ = onClick || (href ? () => App.openUrl({ url: href }) : undefined);

  return (
    <IonItem
      button
      className="item-text item-button item-interactive"
      href={href}
      onClick={onClick_}>
      {children}
    </IonItem>
  );
};

const Help: React.FC = () => {
  const { data: settings } = useSettings();
  const contactInfo: ContactInfo | undefined = settings?.[SETTING_CONTACT_INFO];
  const urls: ConfigurableUrls | undefined = settings?.[SETTING_CONFIGURABLE_URLS];

  const mailSubject = 'Questions about HTS@TN app';
  const mailUrl = `mailto:${contactInfo?.email}?subject=${encodeURIComponent(mailSubject)}`;

  const prefix = Capacitor.getPlatform() === 'ios' ? 'ios_' : 'android_';
  const tutorialRecruitmentSurvey = urls?.[prefix + 'tutorial_recruitment_survey'] || undefined;
  const tutorialTripTracking = urls?.[prefix + 'tutorial_trip_tracking'] || undefined;
  const tutorialSettings = urls?.[prefix + 'tutorial_settings'] || undefined;
  const irbConsentUrl = urls?.['irb_consent_form_url'] || undefined;

  const [isFaqVisible, setIsFaqVisible] = useState(false);
  const [isTermsOfServiceVisible, setIsTermsOfServiceVisible] = useState(false);
  const [isAcceptableUsePolicyVisible, setIsAcceptableUsePolicyVisible] = useState(false);
  const [isPrivacyPolicyVisible, setIsPrivacyPolicyVisible] = useState(false);

  return (
    <IonPage>
      <FormLayout title="Help" subtitle="Helpful tips, terms, and policies.">
        <IonList>
          <IonListHeader>
            <IonLabel>Tutorials</IonLabel>
          </IonListHeader>
          <Link href={tutorialRecruitmentSurvey}>
            <IonText>Recruitment Survey</IonText>
            <IonIcon icon={playCircleOutline} slot="start" />
            <IonIcon icon={openOutline} slot="end" />
          </Link>
          <Link href={tutorialTripTracking}>
            <IonText>Trip Recording</IonText>
            <IonIcon icon={playCircleOutline} slot="start" />
            <IonIcon icon={openOutline} slot="end" />
          </Link>
          <Link href={tutorialSettings}>
            <IonText>App Settings</IonText>
            <IonIcon icon={playCircleOutline} slot="start" />
            <IonIcon icon={openOutline} slot="end" />
          </Link>
          <Link onClick={() => setIsFaqVisible(true)}>
            <IonText>FAQ</IonText>
            <IonIcon icon={helpCircleOutline} slot="start" />
          </Link>
        </IonList>
        <IonListHeader>
          <IonLabel>Contact Us</IonLabel>
        </IonListHeader>
        <Link href={mailUrl}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <IonText>{contactInfo?.name || 'Loading...'}</IonText>
            <IonText style={{ fontSize: '0.75em' }}>{contactInfo?.email || 'Loading...'}</IonText>
          </div>
          <IonIcon icon={mailOutline} slot="start" />
          <IonIcon icon={openOutline} slot="end" />
        </Link>
        <IonListHeader>
          <IonLabel>Terms and Policies</IonLabel>
        </IonListHeader>
        <Link href={irbConsentUrl}>
          <IonText>IRB Consent Form</IonText>
          <IonIcon icon={documentOutline} slot="start" />
          <IonIcon icon={openOutline} slot="end" />
        </Link>
        <Link onClick={() => setIsTermsOfServiceVisible(true)}>
          <IonText>Terms of Service</IonText>
          <IonIcon icon={documentOutline} slot="start" />
        </Link>
        <Link onClick={() => setIsAcceptableUsePolicyVisible(true)}>
          <IonText>Acceptable Use Policy</IonText>
          <IonIcon icon={documentOutline} slot="start" />
        </Link>
        <Link onClick={() => setIsPrivacyPolicyVisible(true)}>
          <IonText>Privacy Policy</IonText>
          <IonIcon icon={documentOutline} slot="start" />
        </Link>
        <Box mt={3}>
          <Typography component="p" variant="caption" align="center" color="textSecondary">
            {'The Institutional Review Board of the University of Tennessee at Chattanooga ' +
              '(FWA00004149) has approved this research project # 21-139.'}
          </Typography>
        </Box>
      </FormLayout>
      <PolicyPopup
        name="faq"
        title="FAQ"
        isOpen={isFaqVisible}
        onDismiss={() => setIsFaqVisible(false)}
      />
      <PolicyPopup
        name="terms-of-service"
        title="Terms of Service"
        isOpen={isTermsOfServiceVisible}
        onDismiss={() => setIsTermsOfServiceVisible(false)}
      />
      <PolicyPopup
        name="acceptable-use-policy"
        title="Acceptable Use Policy"
        isOpen={isAcceptableUsePolicyVisible}
        onDismiss={() => setIsAcceptableUsePolicyVisible(false)}
      />
      <PolicyPopup
        name="privacy-policy"
        title="Privacy Policy"
        isOpen={isPrivacyPolicyVisible}
        onDismiss={() => setIsPrivacyPolicyVisible(false)}
      />
    </IonPage>
  );
};

export default Help;
