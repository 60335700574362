import { CellParams, ColDef, RowParams, RowsProp } from '@material-ui/data-grid';
import { Rating } from '@material-ui/lab';
import React from 'react';
import { useHistory } from 'react-router';

import { FilterOptions, usePagedList } from '../../api/common';
import { Feedback } from '../../api/feedback';
import DataGrid from '../../components/datagrid/DataGrid';
import {
  ContainsOperator,
  DateTimeRangeOperators,
  RatingOperators,
} from '../../components/datagrid/Operators';
import useFilter from '../../components/datagrid/useFilter';
import usePagination from '../../components/datagrid/usePagination';
import useSorting from '../../components/datagrid/useSorting';
import { API_FEEDBACK_LIST, DASHBOARD_FEEDBACK } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const columns: ColDef[] = [
  {
    field: 'overallRating',
    headerName: 'Overall Rating',
    width: 170,
    filterOperators: RatingOperators,
    renderCell: (params: CellParams) => (
      <Rating precision={1} value={Number(params.value)} readOnly />
    ),
  },
  {
    field: 'functionalityRating',
    headerName: 'Functionality Rating',
    width: 210,
    filterOperators: RatingOperators,
    renderCell: (params: CellParams) => (
      <Rating precision={1} value={Number(params.value)} readOnly />
    ),
  },
  {
    field: 'usabilityRating',
    headerName: 'Usability Rating',
    width: 180,
    filterOperators: RatingOperators,
    renderCell: (params: CellParams) => (
      <Rating precision={1} value={Number(params.value)} readOnly />
    ),
  },
  {
    field: 'stabilityRating',
    headerName: 'Stability Rating',
    width: 180,
    filterOperators: RatingOperators,
    renderCell: (params: CellParams) => (
      <Rating precision={1} value={Number(params.value)} readOnly />
    ),
  },
  {
    field: 'lookAndFeelRating',
    headerName: 'Look and Feel Rating',
    width: 220,
    filterOperators: RatingOperators,
    renderCell: (params: CellParams) => (
      <Rating precision={1} value={Number(params.value)} readOnly />
    ),
  },
  { field: 'userComment', headerName: 'Comment', width: 400, filterOperators: ContainsOperator },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('createdAt') as string),
  },
];

export interface FeedbackTableProps {
  filter?: FilterOptions;
}

const FeedbackTable: React.FC<FeedbackTableProps> = ({ filter: extraFilter }) => {
  const history = useHistory();

  const [filter, filterProps] = useFilter();
  const [sorting, sortingPorps] = useSorting();
  const [pagination, paginationProps] = usePagination();

  const { data, totalItems, isLoading } = usePagedList<Feedback>(
    API_FEEDBACK_LIST,
    { ...filter, ...extraFilter },
    sorting,
    pagination,
  );

  const onRowClick = (params: RowParams) => {
    history.push(DASHBOARD_FEEDBACK.replace(':id', params.getValue('id') as string));
  };

  const rows: RowsProp = data?.items ?? [];

  return (
    <DataGrid
      {...paginationProps}
      {...filterProps}
      {...sortingPorps}
      rows={rows}
      columns={columns}
      loading={isLoading}
      rowCount={totalItems}
      onRowClick={onRowClick}
    />
  );
};

export default FeedbackTable;
