import { Box, Breadcrumbs, Grid, Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { Member, useMember } from '../../api/member';
import LoadingOrFail from '../../components/containers/LoadingOrFail';
import DashItemTab, { DashItemTabProps } from '../../components/forms/DashItemTab';
import DashItemTabPanel from '../../components/forms/DashItemTabPanel';
import DashItemText from '../../components/forms/DashItemText';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import VehicleTable from '../../components/tables/VehicleTable';
import DashboardHeader from '../../components/typography/DashboardHeader';
import { DASHBOARD_HOUSEHOLD, DASHBOARD_MEMBER_LIST, DASHBOARD_USER } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const MemberBasicInfo: React.FC<{ member: Member }> = ({ member }) => {
  return (
    <Box mb={4}>
      <Grid container spacing={2}>
        <DashItemText xs={12} md={6} title="ID">
          {member.id}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Name">
          {member.name ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Household">
          <Link
            component={RouterLink}
            to={DASHBOARD_HOUSEHOLD.replace(':id', member.householdId ?? '')}>
            {member.householdId}
          </Link>
        </DashItemText>
        <DashItemText xs={12} md={6} title="Associated User">
          {member.userId ? (
            <Link component={RouterLink} to={DASHBOARD_USER.replace(':id', member.userId)}>
              {member.userId}
            </Link>
          ) : (
            'N/A'
          )}
        </DashItemText>
        <DashItemText xs={12} md={4} title="Relationship">
          {member.relationship?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Age">
          {typeof member.age === 'number' ? member.age : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Gender">
          {member.gender?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={8} md={4} title="Marital Status">
          {member.maritalStatus?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={4} md={2} title="Hispanic">
          {typeof member.isHispanic === 'boolean'
            ? member.isHispanic
              ? 'Yes'
              : 'No'
            : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Race">
          {member.race?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Highest Degree">
          {member.highestDegree?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={6} title="Current Education Level">
          {member.currentEducationLevel?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={4} title="Employment Status">
          {member.employmentStatus?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} md={8} title="Occupation">
          {member.occupation?.title ?? '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Number of Jobs">
          {typeof member.jobCount === 'number' ? member.jobCount : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Work for Profit">
          {typeof member.isWorkForProfit === 'boolean'
            ? member.isWorkForProfit
              ? 'Yes'
              : 'No'
            : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Work from Home">
          {typeof member.isWorkForProfit === 'boolean'
            ? member.isWorkFromHome
              ? 'Yes'
              : 'No'
            : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Seasonal Worker">
          {typeof member.isSeasonalWorker === 'boolean'
            ? member.isSeasonalWorker
              ? 'Yes'
              : 'No'
            : '(Not set)'}
        </DashItemText>
        <DashItemText xs={12} sm={6} md={4} title="Created At">
          {humanizeIsoDate(member.createdAt)}
        </DashItemText>
      </Grid>
    </Box>
  );
};

const MemberLinkedAssets: React.FC<{ member: Member }> = ({ member }) => {
  const tabs = ['driver of'];
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const onTabChange: DashItemTabProps['onTabChange'] = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <DashItemTab
      title="Linked Assets"
      tabs={tabs}
      currentTab={currentTab}
      onTabChange={onTabChange}>
      <DashItemTabPanel forTab={tabs[0]} currentTab={currentTab}>
        <VehicleTable filter={{ isHouseholdDriver: true, householdDriver: member.id }} />
      </DashItemTabPanel>
    </DashItemTab>
  );
};

const MemberDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: member, isError } = useMember(id, { enabled: Boolean(id) });

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Link
            color="inherit"
            variant="overline"
            component={RouterLink}
            to={DASHBOARD_MEMBER_LIST}>
            Members
          </Link>
          <Typography color="textPrimary" variant="overline">
            {id}
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          Member Details
        </Typography>
      </DashboardHeader>
      <LoadingOrFail isLoading={!member} isError={isError}>
        {member ? (
          <>
            <MemberBasicInfo member={member} />
            <MemberLinkedAssets member={member} />
          </>
        ) : null}
      </LoadingOrFail>
    </DashboardLayout>
  );
};

export default MemberDetails;
