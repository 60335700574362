import { CheckboxChangeEventDetail } from '@ionic/core';
import { IonCheckbox } from '@ionic/react';
import React from 'react';

type IonCheckboxProps = Parameters<typeof IonCheckbox>[0];

const IonCheckbox_: React.FC<IonCheckboxProps> = ({ checked, onIonChange, ...props }) => {
  const onIonChange_ = (e: CustomEvent<CheckboxChangeEventDetail>) => {
    if (checked !== e.detail.checked) {
      onIonChange?.(e);
    }
  };

  return <IonCheckbox checked={checked} onIonChange={onIonChange_} {...props} />;
};

export default IonCheckbox_;
