import { Box, Container, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import marked from 'marked';
import React from 'react';

import { useDocument } from '../../api/common';

export interface PolicyPageProps {
  name: string;
  title: string;
}

const PolicyPage: React.FC<PolicyPageProps> = ({ name, title }) => {
  const { data } = useDocument(name);

  return (
    <>
      <CssBaseline />
      <Box height="100vh" overflow="auto">
        <Container maxWidth="md">
          <Box py={6}>
            <Typography component="h1" variant="h4">
              {title}
            </Typography>
            {data ? <div dangerouslySetInnerHTML={{ __html: marked(data) }}></div> : null}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PolicyPage;
