import { Box, Grid, GridTypeMap, Paper, Typography } from '@material-ui/core';
import React from 'react';

type GridProps = GridTypeMap['props'];

export interface DashItemProps extends GridProps {
  title: string;
}

const DashItem: React.FC<DashItemProps> = ({ title, children, ...gridProps }) => {
  return (
    <Grid item {...gridProps}>
      <Paper variant="outlined">
        <Box p={2} pt={1}>
          <Typography color="textSecondary" variant="overline">
            {title}
          </Typography>
          {children}
        </Box>
      </Paper>
    </Grid>
  );
};

export default DashItem;
