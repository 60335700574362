import { IonList, IonModal } from '@ionic/react';
import React from 'react';

import { useSelfHousehold } from '../../api/household';
import { Vehicle, getVehicleMakeModel, getVehicleYear, useVehicles } from '../../api/vehicle';
import InfiniteScroll from '../../components/containers/InfiniteScroll';
import ItemText from '../../components/forms/ItemText';
import ModalLayout from '../../components/layouts/ModalLayout';

interface VehicleSelectProps {
  isOpen: boolean;
  title?: string;
  subtitle?: string;
  optional?: boolean;
  onDismiss?: () => void;
  onSelect?: (vehicle?: Vehicle) => void;
}

const VehicleSelect: React.FC<VehicleSelectProps> = (props) => {
  const { isOpen, optional, onSelect, onDismiss } = props;
  const { title = 'Vehicles', subtitle = 'Select from following household vehicles.' } = props;

  const { data: household } = useSelfHousehold();
  const { data, fetchMore, canFetchMore } = useVehicles(household?.id);

  const bucket = new Set();
  const vehicles = (Array.isArray(data) ? data : [])
    .map((page) => page.items)
    .flat()
    .filter((vehicle) => (bucket.has(vehicle.id) ? false : (bucket.add(vehicle.id), true)));

  return (
    <IonModal isOpen={isOpen}>
      <ModalLayout title={title} subtitle={subtitle} onDismiss={onDismiss}>
        <IonList>
          {optional ? (
            <ItemText
              key={-1}
              title="Not household vehicle"
              onClick={() => {
                onDismiss?.();
                onSelect?.(undefined);
              }}
            />
          ) : null}
          {vehicles.map((vehicle) => {
            return (
              <ItemText
                key={vehicle.id}
                title={getVehicleMakeModel(vehicle)}
                subtitle={getVehicleYear(vehicle)}
                onClick={() => {
                  onDismiss?.();
                  onSelect?.(vehicle);
                }}
              />
            );
          })}
        </IonList>
        <InfiniteScroll
          disabled={!canFetchMore}
          onIonInfinite={async (e: any) => {
            await fetchMore();
            e.target.complete();
          }}
        />
      </ModalLayout>
    </IonModal>
  );
};

export default VehicleSelect;
