import { IonAlert, IonButton, IonIcon } from '@ionic/react';
import { format, formatISO, isAfter, isEqual, parseISO, subMonths } from 'date-fns';
import { warning } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';

import {
  SETTING_SITE_CLOSED_DATE,
  useIsSiteClosed,
  useSiteClosingDate,
  useSurveyExpirationDate,
} from '../../api/setting';
import { isValidUser, useSelfUser } from '../../api/user';
import { useLocalCache } from '../LocalCache';

const SurveyEndWarning: React.FC = () => {
  const store = useLocalCache();
  const siteClosingDate = useSiteClosingDate();
  const isSiteClosed = useIsSiteClosed();
  const { data: user } = useSelfUser({ enabled: !isSiteClosed });
  const surveyExpirationDate = useSurveyExpirationDate({ enabled: isValidUser(user) });

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const confirmedDate = store.get(['alert', SETTING_SITE_CLOSED_DATE]);

  // the most recent date that the survey ends, either site closing date or survey expiration date
  let endDate: Date | null;
  if (siteClosingDate === null) {
    endDate = surveyExpirationDate;
  } else if (surveyExpirationDate === null) {
    endDate = siteClosingDate;
  } else {
    endDate = isAfter(siteClosingDate, surveyExpirationDate)
      ? surveyExpirationDate
      : siteClosingDate;
  }
  // only open alert when there is less than 1 month to the end date
  const isOneMonthToEndDate = endDate !== null && isAfter(new Date(), subMonths(endDate, 1));
  const isAlreadyEnded = endDate !== null && isAfter(new Date(), endDate);
  // ask user to confirm the end date when user has not done so or end date has changed
  const shouldConfirm =
    endDate &&
    isOneMonthToEndDate &&
    !isAlreadyEnded &&
    (!confirmedDate || !isEqual(parseISO(confirmedDate), endDate));

  useEffect(() => {
    if (shouldConfirm) {
      setIsAlertVisible(true);
    }
  }, [shouldConfirm]);

  const onAlertDismiss = () => {
    setIsAlertVisible(false);
    if (endDate) {
      store.set(['alert', SETTING_SITE_CLOSED_DATE], formatISO(endDate));
    }
  };

  // reminder users 1 month before operation site closing date
  if (!endDate || !isOneMonthToEndDate || isAlreadyEnded) {
    return null;
  }

  return (
    <>
      <IonAlert
        header="Warning"
        message={
          'This survey is closing soon. ' +
          'Make sure your recruitment survey is submitted and trips are saved before ' +
          format(endDate, 'MMM d, yyyy hh:mm:ss a') +
          '.'
        }
        buttons={['OK']}
        isOpen={isAlertVisible}
        onDidDismiss={onAlertDismiss}
      />
      <IonButton color="warning" onClick={() => setIsAlertVisible(true)}>
        <IonIcon slot="icon-only" icon={warning} />
      </IonButton>
    </>
  );
};

export default SurveyEndWarning;
