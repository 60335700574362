import React, { createContext, useContext } from 'react';

import { deepIncludes, stableStringify } from '../utils/serialization';

const CACHE_PREFIX = 'cache:';

function getCacheKey(key: any) {
  return CACHE_PREFIX + stableStringify(key);
}

// Adapted from https://github.com/tusharf5/runtime-memcache/tree/v2.0.0
class LinkedListNode<V = any> {
  id: string;
  next: LinkedListNode<V> | null;
  prev: LinkedListNode<V> | null;

  constructor(id: string, data?: any) {
    this.id = id;
    this.next = null;
    this.prev = null;
    if (typeof data !== 'undefined') {
      this.data = data;
    }
  }

  get data(): V | undefined {
    const value = localStorage.getItem(this.id);
    return value === null ? null : JSON.parse(value);
  }

  set data(data: V | undefined) {
    try {
      if (typeof data === 'undefined') {
        localStorage.removeItem(this.id);
      } else {
        localStorage.setItem(this.id, JSON.stringify(data));
      }
    } catch (e) {
      // quota exceeded or storage is diabled
    }
  }
}

// Adapted from https://github.com/tusharf5/runtime-memcache/tree/v2.0.0
class LRULinkedList<V> {
  __size: number = 0;

  HEAD: LinkedListNode<V> | null;
  TAIL: LinkedListNode<V> | null;
  limit: number = 0;
  positions: Map<string, LinkedListNode<V>> = new Map<string, any>();

  constructor(limit: number) {
    this.HEAD = null;
    this.TAIL = null;
    this.limit = limit;
  }

  get size() {
    return this.__size;
  }

  set size(size: number) {
    this.__size = size;
    if (this.__size > this.limit) {
      this.shrinkList();
    }
  }

  // restore list items from local storage
  reload(ids: string[]) {
    for (const id of ids) {
      this.addNodeToHead(id);
    }
  }

  moveToHead(node: LinkedListNode<V>): LinkedListNode<V> | null {
    const { id, data } = node;
    if (this.remove(node.id)) {
      return this.addNodeToHead(id, data);
    }
    return null;
  }

  addNodeToHead(id: string, data?: V): LinkedListNode<V> | null {
    if (this.positions.has(id)) {
      const node = this.moveToHead(this.positions.get(id)!);
      if (node && typeof node.data !== 'undefined') {
        node.data = data;
      }
      return node;
    }
    if (this.HEAD) {
      let oldHead = this.HEAD;
      const newHead = new LinkedListNode<V>(id, data);
      newHead.next = oldHead;
      newHead.prev = null;
      oldHead.prev = newHead;
      this.HEAD = newHead;
      this.positions.set(id, newHead);
      this.size++;
      return newHead;
    } else {
      const newNode = new LinkedListNode<V>(id, data);
      this.HEAD = newNode;
      if (!this.TAIL) {
        this.TAIL = newNode;
      }
      this.positions.set(id, newNode);
      this.size++;
      return newNode;
    }
  }

  remove(id: string): LinkedListNode<V> | null {
    if (!this.HEAD) {
      return null;
    }

    const node = this.positions.get(id);
    if (!node) {
      return null;
    }

    // remove node data from underlying cache storage
    node.data = undefined;

    const prevNode = node.prev;
    const nextNode = node.next;

    // both prev and next node exists
    // in this case this node can never be either HEAD or the TAIL node
    if (prevNode && nextNode) {
      prevNode.next = nextNode;
      nextNode.prev = prevNode;
      this.positions.delete(id);
      this.size--;
      return node;
    }

    // if this is the tail node
    if (prevNode && !nextNode) {
      prevNode.next = null;
      this.TAIL = prevNode;
      this.positions.delete(id);
      this.size--;
      return node;
    }

    // if this is the head node
    if (!prevNode && nextNode) {
      nextNode.prev = null;
      this.HEAD = nextNode;
      this.positions.delete(id);
      this.size--;
      return node;
    }

    // if this is both the head and the tail
    if (!prevNode && !nextNode) {
      this.HEAD = null;
      this.TAIL = null;
      this.positions.delete(id);
      this.size--;
      return node;
    }

    return null;
  }

  keys(): string[] {
    return Array.from(this.positions.keys());
  }

  shrinkList() {
    const nodesToRemove = this.size - this.limit;
    let currentNode = this.TAIL;
    for (let i = 1; i <= nodesToRemove; i++) {
      this.positions.delete(currentNode!.id);
      this.size--;
      currentNode = currentNode!.prev;
    }
    currentNode!.next = null;
    this.TAIL = currentNode;
  }

  has(id: string): boolean {
    if (this.positions.has(id)) {
      return true;
    }
    return false;
  }

  get(id: string): V | null {
    const node = this.positions.get(id);
    if (node) {
      const newHeadNode = this.moveToHead(node);
      return newHeadNode?.data ?? null;
    }
    return null;
  }
}

export interface LocalCache<K, V> {
  keys(): K[];
  size(): number;
  get(key: K): V | null;
  set(key: K, value: V): void;
  remove(key: K): void;
  has(key: K): void;
}

export function createStore<K, V>(limit: number): LocalCache<K, V> {
  const store = new LRULinkedList<V>(limit);
  store.reload(Object.keys(localStorage).filter((key) => key.startsWith(CACHE_PREFIX)));

  function get(key: K) {
    return store.get(getCacheKey(key));
  }

  function remove(key: K) {
    for (const k of keys()) {
      if (deepIncludes(k, key)) {
        store.remove(getCacheKey(k));
      }
    }
  }

  function has(key: K) {
    return store.has(getCacheKey(key));
  }

  function size(): number {
    return store.size;
  }

  function keys(): K[] {
    return store
      .keys()
      .map((key) => key.slice(CACHE_PREFIX.length))
      .map((key) => JSON.parse(key));
  }

  function set(key: any, value: any) {
    store.addNodeToHead(getCacheKey(key), value);
    return true;
  }

  return { keys, size, get, set, remove, has };
}

export type LocalCacheContextProps = { cache: LocalCache<any, any> | null };
export const LocalCacheContext = createContext<LocalCacheContextProps>({ cache: null });

export const LocalCacheProvider: React.FC<LocalCacheContextProps> = ({ cache, children }) => {
  return <LocalCacheContext.Provider value={{ cache }}>{children}</LocalCacheContext.Provider>;
};

export function useLocalCache() {
  const { cache } = useContext(LocalCacheContext);
  if (!cache) {
    throw new Error('Cache cannot be null.');
  }
  return cache;
}
