import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import React from 'react';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import UserTable from '../../components/tables/UserTable';
import DashboardHeader from '../../components/typography/DashboardHeader';

const UserList: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Typography color="textPrimary" variant="overline">
            Users
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          All Users
        </Typography>
      </DashboardHeader>
      <Paper variant="outlined">
        <UserTable />
      </Paper>
    </DashboardLayout>
  );
};

export default UserList;
