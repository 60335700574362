import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

type IonInfiniteScrollProps = Parameters<typeof IonInfiniteScroll>[0];

export interface InfiniteScrollProps extends IonInfiniteScrollProps {}

const useStyles = makeStyles({
  container: {
    display: ({ disabled }: Partial<InfiniteScrollProps>) => (disabled ? 'none' : 'flex'),
    flexDirection: 'row',
    justifyContent: 'center',
    '& .infinite-loading-spinner': {
      height: 28,
    },
  },
  button: {
    lineHeight: '28px',
    marginTop: 'var(--ion-margin)',
  },
});

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({ disabled, onIonInfinite, ...props }) => {
  const classes = useStyles({ disabled });
  const ref = useRef<HTMLIonInfiniteScrollElement>(null);
  const [scroller, setScroller] = useState<HTMLElement>();
  const [isManualLoading, setIsManualLoading] = useState(false);
  const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(true);

  useEffect(() => {
    const container = ref.current?.closest('ion-content');
    container?.getScrollElement().then((element) => setScroller(element));
  }, []);

  useEffect(() => {
    const listener = () => {
      setIsLoadMoreVisible(false);
    };

    scroller?.addEventListener('scroll', listener);
    return () => scroller?.removeEventListener('scroll', listener);
  }, [scroller]);

  const onManualLoadClick = () => {
    setIsManualLoading(true);
    onIonInfinite?.({
      target: {
        complete: async () => {
          await ref.current?.complete();
          setIsManualLoading(false);
        },
      },
    } as any);
  };

  return (
    <IonInfiniteScroll
      ref={ref}
      threshold="10"
      position="bottom"
      disabled={disabled}
      className={classes.container}
      onIonInfinite={onIonInfinite}
      {...props}>
      {isLoadMoreVisible ? (
        !isManualLoading ? (
          <Typography className={classes.button} variant="body2" align="center">
            <button className="is-link" onClick={onManualLoadClick}>
              Load more
            </button>
          </Typography>
        ) : null
      ) : (
        <IonInfiniteScrollContent loadingSpinner="lines-small" />
      )}
    </IonInfiniteScroll>
  );
};

export default InfiniteScroll;
