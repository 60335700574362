import { Loader } from '@googlemaps/js-api-loader';
import {
  InfiniteQueryConfig,
  MutationConfig,
  QueryObserverConfig,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import { API_ADDRESS, API_ADDRESSES } from '../urls';
import { useApi } from './base';
import { FilterOptions, Pagination, Timestamp, getGoogleMapsApiKey } from './common';

const loadGoogleMapsApi = (() => {
  let isLoaded: boolean;

  return async () => {
    if (!isLoaded) {
      await new Loader({
        apiKey: getGoogleMapsApiKey(),
        region: 'US',
        version: 'weekly',
        libraries: ['places'],
        language: 'en',
      }).load();
      isLoaded = true;
    }
  };
})();

async function createGeocoder() {
  await loadGoogleMapsApi();
  return new google.maps.Geocoder();
}

async function createAutocompleteService() {
  await loadGoogleMapsApi();
  return new google.maps.places.AutocompleteService();
}

export const UNKNOWN_CITY = 'Unknown city';
export const UNKNOWN_STATE = 'Unknown state';
export const UNKNOWN_ADDRESS = 'Unknown address';

export interface AddressBase {
  name?: string | null;
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface Address extends AddressBase, Timestamp {
  id: string;
  userId: string;
  name: string | null;
}

export interface AddressCreate extends AddressBase {}

export interface AddressUpdate extends Partial<AddressCreate> {
  id: string;
}

export function useGeocoding() {
  const queryFn = async (request: google.maps.GeocoderRequest) => {
    const geocoder = await createGeocoder();

    return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      geocoder.geocode(request, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject(status);
        } else {
          resolve(results);
        }
      });
    });
  };

  return useMutation(queryFn);
}

export function useReverseGeocoding() {
  const queryFn = async (request: google.maps.GeocoderRequest) => {
    const geocoder = await createGeocoder();

    return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      geocoder.geocode(request, (results, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject(status);
        } else {
          resolve(results);
        }
      });
    });
  };

  return useMutation(queryFn);
}

export function usePlaceSearch() {
  const queryFn = async (request: google.maps.places.AutocompletionRequest) => {
    const service = await createAutocompleteService();
    const req = Object.assign({ componentRestrictions: { country: 'us' } }, request);

    return await new Promise<google.maps.places.AutocompletePrediction[]>((resolve, reject) => {
      if (!req.input) {
        return resolve([]);
      }
      service.getPlacePredictions(req, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
        } else if (!predictions || !Array.isArray(predictions)) {
          reject('invalid address predictions');
        } else {
          resolve(predictions);
        }
      });
    });
  };

  return useMutation(queryFn);
}

export function useAddress(id: string, config?: QueryObserverConfig<Address>) {
  const api = useApi();
  const store = useLocalCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['address', id],
    async (key: string, id: string) => {
      const { data } = await api.get<Address>(API_ADDRESS(id));
      return data;
    },
    {
      enabled: enabled ?? Boolean(id),
      initialData: initialData ?? (() => store.get(['address', id])),
      onSuccess(data) {
        store.set(['address', id], data);
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useAddresses(user?: string, config?: InfiniteQueryConfig<Pagination<Address>>) {
  const api = useApi();
  const store = useLocalCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useInfiniteQuery(
    ['addresses', { user }],
    async (key: string, filters?: FilterOptions, nextPage?: number) => {
      const { data } = await api.get<Pagination<Address>>(API_ADDRESSES, {
        params: { ...filters, page: nextPage },
      });
      return data;
    },
    {
      enabled: enabled ?? Boolean(user),
      initialData: initialData ?? (() => store.get(['addresses', { user }])),
      onSuccess(data) {
        if (data && data.length === 1) {
          // only cache first page
          store.set(['addresses', { user }], data);
        }
        onSuccess?.(data);
      },
      getFetchMore(lastPage) {
        return lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : 0;
      },
      ...config_,
    },
  );
}

export function useAddressCreate(config?: MutationConfig<Address, unknown, AddressCreate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (address: AddressCreate) => {
      const { data } = await api.post<Address>(API_ADDRESSES, address);
      return data;
    },
    {
      onSuccess: async (address, variables) => {
        cache.setQueryData(['address', address.id], address);
        store.set(['address', address.id], address);
        cache.invalidateQueries(['addresses']);
        store.remove(['addresses']);
        onSuccess?.(address, variables);
      },
      ...config_,
    },
  );
}

export function useAddressUpdate(config?: MutationConfig<Address, unknown, AddressUpdate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (address: AddressUpdate) => {
      const { data } = await api.put<Address>(API_ADDRESS(address.id), address);
      return data;
    },
    {
      onSuccess: async (address, variable) => {
        cache.setQueryData(['address', address.id], address);
        store.set(['address', address.id], address);
        cache.invalidateQueries(['addresses']);
        store.remove(['addresses']);
        onSuccess?.(address, variable);
      },
      ...config_,
    },
  );
}

export function useAddressDelete(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      await api.delete(API_ADDRESS(id));
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['address', id]);
        store.remove(['address', id]);
        cache.invalidateQueries(['addresses']);
        store.remove(['addresses']);
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}
