import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailIcon from '@material-ui/icons/MailOutline';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import GLOBE from 'vanta/dist/vanta.globe.min';

import {
  ConfigurableUrls,
  ContactInfo,
  SETTING_CONFIGURABLE_URLS,
  SETTING_CONTACT_INFO,
  useSettings,
} from '../api/setting';
import {
  LANDING_ACCEPTABLE_USE_POLICY,
  LANDING_FAQ,
  LANDING_PRIVACY_POLICY,
  LANDING_TERMS_OF_SERVICE,
} from '../urls';

const useStyles = makeStyles((theme) => ({
  background: {
    width: '100vw',
    height: '100vh',
  },
  containerWrapper: {
    height: '100%',
    overflowY: 'auto',
    maxWidth: 1600,
  },
  container: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    color: 'white',
  },
  header: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
  },
  title: {
    backgroundColor: '#191922',
  },
  playlistThumbnail: {
    position: 'relative',
    width: '100%',
    paddingBottom: '56.25%',
    backgroundImage: "url('/assets/images/screenshot/app-home.png')",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'black',
    transition: '0.15s box-shadow ease-in-out',
    borderRadius: 8,
    '&:hover': {
      boxShadow: '0 0 10px 5px #ff3f81',
    },
  },
  playButtonContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    fontSize: 0,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  copyright: {
    textAlign: 'start',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: '50%',
    paddingRight: theme.spacing(4),
    '& .MuiTypography-subtitle2': {
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingRight: 0,
      marginBottom: theme.spacing(4),
    },
  },
  links: {
    textAlign: 'end',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '50%',
    '& .MuiGrid-container': {
      justifyContent: 'end',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    '& .MuiGrid-item': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      flexShrink: 1,
      '& .MuiGrid-container': {
        justifyContent: 'center',
      },
    },
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.header} container justifyContent="flex-start">
      <Grid item>
        <img alt="Logo" src="/assets/images/landing/logo.png" style={{ height: 40 }} />
      </Grid>
    </Grid>
  );
};

const Content: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: settings } = useSettings();
  const urls: ConfigurableUrls | undefined = settings?.[SETTING_CONFIGURABLE_URLS];

  const googlePlayUrl = urls?.['google_play_url'] || undefined;
  const appStoreUrl = urls?.['apple_app_store_url'] || undefined;
  const youtubePlaylistPageUrl = urls?.['youtube_playlist_url'] || undefined;

  const title = 'Data-driven future of transportation';
  const subtitle = 'Download the HTS@TN app and join our household travel survey!';

  return (
    <Grid
      container
      className={classes.content}
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}>
      <Grid item xs={12} md={7} lg={8}>
        <Box mb={4}>
          <Typography
            variant={isSmDown ? 'h4' : 'h3'}
            component="h1"
            gutterBottom
            style={{ textAlign: isSmDown ? 'center' : 'start' }}>
            <span className={classes.title}>{title}</span>
          </Typography>
          <Typography
            variant={isSmDown ? 'subtitle1' : 'h6'}
            component="p"
            gutterBottom
            style={{ textAlign: isSmDown ? 'center' : 'start' }}>
            <span className={classes.title}>{subtitle}</span>
          </Typography>
        </Box>
        <Grid container spacing={2} justifyContent={isSmDown ? 'center' : 'flex-start'}>
          <Grid item>
            <Button
              variant="outlined"
              color="inherit"
              href={googlePlayUrl}
              startIcon={<AndroidIcon style={{ fontSize: 40 }} />}>
              <Box textAlign="start">
                <Typography variant="body2">Get it on</Typography>
                <Typography variant="h6">Google Play</Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="inherit"
              href={appStoreUrl}
              startIcon={<AppleIcon style={{ fontSize: 40 }} />}>
              <Box textAlign="start">
                <Typography variant="body2">Download on the</Typography>
                <Typography variant="h6">App Store</Typography>
              </Box>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Box className={classes.playlistThumbnail} marginTop={4}>
          <Link
            color="error"
            title="Learn more on YouTube"
            href={youtubePlaylistPageUrl}
            className={classes.playButtonContainer}>
            <YouTubeIcon style={{ width: 80, height: 80 }} />
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

const Footer: React.FC = () => {
  const classes = useStyles();

  const { data: settings } = useSettings();
  const contactInfo: ContactInfo | undefined = settings?.[SETTING_CONTACT_INFO];
  const urls: ConfigurableUrls | undefined = settings?.[SETTING_CONFIGURABLE_URLS];

  const mailSubject = 'Questions about HTS@TN app';
  const mailUrl = `mailto:${contactInfo?.email}?subject=${encodeURIComponent(mailSubject)}`;
  const irbConsentUrl = urls?.['irb_consent_form_url'] || undefined;
  const twitterPageUrl = urls?.['twitter_url'] || undefined;
  const facebookPageUrl = urls?.['facebook_url'] || undefined;
  const instagramPageUrl = urls?.['instagram_url'] || undefined;

  return (
    <Box className={classes.footer}>
      <Box className={classes.copyright}>
        <Typography variant="subtitle2">NexusLab Apps &copy; {new Date().getFullYear()}</Typography>
        <Typography variant="caption">
          The HTS@TN mobile app was developed as part of the research project "Activity-based
          Household Travel Survey through Smartphone Apps in Tennessee," which is sponsored by
          Tennessee Department of Transportation (TDOT) with TDOT Project# RES2020-19.
        </Typography>
      </Box>
      <Box className={classes.links}>
        <Grid container spacing={2}>
          {twitterPageUrl ? (
            <Grid item>
              <Link color="inherit" href={twitterPageUrl} title="Twitter">
                <TwitterIcon />
              </Link>
            </Grid>
          ) : null}
          {facebookPageUrl ? (
            <Grid item>
              <Link color="inherit" href={facebookPageUrl} title="Facebook">
                <FacebookIcon />
              </Link>
            </Grid>
          ) : null}
          {instagramPageUrl ? (
            <Grid item>
              <Link color="inherit" href={instagramPageUrl} title="Instagram">
                <InstagramIcon />
              </Link>
            </Grid>
          ) : null}
          {mailUrl ? (
            <Grid item>
              <Link color="inherit" href={mailUrl} title="Contact Us">
                <MailIcon />
              </Link>
            </Grid>
          ) : null}
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="overline">
              <Link color="inherit" href={LANDING_FAQ}>
                FAQ
              </Link>
            </Typography>
          </Grid>
          {irbConsentUrl ? (
            <Grid item>
              <Typography variant="overline">
                <Link color="inherit" href={irbConsentUrl}>
                  IRB Consent Form
                </Link>
              </Typography>
            </Grid>
          ) : null}
          <Grid item>
            <Typography variant="overline">
              <Link color="inherit" href={LANDING_TERMS_OF_SERVICE}>
                Terms of Service
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="overline">
              <Link color="inherit" href={LANDING_PRIVACY_POLICY}>
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="overline">
              <Link color="inherit" href={LANDING_ACCEPTABLE_USE_POLICY}>
                Acceptable Use Policy
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const Index: React.FC = () => {
  const classes = useStyles();

  const backgroundRef = useRef(null);
  const [backgroundEffect, setBackgroundEffect] = useState<any>(null);

  useEffect(() => {
    if (!backgroundEffect) {
      setBackgroundEffect(
        GLOBE({
          THREE,
          el: backgroundRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: window.innerHeight,
          minWidth: window.innerWidth,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundColor: 0x191922,
        }),
      );
    }
    return () => {
      if (backgroundEffect) {
        backgroundEffect.destroy();
      }
    };
  }, [backgroundEffect]);

  return (
    <div className={classes.background} ref={backgroundRef}>
      <Container className={classes.containerWrapper}>
        <Box className={classes.container}>
          <Header />
          <Content />
          <Footer />
        </Box>
      </Container>
    </div>
  );
};

export default Index;
