import { Typography } from '@material-ui/core';
import React from 'react';

import DashItem, { DashItemProps } from './DashItem';

const DashItemText: React.FC<DashItemProps> = ({ children, ...props }) => {
  return (
    <DashItem {...props}>
      <Typography color="textPrimary" variant="body1" component="div">
        {children}
      </Typography>
    </DashItem>
  );
};

export default DashItemText;
