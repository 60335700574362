import { IonModal } from '@ionic/react';
import marked from 'marked';
import React from 'react';

import { useDocument } from '../../api/common';
import ModalLayout from '../../components/layouts/ModalLayout';

export interface PolicyPopupProps {
  name: string;
  title: string;
  isOpen: boolean;
  onDismiss?: () => void;
}

const PolicyPopup: React.FC<PolicyPopupProps> = ({ name, title, isOpen, onDismiss }) => {
  const { data } = useDocument(name);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDismiss}>
      <ModalLayout title={title} onDismiss={onDismiss}>
        {data ? <div dangerouslySetInnerHTML={{ __html: marked(data) }}></div> : null}
      </ModalLayout>
    </IonModal>
  );
};

export default PolicyPopup;
