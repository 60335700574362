import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { History } from 'history';
import React from 'react';
import { Redirect, Route } from 'react-router';

import { useToken } from '../../api/auth';
import { useIsSiteClosed } from '../../api/setting';
import Feedback from '../../pages/Feedback';
import Help from '../../pages/Help';
import Home from '../../pages/Home';
import Profile from '../../pages/Profile';
import Security from '../../pages/Security';
import Settings from '../../pages/Settings';
import SurveyClosed from '../../pages/SurveyClosed';
import AddressList from '../../pages/address/AddressList';
import ResetPassword from '../../pages/auth/ResetPassword';
import SignIn from '../../pages/auth/SignIn';
import SignUp from '../../pages/auth/SignUp';
import Complete from '../../pages/recruitment/Complete';
import Household from '../../pages/recruitment/Household';
import Member from '../../pages/recruitment/Member';
import MemberSummary from '../../pages/recruitment/MemberSummary';
import Status from '../../pages/recruitment/Status';
import Summary from '../../pages/recruitment/Summary';
import Vehicle from '../../pages/recruitment/Vehicle';
import VehicleSummary from '../../pages/recruitment/VehicleSummary';
import Welcome from '../../pages/recruitment/Welcome';
import Trip from '../../pages/trip/Trip';
import TripList from '../../pages/trip/TripList';
import TripMap from '../../pages/trip/TripMap';
import TripTracker from '../../pages/trip/TripTracker';
import {
  APP_ADDRESS_LIST,
  APP_FEEDBACK,
  APP_HELP,
  APP_HOME,
  APP_PASSWORD_REST,
  APP_PROFILE,
  APP_RECRUITMENT_COMPLETE,
  APP_RECRUITMENT_HOUSEHOLD,
  APP_RECRUITMENT_MEMBER,
  APP_RECRUITMENT_MEMBER_SUMMARY,
  APP_RECRUITMENT_STATUS,
  APP_RECRUITMENT_SUMMARY,
  APP_RECRUITMENT_VEHICLE,
  APP_RECRUITMENT_VEHICLE_SUMMARY,
  APP_RECRUITMENT_WELCOME,
  APP_SECURITY,
  APP_SETTINGS,
  APP_SIGNIN,
  APP_SIGNUP,
  APP_TRIP,
  APP_TRIP_LIST,
  APP_TRIP_MAP,
  APP_TRIP_TRACKER,
} from '../../urls';

const Router: React.FC<{ history: History }> = ({ history }) => {
  const isSiteClosed = useIsSiteClosed();
  const { data: token, isLoading } = useToken();

  if (isSiteClosed) {
    return <SurveyClosed />;
  }

  if (isLoading) {
    return null;
  }

  if (!token) {
    return (
      <IonReactRouter history={history}>
        <IonRouterOutlet>
          <Route exact path={APP_SIGNIN} component={SignIn} />
          <Route exact path={APP_SIGNUP} component={SignUp} />
          <Route exact path={APP_PASSWORD_REST} component={ResetPassword} />
        </IonRouterOutlet>
        <Route render={() => <Redirect to={APP_SIGNIN} />} />
      </IonReactRouter>
    );
  }

  return (
    <IonReactRouter history={history}>
      <IonRouterOutlet>
        <Route exact path={APP_HOME} component={Home} />
        <Route exact path={APP_PROFILE} component={Profile} />
        <Route exact path={APP_SECURITY} component={Security} />
        <Route exact path={APP_RECRUITMENT_WELCOME} component={Welcome} />
        <Route exact path={APP_RECRUITMENT_SUMMARY} component={Complete} />
        <Route exact path={APP_RECRUITMENT_HOUSEHOLD} component={Household} />
        <Route exact path={APP_RECRUITMENT_MEMBER} component={Member} />
        <Route exact path={APP_RECRUITMENT_MEMBER_SUMMARY} component={MemberSummary} />
        <Route exact path={APP_RECRUITMENT_VEHICLE} component={Vehicle} />
        <Route exact path={APP_RECRUITMENT_VEHICLE_SUMMARY} component={VehicleSummary} />
        <Route exact path={APP_RECRUITMENT_SUMMARY} component={Summary} />
        <Route exact path={APP_RECRUITMENT_COMPLETE} component={Complete} />
        <Route exact path={APP_RECRUITMENT_STATUS} component={Status} />
        <Route exact path={APP_TRIP_LIST} component={TripList} />
        <Route exact path={APP_TRIP_MAP} component={TripMap} />
        <Route exact path={APP_TRIP} component={Trip} />
        <Route exact path={APP_TRIP_TRACKER} component={TripTracker} />
        <Route exact path={APP_ADDRESS_LIST} component={AddressList} />
        <Route exact path={APP_SETTINGS} component={Settings} />
        <Route exact path={APP_FEEDBACK} component={Feedback} />
        <Route exact path={APP_HELP} component={Help} />
      </IonRouterOutlet>
      <Route render={() => <Redirect to={APP_HOME} />} />
    </IonReactRouter>
  );
};

export default Router;
