import { FeatureMode, FilterModelParams } from '@material-ui/data-grid';
import { useState } from 'react';

import { FilterOptions } from '../../api/common';

interface FilterProps {
  filterMode: FeatureMode;
  onFilterModelChange: (param: FilterModelParams) => void;
}

export const defaultFilterFactory = (params: FilterModelParams) => {
  const filter: FilterOptions = {};
  const item = params.filterModel.items.length ? params.filterModel.items[0] : undefined;
  const column = params.columns.find((column) => column.field === item?.columnField);
  const operator = column?.filterOperators?.find(
    (operator) => operator.value === item?.operatorValue,
  );
  if (!item || !item.columnField || !item.value || !column || !operator) {
    return filter;
  }
  let relation;
  let filterName;
  switch (operator?.value) {
    case 'equals':
    case 'contains':
      filter[item.columnField] = item.value;
      break;
    case 'select':
      if (item.value !== '') {
        filter[item.columnField] = item.value;
      }
      break;
    case 'numberGeq':
    case 'numberLt':
      relation = operator.value.slice(6);
      filter[item.columnField + relation] = item.value;
      break;
    case 'datetimeBefore':
    case 'datetimeAfter':
      relation = operator.value.slice(8);
      filterName = item.columnField.endsWith('At')
        ? item.columnField.slice(0, item.columnField.length - 2)
        : item.columnField;
      filter[filterName + relation] = item.value;
      break;
  }
  return filter;
};

export default function useFilter(
  filterFactory = defaultFilterFactory,
): [FilterOptions, FilterProps] {
  const [filter, setFilter] = useState<FilterOptions>({});

  const onSortChange = (params: FilterModelParams) => setFilter(filterFactory(params));

  return [
    filter,
    {
      filterMode: 'server' as FeatureMode,
      onFilterModelChange: onSortChange,
    },
  ];
}
