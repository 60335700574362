import { InputChangeEventDetail, SelectChangeEventDetail } from '@ionic/core';
import { IonAlert, IonButton, IonList, IonPage } from '@ionic/react';
import { isNumber } from 'class-validator';
import { helpCircleOutline } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { isHouseholdReadOnly, useSelfHousehold } from '../../api/household';
import {
  useDegrees,
  useEducationLevels,
  useEmploymentStatuses,
  useGenders,
  useMaritalStatuses,
  useMember,
  useMemberCreate,
  useMemberUpdate,
  useOccupations,
  useRaces,
  useRelationships,
  useSelfMember,
} from '../../api/member';
import { useSelfUser } from '../../api/user';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import AddonButton from '../../components/forms/AddonButton';
import BinarySelect from '../../components/forms/BinarySelect';
import ItemInput from '../../components/forms/ItemInput';
import LoadableKvSelect from '../../components/forms/LoadableKvSelect';
import FormLayout from '../../components/layouts/FormLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import { useEditState } from '../../utils/state';

const Member: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const isCreatingMember = !Boolean(id);

  const { data: relationships, status: relationshipStatus } = useRelationships();
  const { data: genders, status: genderStatus } = useGenders();
  const { data: races, status: raceStatus } = useRaces();
  const { data: maritalStatuses, status: maritalStatusStatus } = useMaritalStatuses();
  const { data: degrees, status: degreeStatus } = useDegrees();
  const { data: educationLevels, status: educationLevelStatus } = useEducationLevels();
  const { data: employmentStatuses, status: employmentStatusStatus } = useEmploymentStatuses();
  const { data: occupations, status: occupationStatus } = useOccupations();

  const [create, resCreate] = useMemberCreate({ onSuccess: () => history.goBack() });
  const [update, resUpdate] = useMemberUpdate({ onSuccess: () => history.goBack() });

  const { data: user } = useSelfUser();
  const { data: self } = useSelfMember();
  const { data: household } = useSelfHousehold();
  const { data: member, ...resQuery } = useMember(id, {
    onSuccess: (member_) => {
      setName(member_?.name, true);
      setAge(member_?.age, true);
      setRelationship(member_?.relationship?.id, true);
      setGender(member_?.gender?.id, true);
      setIsHispanic(member_?.isHispanic, true);
      setRace(member_?.race?.id, true);
      setImmigrationStatus(member_?.immigrationStatus?.id, true);
      setMaritalStatus(member_?.maritalStatus?.id, true);
      setHighestDegree(member_?.highestDegree?.id, true);
      setCurrentEducationLevel(member_?.currentEducationLevel?.id, true);
      setEmploymentStatus(member_?.employmentStatus?.id, true);
      setOccupation(member_?.occupation?.id, true);
      setJobCount(member_?.jobCount, true);
      setIsWorkForProfit(member_?.isWorkForProfit, true);
      setIsWorkFromHome(member_?.isWorkFromHome, true);
      setIsSeasonalWorker(member_?.isSeasonalWorker, true);
    },
  });

  const [isRelationshipTipVisible, setIsRelationshipTipVisible] = useState<boolean>(false);

  const [name, setName] = useEditState(member?.name);
  const [age, setAge] = useEditState(member?.age);
  const [relationship, setRelationship] = useEditState(member?.relationship?.id);
  const [gender, setGender] = useEditState(member?.gender?.id);
  const [isHispanic, setIsHispanic] = useEditState(member?.isHispanic);
  const [race, setRace] = useEditState(member?.race?.id);
  const [immigrationStatus, setImmigrationStatus] = useEditState(member?.immigrationStatus?.id);
  const [maritalStatus, setMaritalStatus] = useEditState(member?.maritalStatus?.id);
  const [highestDegree, setHighestDegree] = useEditState(member?.highestDegree?.id);
  const [currentEducationLevel, setCurrentEducationLevel] = useEditState(
    member?.currentEducationLevel?.id,
  );
  const [employmentStatus, setEmploymentStatus] = useEditState(member?.employmentStatus?.id);
  const [occupation, setOccupation] = useEditState(member?.occupation?.id);
  const [jobCount, setJobCount] = useEditState(member?.jobCount);
  const [isWorkForProfit, setIsWorkForProfit] = useEditState(member?.isWorkForProfit);
  const [isWorkFromHome, setIsWorkFromHome] = useEditState(member?.isWorkFromHome);
  const [isSeasonalWorker, setIsSeasonalWorker] = useEditState(member?.isSeasonalWorker);

  const onNameChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setName(e.detail.value!);
  };

  const onAgeChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setAge(value);
    } else {
      setAge(null);
    }
  };

  const onRelationshipChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setRelationship(e.detail.value!);
  };

  const onGenderChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setGender(e.detail.value!);
  };

  const onIsHispanicChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setIsHispanic(e.detail.value!);
  };

  const onRaceChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setRace(e.detail.value!);
  };

  const onMaritalStatusChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setMaritalStatus(e.detail.value!);
  };

  const onHighestDegreeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setHighestDegree(e.detail.value!);
  };

  const onCurrentEducationLevelChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setCurrentEducationLevel(e.detail.value!);
  };

  const onEmploymentStatusChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setEmploymentStatus(e.detail.value!);
  };

  const onOccupationChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setOccupation(e.detail.value!);
  };

  const onJobCountChange = (e: CustomEvent<InputChangeEventDetail>) => {
    const value = parseInt(e.detail.value!);
    if (!isNaN(value)) {
      setJobCount(value);
    } else {
      setJobCount(null);
    }
  };

  const onIsWorkForProfitChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setIsWorkForProfit(e.detail.value!);
  };

  const onIsWorkFromHomeChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setIsWorkFromHome(e.detail.value!);
  };

  const onIsSeasonalWorkerChange = (e: CustomEvent<SelectChangeEventDetail>) => {
    setIsSeasonalWorker(e.detail.value!);
  };

  const onSubmit = () => {
    const updates = {
      name,
      relationship,
      age,
      gender,
      isHispanic,
      race,
      immigrationStatus,
      maritalStatus,
      highestDegree,
      currentEducationLevel,
      employmentStatus,
      occupation,
      jobCount,
      isWorkForProfit,
      isWorkFromHome,
      isSeasonalWorker,
    };
    if (isCreatingMember) {
      create(updates);
    } else if (member) {
      update({ id: member.id, ...updates });
    }
  };

  const isLoading =
    (resQuery.isInitialData && resQuery.isFetching) || resCreate.isLoading || resUpdate.isLoading;
  const isHouseholdOwner = user?.householdOwnedId && user?.householdOwnedId === household?.id;
  const isReadOnly = !isHouseholdOwner || isHouseholdReadOnly(household);
  const isFormValid = Boolean(name) && isNumber(age);
  const canSubmit = !isLoading && !isReadOnly && isFormValid;

  const isSelf = member?.id === self?.id;
  const ownerRelationshipId = relationships?.find(
    (rel) => rel.title.toLowerCase() === 'household owner',
  )?.id;

  const relationships_ = useMemo(
    () =>
      relationships
        ?.filter(
          (rel) => (isSelf && isHouseholdOwner) === (rel.title.toLowerCase() === 'household owner'),
        )
        .map((rel) =>
          rel.title.toLowerCase() !== 'household owner' ? rel : { ...rel, title: 'Self' },
        ) ?? [],
    [isSelf, isHouseholdOwner, relationships],
  );

  useEffect(() => {
    if (isSelf && isHouseholdOwner && typeof ownerRelationshipId === 'number') {
      setRelationship(ownerRelationshipId);
    }
  }, [isSelf, isHouseholdOwner, ownerRelationshipId, setRelationship]);

  const title = 'Member';
  const subtitle = isReadOnly
    ? 'View member information'
    : isSelf
    ? 'Tell us about yourself.'
    : 'Tell us about the people you live with.';

  return (
    <IonPage>
      <FormLayout title={title} subtitle={subtitle}>
        <IonList className="input-group">
          <ItemInput
            label="Name"
            required
            value={name}
            readonly={isReadOnly}
            onChange={onNameChange}
          />
          <ItemInput
            type="number"
            label="Age"
            required
            value={age}
            readonly={isReadOnly}
            onChange={onAgeChange}
          />
          <LoadableKvSelect
            label="Relationship"
            value={relationship}
            entries={relationships_}
            readonly={isReadOnly || isSelf}
            status={relationshipStatus}
            onChange={onRelationshipChange}
            addons={
              <AddonButton
                icon={helpCircleOutline}
                onClick={() => setIsRelationshipTipVisible(true)}
              />
            }
          />
          <LoadableKvSelect
            label="Gender"
            value={gender}
            entries={genders}
            readonly={isReadOnly}
            status={genderStatus}
            onChange={onGenderChange}
          />
          <BinarySelect
            label="Hispanic or Latino Origin"
            value={isHispanic}
            readonly={isReadOnly}
            onChange={onIsHispanicChange}
          />
          <LoadableKvSelect
            label="Race"
            value={race}
            entries={races}
            readonly={isReadOnly}
            status={raceStatus}
            onChange={onRaceChange}
          />
          <LoadableKvSelect
            label="Marital Status"
            value={maritalStatus}
            entries={maritalStatuses}
            readonly={isReadOnly}
            status={maritalStatusStatus}
            onChange={onMaritalStatusChange}
          />
          <LoadableKvSelect
            label="Highest Level of Education"
            value={highestDegree}
            entries={degrees}
            readonly={isReadOnly}
            status={degreeStatus}
            onChange={onHighestDegreeChange}
          />
          <LoadableKvSelect
            label="Current Level of Education"
            value={currentEducationLevel}
            entries={educationLevels}
            readonly={isReadOnly}
            status={educationLevelStatus}
            onChange={onCurrentEducationLevelChange}
          />
          <LoadableKvSelect
            label="Employment Status"
            value={employmentStatus}
            entries={employmentStatuses}
            readonly={isReadOnly}
            status={employmentStatusStatus}
            onChange={onEmploymentStatusChange}
          />
          <LoadableKvSelect
            label="Primary Occupation"
            value={occupation}
            entries={occupations}
            readonly={isReadOnly}
            status={occupationStatus}
            onChange={onOccupationChange}
          />
          <ItemInput
            type="number"
            label="Current Number of Jobs"
            value={jobCount}
            readonly={isReadOnly}
            onChange={onJobCountChange}
          />
          <BinarySelect
            label="Work for Pay or Profit"
            value={isWorkForProfit}
            readonly={isReadOnly}
            onChange={onIsWorkForProfitChange}
          />
          <BinarySelect
            label="Work from Home"
            value={isWorkFromHome}
            readonly={isReadOnly}
            onChange={onIsWorkFromHomeChange}
          />
          <BinarySelect
            label="Seasonal Worker"
            value={isSeasonalWorker}
            readonly={isReadOnly}
            onChange={onIsSeasonalWorkerChange}
          />
        </IonList>
      </FormLayout>
      {!isReadOnly ? (
        <ButtonSheet>
          <IonButton shape="round" expand="block" onClick={onSubmit} disabled={!canSubmit}>
            {isLoading ? 'Loading...' : 'Save'}
          </IonButton>
        </ButtonSheet>
      ) : null}
      <IonAlert
        isOpen={isRelationshipTipVisible}
        onDidDismiss={() => setIsRelationshipTipVisible(false)}
        message={`Enter this person's relationship to the household owner, including
          biological, adopted, and step relationships.`}
        buttons={['OK']}
      />
      <QueryResultToast result={isCreatingMember ? resCreate : resUpdate}>
        Member information saved.
      </QueryResultToast>
    </IonPage>
  );
};

export default Member;
