import { Breadcrumbs, Grid, Link, Typography, makeStyles } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React from 'react';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import { useFeedback } from '../../api/feedback';
import LoadingOrFail from '../../components/containers/LoadingOrFail';
import DashItemText from '../../components/forms/DashItemText';
import DashboardLayout from '../../components/layouts/DashboardLayout';
import DashboardHeader from '../../components/typography/DashboardHeader';
import { DASHBOARD_FEEDBACK_LIST, DASHBOARD_USER } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const useStyles = makeStyles({
  item: {
    '& div.MuiTypography-root': {
      height: '1.5em',
    },
  },
});

const FeedbackDetails: React.FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data: feedback, isError } = useFeedback(id, { enabled: Boolean(id) });

  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Link
            color="inherit"
            variant="overline"
            component={RouterLink}
            to={DASHBOARD_FEEDBACK_LIST}>
            Feedback
          </Link>
          <Typography color="textPrimary" variant="overline">
            {id}
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          Feedback Details
        </Typography>
      </DashboardHeader>
      <LoadingOrFail isLoading={!feedback} isError={isError}>
        {feedback ? (
          <Grid container spacing={2}>
            <DashItemText xs={12} md={6} title="ID">
              {id}
            </DashItemText>
            <DashItemText xs={12} md={6} title="User">
              <Link
                component={RouterLink}
                to={DASHBOARD_USER.replace(':id', feedback.userId ?? '')}>
                {feedback.userId}
              </Link>
            </DashItemText>
            <DashItemText
              classes={{ root: classes.item }}
              xs={12}
              sm={6}
              md={4}
              title="Overall Rating">
              <Rating precision={1} value={feedback.overallRating ?? 0} readOnly />
            </DashItemText>
            <DashItemText
              classes={{ root: classes.item }}
              xs={12}
              sm={6}
              md={4}
              title="Functionality Rating">
              <Rating precision={1} value={feedback.functionalityRating ?? 0} readOnly />
            </DashItemText>
            <DashItemText
              classes={{ root: classes.item }}
              xs={12}
              sm={6}
              md={4}
              title="Usability Rating">
              <Rating precision={1} value={feedback.usabilityRating ?? 0} readOnly />
            </DashItemText>
            <DashItemText
              classes={{ root: classes.item }}
              xs={12}
              sm={6}
              md={4}
              title="Stability Rating">
              <Rating precision={1} value={feedback.stabilityRating ?? 0} readOnly />
            </DashItemText>
            <DashItemText
              classes={{ root: classes.item }}
              xs={12}
              sm={6}
              md={4}
              title="Look and Feel Rating">
              <Rating precision={1} value={feedback.lookAndFeelRating ?? 0} readOnly />
            </DashItemText>
            <DashItemText xs={12} sm={6} md={4} title="Created At">
              {humanizeIsoDate(feedback.createdAt)}
            </DashItemText>
            <DashItemText xs={12} title="User Comment">
              {feedback.userComment ?? '(Not set)'}
            </DashItemText>
          </Grid>
        ) : null}
      </LoadingOrFail>
    </DashboardLayout>
  );
};

export default FeedbackDetails;
