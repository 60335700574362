import { Box, CircularProgress, Typography } from '@material-ui/core';
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons';
import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { QueryStatus } from 'react-query';
import { useLocation } from 'react-router';

import { useVerifyEmail } from '../../api/auth';
import ImageLanding from '../../components/layouts/ImageLanding';
import { getErrorMessage } from '../../utils/error';

const EmailVerification: React.FC = () => {
  const location = useLocation();

  const [verify, { status, error }] = useVerifyEmail();

  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      verify(token);
    }
  }, [token, verify]);

  let subtitle = '';
  let icon = null;
  switch (status) {
    case QueryStatus.Success:
      icon = <CheckCircleOutline color="primary" />;
      subtitle = 'Your email address has been verified';
      break;
    case QueryStatus.Loading:
      icon = <CircularProgress color="inherit" size={20} />;
      subtitle = 'Verifying your email address...';
      break;
    case QueryStatus.Error:
      icon = <ErrorOutline color="error" />;
      subtitle = 'Failed to verify your email address. ';
      switch ((error as AxiosError).response?.status) {
        case 400:
          subtitle += 'The verification token is invalid.';
          break;
        case 403:
          subtitle += 'Survey has ended.';
          break;
        default:
          subtitle += getErrorMessage(error);
          break;
      }
      break;
    case QueryStatus.Idle:
      if (!token) {
        icon = <ErrorOutline color="error" />;
        subtitle = 'Invalid email verification link.';
      }
      break;
  }

  return (
    <ImageLanding
      title={
        <Box display="flex" flexDirection="row" alignItems="center" mb={1}>
          <Box mr={1} component="span" display="flex" flexDirection="row" alignItems="center">
            {icon}
          </Box>
          <Typography color="textPrimary" component="h1" variant="h5">
            Email Verification
          </Typography>
        </Box>
      }
      subtitle={subtitle}
      image="/assets/images/landing/clip-e-mail-marketing-campaign.svg"
    />
  );
};

export default EmailVerification;
