import * as owasp from 'owasp-password-strength-test';

owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 10,
  minPhraseLength: 20,
  minOptionalTestsToPass: 4,
});

export default function isPassword(value?: string | null) {
  if (!value) {
    return false;
  }
  const { errors } = owasp.test(value);
  return errors.length === 0;
}
