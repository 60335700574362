import { IonAlert, IonCard, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { mailOutline } from 'ionicons/icons';
import React, { useState } from 'react';

import { useResendEmail } from '../../api/auth';
import QueryResultToast from '../notification/QueryResultToast';

const VerifyEmailCard: React.FC = () => {
  const [resend, { status, error, reset }] = useResendEmail();
  const [isResendPromptVisible, setIsResendPromptVisible] = useState(false);

  const onResendEmailClick = () => {
    resend();
  };

  return (
    <>
      <IonCard onClick={() => setIsResendPromptVisible(true)}>
        <IonItem button detail lines="none" color="warning">
          <IonIcon icon={mailOutline} slot="start" style={{ marginRight: 'var(--ion-margin)' }} />
          <IonLabel>Please verify your email address</IonLabel>
        </IonItem>
      </IonCard>
      <IonAlert
        isOpen={isResendPromptVisible}
        onDidDismiss={() => setIsResendPromptVisible(false)}
        header={'Resend verification email'}
        message={'Do you want to resend verification email?'}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            handler: () => setIsResendPromptVisible(false),
          },
          {
            text: 'Yes',
            handler: onResendEmailClick,
          },
        ]}
      />
      <QueryResultToast result={{ status, error, reset }}>
        Verification email sent. Please check your inbox.
      </QueryResultToast>
    </>
  );
};

export default VerifyEmailCard;
