import { IonCard, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { mailOutline } from 'ionicons/icons';
import React from 'react';

const AccountDisabledCard: React.FC = () => {
  return (
    <IonCard>
      <IonItem lines="none" color="danger">
        <IonIcon icon={mailOutline} slot="start" style={{ marginRight: 'var(--ion-margin)' }} />
        <IonLabel>Your account has been disabled</IonLabel>
      </IonItem>
    </IonCard>
  );
};

export default AccountDisabledCard;
