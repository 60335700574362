import {
  InfiniteQueryConfig,
  MutationConfig,
  QueryObserverConfig,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryCache,
} from 'react-query';

import { useLocalCache } from '../components/LocalCache';
import {
  API_MEMBER,
  API_MEMBERS,
  API_MEMBER_DEGREES,
  API_MEMBER_EDUCATION_LEVELS,
  API_MEMBER_EMPLOYMENT_STATUSES,
  API_MEMBER_GENDERS,
  API_MEMBER_IMMIGRATION_STATUSES,
  API_MEMBER_INVITE,
  API_MEMBER_MARITAL_STATUSES,
  API_MEMBER_OCCUPATIONS,
  API_MEMBER_RACES,
  API_MEMBER_RELATIONSHIPS,
} from '../urls';
import { useApi } from './base';
import { FilterOptions, LookupValue, Pagination, Timestamp, useLookupValues } from './common';
import { Household } from './household';

export const UNNAMED_MEMBER = '(Unnamed member)';

export interface Member extends Timestamp {
  id: string;
  householdId: string;
  userId: string | null;
  name: string | null;
  relationship: LookupValue | null;
  age: number | null;
  gender: LookupValue | null;
  isHispanic: boolean | null;
  race: LookupValue | null;
  immigrationStatus: LookupValue | null;
  maritalStatus: LookupValue | null;
  highestDegree: LookupValue | null;
  currentEducationLevel: LookupValue | null;
  employmentStatus: LookupValue | null;
  occupation: LookupValue | null;
  jobCount: number | null;
  isWorkForProfit: boolean | null;
  isWorkFromHome: boolean | null;
  isSeasonalWorker: boolean | null;
  invite: LookupValue | null;
}

export interface MemberCreate {
  name?: string | null;
  relationship?: number | null;
  age?: number | null;
  gender?: number | null;
  isHispanic?: boolean | null;
  race?: number | null;
  immigrationStatus?: number | null;
  maritalStatus?: number | null;
  highestDegree?: number | null;
  currentEducationLevel?: number | null;
  employmentStatus?: number | null;
  occupation?: number | null;
  jobCount?: number | null;
  isWorkForProfit?: boolean | null;
  isWorkFromHome?: boolean | null;
  isSeasonalWorker?: boolean | null;
}

export interface MemberUpdate extends Partial<MemberCreate> {
  id: string;
}

export interface MemberInviteCreate {
  id: string;
  email: string;
}

export function useRelationships() {
  return useLookupValues('member-relationships', API_MEMBER_RELATIONSHIPS);
}

export function useGenders() {
  return useLookupValues('member-genders', API_MEMBER_GENDERS);
}

export function useRaces() {
  return useLookupValues('member-races', API_MEMBER_RACES);
}

export function useImmigrationStatuses() {
  return useLookupValues('member-immigration-statuses', API_MEMBER_IMMIGRATION_STATUSES);
}

export function useMaritalStatuses() {
  return useLookupValues('member-marital-statuses', API_MEMBER_MARITAL_STATUSES);
}

export function useDegrees() {
  return useLookupValues('member-degrees', API_MEMBER_DEGREES);
}

export function useEducationLevels() {
  return useLookupValues('member-education-levels', API_MEMBER_EDUCATION_LEVELS);
}

export function useEmploymentStatuses() {
  return useLookupValues('member-employment-statues', API_MEMBER_EMPLOYMENT_STATUSES);
}

export function useOccupations() {
  return useLookupValues('member-occupations', API_MEMBER_OCCUPATIONS);
}

export function useMember(id: string, config?: QueryObserverConfig<Member>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useQuery(
    ['member', id],
    async (key: string, id: string) => {
      const { data } = await api.get<Member>(API_MEMBER(id));
      return data;
    },
    {
      enabled: enabled ?? Boolean(id),
      initialData: initialData ?? (() => store.get(['member', id])),
      onSuccess: (data) => {
        store.set(['member', id], data);
        if (data && data.id !== id) {
          cache.setQueryData(['member', data.id], data);
          store.set(['member', data.id], data);
        }
        onSuccess?.(data);
      },
      ...config_,
    },
  );
}

export function useSelfMember(config?: QueryObserverConfig<Member>) {
  return useMember('self', config);
}

export function useMembers(household?: string, config?: InfiniteQueryConfig<Pagination<Member>>) {
  const api = useApi();
  const store = useLocalCache();
  const { enabled, initialData, onSuccess, ...config_ } = config ?? {};

  return useInfiniteQuery(
    ['members', { household }],
    async (key: string, filters?: FilterOptions, nextPage?: number) => {
      const { data } = await api.get<Pagination<Member>>(API_MEMBERS, {
        params: { ...filters, page: nextPage },
      });
      return data;
    },
    {
      enabled: enabled ?? Boolean(household),
      initialData: initialData ?? (() => store.get(['members', { household }])),
      onSuccess(data) {
        if (data && data.length === 1) {
          // only cache first page
          store.set(['members', { household }], data);
        }
        onSuccess?.(data);
      },
      getFetchMore(lastPage) {
        return lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : 0;
      },
      ...config_,
    },
  );
}

export function useMemberCreate(config?: MutationConfig<Member, unknown, MemberCreate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (member: MemberCreate) => {
      const { data } = await api.post<Member>(API_MEMBERS, member);
      return data;
    },
    {
      onSuccess: async (member, variables) => {
        cache.setQueryData(['member', member.id], member);
        store.set(['member', member.id], member);
        const selfMember = cache.getQueryData<Member>(['member', 'self']);
        if (!selfMember?.id) {
          cache.invalidateQueries(['member', 'self']);
          store.remove(['member', 'self']);
        }
        cache.invalidateQueries(['members']);
        store.remove(['members']);
        onSuccess?.(member, variables);
      },
      ...config_,
    },
  );
}

export function useMemberUpdate(config?: MutationConfig<Member, unknown, MemberUpdate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (member: MemberUpdate) => {
      const { data } = await api.put<Member>(API_MEMBER(member.id), member);
      return data;
    },
    {
      onSuccess: async (member, variables) => {
        cache.setQueryData(['member', member.id], member);
        store.set(['member', member.id], member);
        const selfMember = cache.getQueryData<Household>(['member', 'self']);
        if (member?.id === selfMember?.id) {
          cache.setQueryData(['member', 'self'], member);
          store.set(['member', 'self'], member);
        }
        cache.invalidateQueries(['members']);
        store.remove(['members']);
        onSuccess?.(member, variables);
      },
      ...config_,
    },
  );
}

export function useMemberDelete(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      await api.delete(API_MEMBER(id));
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['member', id]);
        store.remove(['member', id]);
        const selfMember = cache.getQueryData<Member>(['member', 'self']);
        if (id === selfMember?.id) {
          cache.invalidateQueries(['member', 'self']);
          store.remove(['member', 'self']);
        }
        cache.invalidateQueries(['members']);
        store.remove(['members']);
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}

export function useSendMemberInvite(config?: MutationConfig<void, unknown, MemberInviteCreate>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async ({ id, email }: MemberInviteCreate) => {
      await api.post<Member>(API_MEMBER_INVITE(id), { email });
    },
    {
      onSuccess: async (_, invite) => {
        cache.invalidateQueries(['member', invite.id]);
        store.remove(['member', invite.id]);
        const selfMember = cache.getQueryData<Member>(['member', 'self']);
        if (invite.id === selfMember?.id) {
          cache.invalidateQueries(['member', 'self']);
          store.remove(['member', 'self']);
        }
        cache.invalidateQueries(['members']);
        store.remove(['members']);
        onSuccess?.(_, invite);
      },
      ...config_,
    },
  );
}

export function useAcceptMemberInvite(
  config?: MutationConfig<void, unknown, { token: string; member: string }>,
) {
  const api = useApi();

  return useMutation(async ({ token, member }: { token: string; member: string }) => {
    await api.get<void>(API_MEMBER_INVITE(member), { params: { token } });
  }, config);
}

export function useRemoveMemberInvite(config?: MutationConfig<void, unknown, string>) {
  const api = useApi();
  const store = useLocalCache();
  const cache = useQueryCache();
  const { onSuccess, ...config_ } = config ?? {};

  return useMutation(
    async (id: string) => {
      await api.delete<Member>(API_MEMBER_INVITE(id));
    },
    {
      onSuccess: async (_, id) => {
        cache.invalidateQueries(['member', id]);
        store.remove(['member', id]);
        const selfMember = cache.getQueryData<Member>(['member', 'self']);
        if (id === selfMember?.id) {
          cache.invalidateQueries(['member', 'self']);
          store.remove(['member', 'self']);
        }
        cache.invalidateQueries(['members']);
        store.remove(['members']);
        onSuccess?.(_, id);
      },
      ...config_,
    },
  );
}
