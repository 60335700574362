import { IonContent, IonPage } from '@ionic/react';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import Subtitle from '../components/typography/Subtitle';
import Title from '../components/typography/Title';

const useStyles = makeStyles({
  container: {
    '&::part(background)': {
      background:
        'url(/assets/images/landing/clip-finish-line.svg) center calc(100% + 25px) / 400px no-repeat',
    },
  },
});

const SurveyClosed: React.FC = () => {
  const classes = useStyles();

  return (
    <IonPage>
      <IonContent fullscreen className={['display', classes.container].join(' ')}>
        <Title>Survey Closed</Title>
        <Subtitle>Thank you for your participation.</Subtitle>
      </IonContent>
    </IonPage>
  );
};

export default SurveyClosed;
