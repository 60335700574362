import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  Menu,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  DriveEta,
  EmojiPeople,
  ExitToApp,
  ExpandMore,
  Feedback,
  Flight,
  Home,
  LocationOn,
  Menu as MenuIcon,
  NightsStay,
  People,
  Settings,
  TableChart,
  WbSunny,
} from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { useSignOut, useToken } from '../../api/auth';
import { User, useSelfUser } from '../../api/user';
import {
  DASHBOARD_ADDRESS_LIST,
  DASHBOARD_FEEDBACK_LIST,
  DASHBOARD_HOUSEHOLD_LIST,
  DASHBOARD_MEMBER_LIST,
  DASHBOARD_SETTINGS,
  DASHBOARD_TRIP_LIST,
  DASHBOARD_USER_LIST,
  DASHBOARD_VEHICLE_LIST,
} from '../../urls';
import { ThemeContext } from '../ThemeProvider';
import IconMenuItem from '../buttons/IconMenuItem';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid ' + theme.palette.divider,
    '& .MuiButtonBase-root': {
      textTransform: 'none',
    },
    '& .MuiToolbar-root': {
      marginRight: theme.spacing(-1),
    },
  },
  logo: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
  },
  menu: {
    marginLeft: theme.spacing(4),
    '& .MuiButtonBase-root': {
      marginRight: theme.spacing(1),
    },
  },
  menuSubheader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
  },
}));

const DarkModeSwitch: React.FC = () => {
  const { mode, setMode } = useContext(ThemeContext);

  const onDarkModeToggle = () => {
    setMode?.(mode === 'dark' ? 'light' : 'dark');
  };

  return (
    <IconButton color="inherit" onClick={onDarkModeToggle}>
      {mode === 'dark' ? <NightsStay fontSize="small" /> : <WbSunny fontSize="small" />}
    </IconButton>
  );
};

const DesktopMainMenu: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [dataMenuAnchor, setDataMenuAnchor] = useState<null | HTMLElement>(null);

  const onDataMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDataMenuAnchor(event.currentTarget);
  };

  const onDataMenuClose = () => {
    setDataMenuAnchor(null);
  };

  const redirect = (url: string) => {
    onDataMenuClose();
    history.push(url);
  };

  return (
    <Box className={classes.menu}>
      <Button color="inherit" startIcon={<People />} onClick={() => redirect(DASHBOARD_USER_LIST)}>
        Users
      </Button>
      <Button
        color="inherit"
        startIcon={<TableChart />}
        endIcon={<ExpandMore />}
        onClick={onDataMenuClick}>
        Data
      </Button>
      <Menu
        keepMounted
        anchorEl={dataMenuAnchor}
        open={Boolean(dataMenuAnchor)}
        onClose={onDataMenuClose}>
        <IconMenuItem
          icon={<Flight fontSize="small" />}
          text="Trips"
          onClick={() => redirect(DASHBOARD_TRIP_LIST)}
        />
        <IconMenuItem
          icon={<Home fontSize="small" />}
          text="Households"
          onClick={() => redirect(DASHBOARD_HOUSEHOLD_LIST)}
        />
        <IconMenuItem
          icon={<EmojiPeople fontSize="small" />}
          text="Members"
          onClick={() => redirect(DASHBOARD_MEMBER_LIST)}
        />
        <IconMenuItem
          icon={<DriveEta fontSize="small" />}
          text="Vehicles"
          onClick={() => redirect(DASHBOARD_VEHICLE_LIST)}
        />
        <IconMenuItem
          icon={<LocationOn fontSize="small" />}
          text="Addresses"
          onClick={() => redirect(DASHBOARD_ADDRESS_LIST)}
        />
        <IconMenuItem
          icon={<Feedback fontSize="small" />}
          text="Feedback"
          onClick={() => redirect(DASHBOARD_FEEDBACK_LIST)}
        />
      </Menu>
      <Button color="inherit" startIcon={<Settings />} onClick={() => redirect(DASHBOARD_SETTINGS)}>
        Settings
      </Button>
    </Box>
  );
};

const DesktopUserMenu: React.FC<{ user: User }> = ({ user }) => {
  const [signOut] = useSignOut();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <Box>
      <Button color="inherit" endIcon={<ExpandMore fontSize="small" />} onClick={onMenuClick}>
        {user.email}
      </Button>
      <Menu
        keepMounted
        anchorEl={menuAnchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(menuAnchor)}
        onClose={onMenuClose}>
        <IconMenuItem
          icon={<ExitToApp fontSize="small" />}
          text="Sign out"
          onClick={() => {
            onMenuClose();
            signOut();
          }}
        />
      </Menu>
    </Box>
  );
};

const MobileMenu: React.FC<{ user: User }> = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();

  const [signOut] = useSignOut();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuAnchor(null);
  };

  const redirect = (url: string) => {
    onMenuClose();
    history.push(url);
  };

  return (
    <Box>
      <IconButton onClick={onMenuClick}>
        <MenuIcon />
      </IconButton>
      <Menu keepMounted anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={onMenuClose}>
        <IconMenuItem
          icon={<People fontSize="small" />}
          text="Users"
          onClick={() => redirect(DASHBOARD_USER_LIST)}
        />
        <Divider />
        <Typography
          className={classes.menuSubheader}
          color="textSecondary"
          component="div"
          variant="overline">
          Data
        </Typography>
        <IconMenuItem
          icon={<Flight fontSize="small" />}
          text="Trips"
          onClick={() => redirect(DASHBOARD_TRIP_LIST)}
        />
        <IconMenuItem
          icon={<Home fontSize="small" />}
          text="Households"
          onClick={() => redirect(DASHBOARD_HOUSEHOLD_LIST)}
        />
        <IconMenuItem
          icon={<EmojiPeople fontSize="small" />}
          text="Members"
          onClick={() => redirect(DASHBOARD_MEMBER_LIST)}
        />
        <IconMenuItem
          icon={<DriveEta fontSize="small" />}
          text="Vehicles"
          onClick={() => redirect(DASHBOARD_VEHICLE_LIST)}
        />
        <IconMenuItem
          icon={<LocationOn fontSize="small" />}
          text="Addresses"
          onClick={() => redirect(DASHBOARD_ADDRESS_LIST)}
        />
        <IconMenuItem
          icon={<Feedback fontSize="small" />}
          text="Feedback"
          onClick={() => redirect(DASHBOARD_FEEDBACK_LIST)}
        />
        <Divider />
        <IconMenuItem
          icon={<Settings fontSize="small" />}
          text="Settings"
          onClick={() => redirect(DASHBOARD_SETTINGS)}
        />
        <Divider />
        <Typography
          className={classes.menuSubheader}
          color="textSecondary"
          component="div"
          variant="overline">
          {user.email}
        </Typography>
        <IconMenuItem
          icon={<ExitToApp fontSize="small" />}
          text="Sign out"
          onClick={() => {
            onMenuClose();
            signOut();
          }}
        />
      </Menu>
    </Box>
  );
};

const DashboardNavbar: React.FC = () => {
  const classes = useStyles();
  const { data: token } = useToken();
  const { data: user } = useSelfUser({ enabled: Boolean(token) });

  return (
    <AppBar className={classes.root} position="static" color="default" elevation={0}>
      <Container>
        <Toolbar disableGutters>
          <Box display="flex" flexDirection="row" alignItems="center">
            <img className={classes.logo} src="/assets/icons/logo.svg" alt="App logo" />
            <Typography variant="h6">Dashboard</Typography>
          </Box>
          <Box display="flex" flexGrow={1} flexDirection="row" alignItems="center">
            {user ? (
              <Hidden smDown>
                <DesktopMainMenu />
              </Hidden>
            ) : null}
          </Box>
          <DarkModeSwitch />
          {user ? (
            <Hidden smDown>
              <DesktopUserMenu user={user} />
            </Hidden>
          ) : null}
          {user ? (
            <Hidden mdUp>
              <MobileMenu user={user} />
            </Hidden>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default DashboardNavbar;
