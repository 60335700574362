import {
  IonAlert,
  IonButton,
  IonList,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { trashOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import {
  isHouseholdReadOnly,
  useHouseholdDelete,
  useHouseholdSubmit,
  useSelfHousehold,
} from '../../api/household';
import { useMembers } from '../../api/member';
import { useSelfUser } from '../../api/user';
import { useVehicles } from '../../api/vehicle';
import ButtonSheet from '../../components/buttons/ButtonSheet';
import AddonButton from '../../components/forms/AddonButton';
import ItemText from '../../components/forms/ItemText';
import StatusIndicator from '../../components/forms/StatusIndicator';
import FormLayout from '../../components/layouts/FormLayout';
import QueryResultToast from '../../components/notification/QueryResultToast';
import {
  APP_RECRUITMENT_COMPLETE,
  APP_RECRUITMENT_HOUSEHOLD,
  APP_RECRUITMENT_MEMBER_SUMMARY,
  APP_RECRUITMENT_VEHICLE_SUMMARY,
} from '../../urls';

const Summary: React.FC = () => {
  const history = useHistory();

  const { data: user, refetch: refetchUser } = useSelfUser();
  const { data: household, ...resHouseholdQuery } = useSelfHousehold();
  const { data: members, ...resMembersQuery } = useMembers(household?.id);
  const { data: vehicles, ...resVehiclesQuery } = useVehicles(household?.id);
  const [deleteHousehold, resDeleteHousehold] = useHouseholdDelete();
  const [submit, resSubmit] = useHouseholdSubmit({
    onSuccess: () => history.replace(APP_RECRUITMENT_COMPLETE),
  });

  const [isSubmitDialogVisible, setIsSubmitDialogVisible] = useState(false);
  const [isHouseholdDelDialogVisible, setIsHouseholdDelDialogVisible] = useState(false);
  const [isPageEntered, setIsPagedEntered] = useState(false);

  useIonViewDidEnter(() => {
    if (user?.householdOwnedId !== household?.id) {
      refetchUser();
    }
  }, [user, household]);
  useIonViewDidEnter(() => setIsPagedEntered(true));
  useIonViewWillLeave(() => setIsPagedEntered(false));

  const memberCount = !Array.isArray(members) ? 0 : members?.[0].totalItems;
  const vehicleCount = !Array.isArray(vehicles) ? 0 : vehicles?.[0].totalItems;

  const isError = resHouseholdQuery.isError || resMembersQuery.isError || resVehiclesQuery.isError;
  const isLoading =
    (resHouseholdQuery.isInitialData && resHouseholdQuery.isFetching) ||
    (resMembersQuery.isInitialData && resMembersQuery.isFetching) ||
    (resVehiclesQuery.isInitialData && resVehiclesQuery.isFetching) ||
    resSubmit.isLoading ||
    resDeleteHousehold.isLoading;
  const isHouseholdOwner =
    Boolean(user?.householdOwnedId) && user?.householdOwnedId === household?.id;
  const isReadOnly = !isHouseholdOwner || isHouseholdReadOnly(household);
  const canSubmit = Boolean(household?.id) && !isReadOnly && !isLoading && !isError && memberCount;

  const onHouseholdClick = (e: React.MouseEvent<HTMLIonItemElement>) => {
    e.stopPropagation();
    if (!resHouseholdQuery.isLoading && !resHouseholdQuery.isError) {
      history.push(APP_RECRUITMENT_HOUSEHOLD.replace(':id?', household?.id ? household?.id : ''));
    }
  };

  const onMemberClick = (e: React.MouseEvent<HTMLIonItemElement>) => {
    e.stopPropagation();
    if (!resMembersQuery.isLoading && !resMembersQuery.isError) {
      history.push(APP_RECRUITMENT_MEMBER_SUMMARY);
    }
  };

  const onVehicleClick = (e: React.MouseEvent<HTMLIonItemElement>) => {
    e.stopPropagation();
    if (!resVehiclesQuery.isLoading && !resVehiclesQuery.isError) {
      history.push(APP_RECRUITMENT_VEHICLE_SUMMARY);
    }
  };

  const onHouseholdDeleteClick = (e: React.MouseEvent<HTMLIonButtonElement>) => {
    e.stopPropagation();
    setIsHouseholdDelDialogVisible(true);
  };

  const onDeleteHouseholdClick = () => {
    if (household?.id) {
      deleteHousehold(household.id);
    }
  };

  const onHouseholdSubmitClick = () => {
    submit(household?.id);
  };

  return (
    <IonPage>
      <FormLayout
        title="Survey Summary"
        subtitle={`Tap on an item to ${isReadOnly ? 'view' : 'edit'}.`}>
        <IonList className="input-group">
          {/* Household */}
          <ItemText title="Household" onClick={onHouseholdClick}>
            {resHouseholdQuery.isLoading || resHouseholdQuery.isError || !household?.id ? (
              <StatusIndicator color="medium" status={resHouseholdQuery.status}>
                {!household?.id ? 'Not started' : null}
              </StatusIndicator>
            ) : !isReadOnly ? (
              <AddonButton
                color="danger"
                align="center"
                icon={trashOutline}
                onClick={onHouseholdDeleteClick}
              />
            ) : null}
          </ItemText>
          {/* Member */}
          {household?.id ? (
            <ItemText title="Members" onClick={onMemberClick}>
              <StatusIndicator
                color={memberCount ? 'success' : 'medium'}
                status={resMembersQuery.status}>
                {`${memberCount} member${memberCount > 1 ? 's' : ''} saved`}
              </StatusIndicator>
            </ItemText>
          ) : null}
          {/* Vehicle */}
          {household?.id ? (
            <ItemText title="Vehicles" onClick={onVehicleClick}>
              <StatusIndicator
                color={vehicleCount ? 'success' : 'medium'}
                status={resVehiclesQuery.status}>
                {`${vehicleCount} vehicles${vehicleCount > 1 ? 's' : ''} saved`}
              </StatusIndicator>
            </ItemText>
          ) : null}
        </IonList>
      </FormLayout>
      {!isReadOnly ? (
        <ButtonSheet initialVisibility={isPageEntered}>
          <IonButton
            shape="round"
            expand="block"
            disabled={!canSubmit}
            onClick={() => setIsSubmitDialogVisible(true)}>
            {isLoading ? 'Loading...' : 'Submit'}
          </IonButton>
        </ButtonSheet>
      ) : null}
      <IonAlert
        isOpen={isHouseholdDelDialogVisible}
        onDidDismiss={() => setIsHouseholdDelDialogVisible(false)}
        header="Delete household?"
        message={`Do you want to remove household information? 
          This will also remove all saved members and vehicles.`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => setIsHouseholdDelDialogVisible(false),
          },
          {
            text: 'Delete',
            cssClass: 'danger',
            handler: onDeleteHouseholdClick,
          },
        ]}
      />
      <IonAlert
        isOpen={isSubmitDialogVisible}
        onDidDismiss={() => setIsSubmitDialogVisible(false)}
        header="Submit response?"
        message={`Do you want to submit your recruitment survey response? 
          You cannot change your answers once it is submitted.`}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => setIsSubmitDialogVisible(false),
          },
          {
            text: 'Submit',
            handler: onHouseholdSubmitClick,
          },
        ]}
      />
      <QueryResultToast result={resDeleteHousehold}>
        Household information has been removed.
      </QueryResultToast>
      <QueryResultToast result={resSubmit}>Recruitment survey submitted.</QueryResultToast>
    </IonPage>
  );
};

export default Summary;
