import DateFnsUtils from '@date-io/date-fns';
import { LocalizationProvider } from '@material-ui/pickers';
import React from 'react';

import ThemeProvider from './components/ThemeProvider';
import ServerRouter from './components/navigation/ServerRouter';

const ServerApp: React.FC = () => {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <ServerRouter />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default ServerApp;
