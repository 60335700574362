import { ColDef, RowParams, RowsProp } from '@material-ui/data-grid';
import React from 'react';
import { useHistory } from 'react-router';

import { FilterOptions, usePagedList } from '../../api/common';
import { User, UserProfile } from '../../api/user';
import DataGrid from '../../components/datagrid/DataGrid';
import {
  BooleanOperator,
  ContainsOperator,
  DateTimeRangeOperators,
  createSelectOperator,
} from '../../components/datagrid/Operators';
import useFilter from '../../components/datagrid/useFilter';
import usePagination from '../../components/datagrid/usePagination';
import useSorting, { defaultSortingFactory } from '../../components/datagrid/useSorting';
import { API_USERS, DASHBOARD_USER } from '../../urls';
import { humanizeIsoDate } from '../../utils/date';

const columns: ColDef[] = [
  { field: 'email', headerName: 'Email', width: 250, filterOperators: ContainsOperator },
  {
    field: 'name',
    headerName: 'Full Name',
    width: 200,
    filterOperators: ContainsOperator,
    valueGetter(params) {
      const profile = params.row.profile as UserProfile;
      return [profile?.firstName, profile?.lastName].filter(Boolean).join(' ');
    },
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    width: 175,
    filterOperators: ContainsOperator,
    valueGetter(params) {
      const profile = params.row.profile as UserProfile;
      return profile?.phoneNumber ?? '';
    },
  },
  {
    field: 'householdOwner',
    headerName: 'Household Owner',
    width: 195,
    filterOperators: BooleanOperator,
    valueGetter: (params) => (params.row.householdOwned ? 'Yes' : 'No'),
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 120,
    filterOperators: createSelectOperator([
      { id: 'admin', title: 'ADMIN' },
      { id: 'user', title: 'USER' },
    ]),
    valueGetter: (params) => params.row.role.title.toUpperCase(),
  },
  {
    field: 'verified',
    headerName: 'Verified',
    width: 135,
    filterOperators: BooleanOperator,
    valueGetter: (params) => (params.row.verifiedAt ? 'Yes' : 'No'),
  },
  {
    field: 'disabled',
    headerName: 'Disabled',
    width: 135,
    filterOperators: BooleanOperator,
    valueGetter: (params) => (params.row.disabledAt ? 'Yes' : 'No'),
  },
  {
    field: 'referrer',
    headerName: 'Referral Code',
    width: 150,
    filterOperators: ContainsOperator,
  },
  {
    field: 'createdAt',
    headerName: 'Registered At',
    width: 170,
    filterOperators: DateTimeRangeOperators,
    valueFormatter: (params) => humanizeIsoDate(params.getValue('createdAt') as string),
  },
];

export interface UserTableProps {
  filter?: FilterOptions;
}

const UserTable: React.FC<UserTableProps> = ({ filter: extraFilter }) => {
  const history = useHistory();

  const [filter, filterProps] = useFilter();
  const [pagination, paginationProps] = usePagination();
  const [sorting, sortingPorps] = useSorting((params) => {
    const sorting = defaultSortingFactory(params);
    if (sorting.column === 'verified' || sorting.column === 'disabled') {
      sorting.column += 'At';
    }
    return sorting;
  });

  const { data, totalItems, isLoading } = usePagedList<User>(
    API_USERS,
    { ...filter, ...extraFilter },
    sorting,
    pagination,
  );

  const onRowClick = (params: RowParams) => {
    history.push(DASHBOARD_USER.replace(':id', params.getValue('id') as string));
  };

  const rows: RowsProp = data?.items ?? [];

  return (
    <DataGrid
      {...paginationProps}
      {...filterProps}
      {...sortingPorps}
      rows={rows}
      columns={columns}
      loading={isLoading}
      rowCount={totalItems}
      onRowClick={onRowClick}
    />
  );
};

export default UserTable;
