import { Capacitor, Plugins } from '@capacitor/core';
import { BackButtonEvent, iosTransitionAnimation } from '@ionic/core';
import { setupConfig } from '@ionic/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import { LocalCacheProvider, createStore } from './components/LocalCache';
import * as serviceWorker from './serviceWorker';
import { APP_HOME, APP_PASSWORD_REST, APP_SIGNIN, APP_SIGNUP } from './urls';

const { App } = Plugins;
const isDevelopment = process.env.NODE_ENV === 'development';
const isServerApp = process.env.REACT_APP_PRODUCT === 'server';

setupConfig({ mode: 'md', navAnimation: iosTransitionAnimation });
document.body.classList.add('md');

const localCache = createStore(100);
const queryCache = new QueryCache({
  defaultConfig: { queries: { staleTime: 5 * 1000, initialStale: true } },
});

// exit app handler
if (Capacitor.platform === 'android') {
  const exitPoints = [APP_SIGNIN, APP_SIGNUP, APP_PASSWORD_REST, APP_HOME, '/'];

  // exit by pressing back button in auth pages and home page
  document.addEventListener('ionBackButton', (ev: any) => {
    (ev as BackButtonEvent).detail.register(99, (next) => {
      const path = window.location.pathname;
      if (exitPoints.includes(path.toLowerCase())) {
        App.exitApp();
      } else {
        next();
      }
    });
  });
}

const render = () => {
  const App = require('./App').default;
  const ServerApp = require('./ServerApp').default;
  ReactDOM.render(
    <LocalCacheProvider cache={localCache}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        {!isServerApp ? <App /> : <ServerApp />}
        <ReactQueryDevtools initialIsOpen={isDevelopment} />
      </ReactQueryCacheProvider>
    </LocalCacheProvider>,
    document.getElementById('root'),
  );
};

render();
if (isDevelopment && module.hot) {
  module.hot.accept('./App', render);
  module.hot.accept('./ServerApp', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
