import {
  Box,
  Grid,
  GridTypeMap,
  Paper,
  Tab,
  Tabs,
  TabsProps,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

type GridProps = GridTypeMap['props'];

const useStyles = makeStyles((theme) => ({
  tabs: {
    paddingRight: theme.spacing(1),
  },
  tab: {
    minWidth: 'auto',
    fontSize: theme.typography.overline.fontSize,
  },
}));

export interface DashItemTabProps extends GridProps {
  title: string;
  tabs: string[];
  currentTab?: string;
  onTabChange?: TabsProps['onChange'];
}

const DashItemTab: React.FC<DashItemTabProps> = ({ children, ...props }) => {
  const classes = useStyles();
  const { title, tabs, currentTab = tabs[0], onTabChange, ...gridProps } = props;

  return (
    <Grid item {...gridProps}>
      <Paper variant="outlined">
        <Grid container justifyContent="space-between">
          <Grid item>
            <Box px={2} pt={1}>
              <Typography color="textSecondary" variant="overline">
                {title}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Tabs
              classes={{ root: classes.tabs }}
              textColor="primary"
              indicatorColor="primary"
              value={currentTab}
              onChange={onTabChange}>
              {tabs.map((tab) => (
                <Tab classes={{ root: classes.tab }} key={tab} label={tab} value={tab} />
              ))}
            </Tabs>
          </Grid>
        </Grid>
        {children}
      </Paper>
    </Grid>
  );
};

export default DashItemTab;
