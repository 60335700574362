import { arrowBackOutline } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';

import ToolbarLayout, { ToolbarLayoutProps } from './ToolbarLayout';

const FormLayout: React.FC<ToolbarLayoutProps> = ({ children, buttons, ...props }) => {
  const history = useHistory();
  const back = {
    slot: 'start',
    color: 'light',
    icon: arrowBackOutline,
    onClick: () => history.goBack(),
  };

  return (
    <ToolbarLayout buttons={[back, ...(buttons ?? [])]} {...props}>
      {children}
    </ToolbarLayout>
  );
};

export default FormLayout;
