import { Breadcrumbs, Paper, Typography } from '@material-ui/core';
import React from 'react';

import DashboardLayout from '../../components/layouts/DashboardLayout';
import FeedbackTable from '../../components/tables/FeedbackTable';
import DashboardHeader from '../../components/typography/DashboardHeader';

const FeedbackList: React.FC = () => {
  return (
    <DashboardLayout>
      <DashboardHeader>
        <Breadcrumbs>
          <Typography color="inherit" variant="overline">
            Dashboard
          </Typography>
          <Typography color="inherit" variant="overline">
            Data
          </Typography>
          <Typography color="textPrimary" variant="overline">
            Feedback
          </Typography>
        </Breadcrumbs>
        <Typography color="textPrimary" variant="h5" component="h1">
          All Feedback
        </Typography>
      </DashboardHeader>
      <Paper variant="outlined">
        <FeedbackTable />
      </Paper>
    </DashboardLayout>
  );
};

export default FeedbackList;
