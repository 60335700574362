import { InputChangeEventDetail } from '@ionic/core';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonPage,
  IonRouterLink,
} from '@ionic/react';
import { Typography, makeStyles } from '@material-ui/core';
import { isEmail } from 'class-validator';
import { lockClosedOutline, mailOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { QueryStatus } from 'react-query';
import { Redirect } from 'react-router';

import { useRequestPasswordReset } from '../../api/auth';
import HelperText from '../../components/forms/HelperText';
import IonInput from '../../components/forms/patch/IonInput';
import QueryResultToast from '../../components/notification/QueryResultToast';
import Subtitle from '../../components/typography/Subtitle';
import Title from '../../components/typography/Title';
import { APP_SIGNIN, APP_SIGNUP } from '../../urls';
import isPassword from '../../utils/password';
import ConfirmPassword from './ConfirmPassword';

const useStyles = makeStyles({
  container: {
    '&::part(background)': {
      background:
        'url(/assets/images/app/fogg-searching.svg) center calc(100% + 25px) / 400px no-repeat',
    },
  },
});

const ResetPassword: React.FC = () => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [request, { status, error, reset }] = useRequestPasswordReset();

  const onEmailChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setEmail(e.detail.value!);
  };

  const onPasswordChange = (e: CustomEvent<InputChangeEventDetail>) => {
    setPassword(e.detail.value!);
  };

  const onSubmit = () => request({ email });

  const isEmailValid = isEmail(email);
  const hasValidEmail = !!email && isEmailValid;
  const isPasswordValid = isPassword(password);
  const hasValidPassword = !!password && isPasswordValid;
  const isLoading = status === QueryStatus.Loading;
  const canSubmit = !isLoading && hasValidEmail && hasValidPassword;

  useEffect(() => {
    if (status === QueryStatus.Success) {
      setIsConfirmModalVisible(true);
    }
  }, [status]);

  if (shouldRedirect) {
    return <Redirect push={false} to={APP_SIGNIN} />;
  }

  return (
    <IonPage>
      <IonContent fullscreen className={['display', classes.container].join(' ')}>
        <Title>Reset Password!</Title>
        <Subtitle>Enter your email and a new password.</Subtitle>
        <IonList>
          <IonItem className="ion-margin-top">
            <IonIcon icon={mailOutline} slot="start" />
            <IonInput type="email" placeholder="Email" value={email} onIonChange={onEmailChange} />
          </IonItem>
          {email && !isEmailValid ? (
            <HelperText color="error">Email address is invalid</HelperText>
          ) : null}
          <IonItem className="ion-margin-top">
            <IonIcon icon={lockClosedOutline} slot="start" />
            <IonInput
              type="password"
              placeholder="New password"
              value={password}
              onIonChange={onPasswordChange}
            />
          </IonItem>
          {password && !isPasswordValid ? (
            <HelperText color="error">
              At least 10 characters, 1 uppercase letter, 1 number, 1 symbol
            </HelperText>
          ) : null}
        </IonList>
        <IonButton
          shape="round"
          expand="block"
          onClick={onSubmit}
          disabled={!canSubmit}
          className="ion-no-margin ion-margin-top ion-margin-bottom">
          {isLoading ? 'Loading...' : 'Reset Password'}
        </IonButton>
        <Typography variant="body2" align="center">
          {"Don't have an account? "}
          <IonRouterLink routerLink={APP_SIGNUP}>Sign up</IonRouterLink>.
        </Typography>
      </IonContent>
      <ConfirmPassword
        email={email}
        password={password}
        isOpen={isConfirmModalVisible}
        onSuccess={() => setShouldRedirect(true)}
        onDismiss={() => setIsConfirmModalVisible(false)}
      />
      <QueryResultToast result={{ status, error, reset }}>
        Password reset token has been sent to your email.
      </QueryResultToast>
    </IonPage>
  );
};

export default ResetPassword;
