import { SelectChangeEventDetail } from '@ionic/core';
import React from 'react';

import { ItemSelectProps } from './ItemSelect';
import KvSelect from './KvSelect';

const DEFAULT_ENTRIES: [string, string][] = [
  ['yes', 'Yes'],
  ['no', 'No'],
];

export interface BinarySelectProps<T> extends Omit<ItemSelectProps<string>, 'value'> {
  value?: boolean | null;
  entries?: ['yes' | 'no', T][];
}

function BinarySelect<T>({ value, onChange, entries, ...props }: BinarySelectProps<T>) {
  const computedValue = typeof value === 'boolean' ? (value ? 'yes' : 'no') : value;
  const computedHandler = (e: CustomEvent<SelectChangeEventDetail>) => {
    if (typeof e.detail.value === 'string') {
      e.detail.value = e.detail.value! === 'yes';
    }
    onChange?.(e);
  };

  if (entries) {
    return (
      <KvSelect value={computedValue} onChange={computedHandler} entries={entries} {...props} />
    );
  }
  return (
    <KvSelect
      value={computedValue}
      onChange={computedHandler}
      entries={DEFAULT_ENTRIES}
      {...props}
    />
  );
}

export default BinarySelect;
